import { useState } from "react";

import { TextInputTheme } from "assets";
import { Button } from "components";
import { TextInput } from "flowbite-react";
import { HiArrowLeft, HiCheck } from "react-icons/hi";

export function UserProfileOnboarding({
  partner,
  input,
  setInput = () => {},
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const [err, setErr] = useState(false);
  // const submitRef = useRef(null);

  const handleInput = (event) => {
    setInput({
      ...input,
      [event?.target?.name]: event?.target?.value,
    });
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="text-primary w-fit text-lg font-bold">
          About Your User Profile
        </div>

        <div className="text-gray w-fit text-sm">
          Please confirm profile details for the primary contact for your
          organisation.
        </div>
      </div>

      <div className="flex w-full flex-col gap-8 self-stretch">
        <div className="flex flex-col gap-4 self-stretch text-sm font-medium laptop:gap-8">
          <div className="flex flex-col gap-4 self-stretch laptop:flex-row">
            <div className="flex w-full flex-col gap-2 self-stretch">
              First Name
              <TextInput
                name="firstName"
                type="text"
                placeholder="Enter your first name"
                required
                defaultValue={partner?.firstName}
                onChange={handleInput}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>

            <div className="flex w-full flex-col gap-2 self-stretch">
              Last Name
              <TextInput
                name="lastName"
                type="text"
                placeholder="Enter your last name"
                required
                defaultValue={partner?.lastName}
                onChange={handleInput}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4 self-stretch laptop:flex-row">
            <div className="flex w-full flex-col gap-2 self-stretch">
              Work Email Address
              <TextInput
                name="email"
                type="text"
                disabled
                required
                value={partner?.email}
                onChange={handleInput}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>

            <div className="flex w-full flex-col gap-2 self-stretch">
              Mobile
              <TextInput
                name="phone"
                type="text"
                disabled
                required
                value={partner?.phone}
                onChange={handleInput}
                // onKeyPress={filterPhoneNoKeyPress}
                // onPaste={filterPhoneNoPaste}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>
          </div>

          <div className="flex flex-col gap-4 self-stretch laptop:flex-row">
            <div className="flex w-full flex-col gap-2 self-stretch">
              Role
              <TextInput
                name="role"
                type="text"
                placeholder="Enter your role"
                required
                defaultValue={partner?.role}
                onChange={handleInput}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>

            <div className="hidden w-full laptop:block" />
          </div>
        </div>

        <div className="flex gap-3 self-stretch">
          <Button
            customClasses={`w-full laptop:w-fit`}
            leftIcon={<HiArrowLeft className="size-4" />}
            label={"Back"}
            labelStyles={"font-semibold text-sm"}
            variant={"blue"}
            onClick={handlePrevStep}
          />

          <Button
            customClasses={`w-full laptop:w-fit`}
            leftIcon={<HiCheck className="size-4" />}
            label={"Next Step"}
            labelStyles={"font-semibold text-sm"}
            variant={"blue"}
            onClick={handleNextStep}
          />
        </div>
      </div>
    </>
  );
}
