import { placeholderLogo } from "assets";
import { Navbar } from "layouts";
import { SidebarAssessment } from "layouts/sidebar/sidebar-assessment";

export function LayoutAssessmentWrapper({
  assessment,
  eligibilityCheck,
  children,
  partner,
  preview,
  returnFn = () => {},
}) {
  let partnerLogoL = !partner?.logo?.includes("favicon") ? partner?.logo : null;

  return (
    <>
      <Navbar
        variant="renderAssessmentNav"
        primeLogo={partner?.logo || partner?.favicon || placeholderLogo}
        primeLogoL={partnerLogoL}
        orgName={partner?.name || "CAP Partner"}
        assessmentId={
          assessment?.assessment?.id || assessment?.assessmentResult?.id
        }
        assessmentToken={assessment?.assessment?.token}
        assessmentUser={assessment?.assessmentResult?.verifiedData}
        eligibilityCheck={eligibilityCheck}
        assessmentFn={returnFn}
      />

      <div className="flex w-full grow flex-col laptop:flex-row">
        <SidebarAssessment assessment={assessment} />

        <div className="flex w-full flex-col justify-between self-stretch">
          {children}
        </div>
      </div>
    </>
  );
}
