import React from "react";

import { HiCheck } from "react-icons/hi";
import { handleCheckboxSubmission } from "utils";

export function Checkbox({ checked, onChange, label }) {
  const toggleCheckbox = () => {
    if (onChange) {
      onChange(!checked); // Pass the new checked state to the onChange function
    }
  };

  return (
    <label className="flex cursor-pointer items-center">
      <input
        type="checkbox"
        checked={checked}
        onChange={toggleCheckbox}
        className="hidden"
      />
      <div
        className={`border-primary flex size-4 items-center justify-center rounded border
                  hover:bg-blue-100 
                  focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-300
                  ${checked ? "bg-blue-100" : "bg-white"}`}
        tabIndex={0}
        onKeyDown={(event) => handleCheckboxSubmission(event, toggleCheckbox)}
      >
        {checked && <HiCheck className="text-brand" />}
      </div>
      <span className="text-gray-700">{label}</span>
    </label>
  );
}
