import { CertCard, DetailRow, UpdateButton } from "components";
import { useDashboard } from "contexts";
import { HiExternalLink } from "react-icons/hi";
import { parseDateShort, parseDateTimeShort } from "utils";
import { LEVELS } from "variables";

export function OrganisationDetails({
  certDetails,
  orgData,
  setCertDetails = () => {},
  handleUpdateOrgName = () => {},
}) {
  const { dashboard } = useDashboard();

  return (
    <>
      <div className="flex flex-col gap-8 self-stretch px-8">
        <div className="flex flex-col gap-8 self-stretch desktop:flex-row">
          <section
            id="cybercert-organisation-details-overview"
            className="laptop:w-[600px]"
          >
            <div className="flex flex-col gap-5 self-stretch">
              <span className="text-primary text-sm font-bold">
                Account Overview
              </span>

              <div className="flex flex-col self-stretch">
                {!!orgData?.data &&
                  Object.entries(orgData?.data)?.map((item, index) => (
                    <DetailRow
                      key={index}
                      label={
                        item[0] === "Organisation Name"
                          ? dashboard.orgTitleSingle + " Name"
                          : item[0]
                      }
                    >
                      {item[0] === "Organisation Name" ? (
                        <div className="flex justify-between">
                          {item[1]}
                          <UpdateButton onClick={handleUpdateOrgName} />
                        </div>
                      ) : (
                        item[1]
                      )}
                    </DetailRow>
                  ))}
              </div>
            </div>
          </section>

          {/* <TierCard /> */}
        </div>

        <section id="cybercert-organisation-details-certifications">
          <div className="flex flex-col gap-8 self-stretch">
            <span className="text-primary text-sm font-bold">
              Certifications
            </span>

            {!!orgData?.customer?.certifications?.length ? (
              <div className="flex w-fit gap-4">
                {orgData?.customer?.certifications?.map((item, index) => (
                  <CertCard
                    id={item.id}
                    key={index}
                    title={item.title}
                    standard={item.standard}
                    level={item.level}
                    currentStep={item.currentStep}
                    totalSteps={item.totalSteps}
                    showCertDetails={() => {
                      if (item?.id === certDetails?.id) {
                        setCertDetails(null);
                      } else {
                        setCertDetails(item);
                      }
                    }}
                    status={item?.progress?.status}
                  />
                ))}
              </div>
            ) : (
              <div className="flex flex-grow self-stretch">
                There are no certifications currently active or in progress for
                this {dashboard?.orgSingle}
              </div>
            )}
          </div>
        </section>

        {!!certDetails && (
          <section className="flex flex-col gap-8">
            <div className="flex w-full flex-col items-start justify-between gap-8 laptop:flex-row">
              <div className="flex w-full flex-col">
                <span className="text-primary text-sm font-bold">
                  Certification details
                </span>

                <DetailRow label={"Cert ID"}>
                  <span>{certDetails?.id}</span>
                </DetailRow>

                <DetailRow label={"Cert Progress ID"}>
                  <span>{certDetails?.progressId}</span>
                </DetailRow>

                <DetailRow label={"Level"}>
                  <span>{LEVELS[certDetails?.level]}</span>
                </DetailRow>

                <DetailRow label={"Started"}>
                  <span>
                    {parseDateTimeShort(certDetails?.progress?.createdAt)}
                  </span>
                </DetailRow>

                <DetailRow label={"Certificate Issued"}>
                  <span>
                    {parseDateShort(certDetails?.progress?.completedDate)}
                  </span>
                </DetailRow>

                <DetailRow label={"Verify certification"}>
                  <span>
                    {certDetails?.verified ? (
                      <>
                        <div className="text-primary hover:text-link flex flex-row items-center gap-2">
                          <a href="#" target="_blank" rel="noreferrer">
                            Verify
                          </a>
                          <HiExternalLink className="size-5" />
                        </div>
                      </>
                    ) : (
                      "NA"
                    )}
                  </span>
                </DetailRow>

                <DetailRow label={"Completed"}>
                  <span>
                    {parseDateTimeShort(certDetails?.progress?.completedDate)}
                  </span>
                </DetailRow>

                <DetailRow label={"Expiry"}>
                  <span>
                    {parseDateShort(certDetails?.progress?.expiredDate)}
                  </span>
                </DetailRow>
              </div>

              <div className="flex w-full flex-col">
                <span className="text-primary text-sm font-bold">
                  Director Attestation
                </span>
                <DetailRow label={"Completed by"}>
                  <span>
                    {!!certDetails?.progress?.firstName
                      ? `${certDetails?.progress?.firstName} ${certDetails?.progress?.lastName}`
                      : "NA"}
                  </span>
                </DetailRow>
                <DetailRow label={"Role"}>
                  <span>{certDetails?.progress?.role || "NA"}</span>
                </DetailRow>
                <DetailRow label={"Work email address"}>
                  <span>{certDetails?.progress?.email || "NA"}</span>
                </DetailRow>
                <DetailRow label={"Contact number"}>
                  <span>{certDetails?.progress?.mobile || "NA"}</span>
                </DetailRow>
              </div>
            </div>
            {/* <div className="flex flex-col gap-4">
                  {certDetails?.progress?.requirements?.items?.filter(
                    (req) => req?.attestationStatus === "NA"
                  )?.length > 0 && (
                      <>
                        <span className="text-primary text-sm font-bold">
                          Marked as Not Applicable Requirements
                        </span>

                        {certDetails?.progress?.requirements?.items
                          ?.filter(
                            (req) => req?.attestationStatus === "NA"
                          )
                          ?.map((req) => (
                            <>
                              <span className="text-primary text-sm font-medium">
                                {req?.detail?.requirementId} {req?.detail?.title}
                              </span>
                              <DetailRow label={"Comment"}>
                                <span>{req?.notApplicableDetails}</span>
                              </DetailRow>
                            </>
                          ))}
                      </>
                    )}
                </div> */}
          </section>
        )}
      </div>
    </>
  );
}
