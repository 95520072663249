import { useState } from "react";

import { AlertTheme } from "assets";
import { Button } from "components";
import { Alert } from "flowbite-react";
import {
  HiChatAlt2,
  HiCheck,
  HiCheckCircle,
  HiOutlineDeviceMobile,
  HiOutlineMail,
  HiOutlineOfficeBuilding,
} from "react-icons/hi";
import { openZEWidget } from "utils";

export function ConfirmOrg({ verifyResult, handleConfirm = () => {} }) {
  const [showAlert, setShowAlert] = useState(true);

  const handleDismiss = () => {
    setShowAlert(!showAlert);
  };

  return (
    <>
      <div className="flex flex-col gap-8 p-8">
        {showAlert && (
          <Alert
            color={"green"}
            onDismiss={handleDismiss}
            icon={HiCheckCircle}
            theme={AlertTheme}
          >
            <div className="alert-flowbite flex flex-row items-center gap-3">
              <div className="text-base font-semibold">
                Your account has been verified! Please confirm your organisation
                to proceed.
              </div>
            </div>
          </Alert>
        )}

        <div className="flex flex-col">
          <div className="text-primary w-fit text-lg font-bold">
            Your organisation details
          </div>

          <div className="text-gray w-fit text-sm">
            Please confirm your organisation details to proceed.
          </div>
        </div>

        <div className="border-primary surface-db-stepper w-full rounded-lg border p-5 desktop:w-[50%]">
          <div className="mb-4 flex flex-row items-center justify-start gap-2">
            <HiOutlineOfficeBuilding className="text-icons size-5" />{" "}
            <span className="body-sm text-gray-600">
              Is this your organisation?
            </span>
          </div>

          <div className="org-name mb-3 flex flex-row items-center justify-start gap-2">
            <span className="text-lg font-bold capitalize text-gray-900">
              {verifyResult?.assessmentResult?.name}
            </span>{" "}
            <HiCheckCircle className="size-5 text-green-500" />
          </div>

          <div className="org-abn">
            <span className="font-medium">ABN</span>{" "}
            {verifyResult?.assessmentResult?.ABN}
          </div>

          <div className="org-location">
            <span className="font-medium">Representative</span>{" "}
            {verifyResult?.assessmentResult?.completedBy}
          </div>

          <div className="flex flex-row items-center gap-2">
            <span className="font-medium">Verified with</span>{" "}
            {verifyResult?.assessmentResult?.verifiedVia &&
              (() => {
                switch (verifyResult?.assessmentResult?.verifiedVia) {
                  case "email":
                    return <HiOutlineMail className="text-icons size-5" />;
                  case "mobile":
                    return (
                      <HiOutlineDeviceMobile className="text-icons size-5" />
                    );
                  default:
                    return null;
                }
              })()}
            <span className="text-primary">
              {verifyResult?.assessmentResult?.verifiedData}
            </span>
          </div>
        </div>

        <div className="flex flex-row items-center gap-2 body-sm text-gray-900">
          Not your organisation?{" "}
          <button
            onClick={openZEWidget}
            className="flex cursor-pointer flex-row gap-1 font-medium text-blue-600 outline-none hover:text-blue-700 hover:underline"
            tabIndex={0}
          >
            {" "}
            <HiChatAlt2 className="size-5" />
            Get Help
          </button>
        </div>

        <Button
          customClasses={`w-fit laptop:flex`}
          leftIcon={<HiCheck className="size-4" />}
          label={"Confirm"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={handleConfirm}
        />
      </div>
    </>
  );
}
