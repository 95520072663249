import { HiXCircle } from "react-icons/hi";

export function FaviconUpload({ imgSrc, imgAlt, handleRemove = () => {} }) {
  return (
    <div className="border-primary size-15 relative flex self-stretch rounded-full border-px p-1">
      <img src={imgSrc} alt={imgAlt} className="rounded-full" />

      <button
        className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 rounded-full hover:opacity-80 focus:ring-2 focus:ring-gray-500"
        onClick={handleRemove}
      >
        <HiXCircle className="size-5 text-gray-400" />
      </button>
    </div>
  );
}
