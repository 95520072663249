import { useEffect, useState } from "react";

import { fetchGAPAssessmentResult } from "api";
import { AlternateTableTheme } from "assets";
import {
  Breadcrumbs,
  DetailRow,
  DisplayLevelIcon,
  FlowbitePagination,
  LinkPanel,
  LoadingSpinner,
  StatusLabel,
} from "components";
import { useDashboard } from "contexts";
import { Alert, Table } from "flowbite-react";
import { useFetchGAPAssessments } from "hooks";
import { PageHeader } from "layouts";
import { HiChartPie, HiCheckCircle, HiExclamationCircle } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import { parseDateTimeShort } from "utils";
import { INITIAL_GAP_ASSESSMENTS, ROUTES } from "variables";

const RESULTS = [null, "BRONZE", "SILVER", "GOLD", "PLATINUM", "DIAMOND"];

export function ViewGapAssessment() {
  const { dashboard } = useDashboard();

  const [isLoading, setIsLoading] = useState(true);
  const [gapAssessment, setGapAssessment] = useState();
  const [gapAssessmentResults, setGapAssessmentResults] = useState();
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([10]);
  const [selectedOption, setSelectedOption] = useState(itemsPerPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [infoMsg, setInfoMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const params = useParams();
  const gapAssessmentsState = useFetchGAPAssessments();

  const setup = async () => {
    const id = params?.id;

    const a = gapAssessmentsState?.data?.assessments?.filter(
      (item) => item?.id === id,
    )[0];
    if (!!a) {
      const results = await fetchGAPAssessmentResult({ assessmentId: id });

      setGapAssessmentResults(results?.results);

      setGapAssessment({
        ...a,
        linkStatus: !!a?.token ? "complete" : "notstarted",
        assessmentName:
          INITIAL_GAP_ASSESSMENTS[
            INITIAL_GAP_ASSESSMENTS.length - a?.maximumLevel
          ]?.level,
        generated: parseDateTimeShort(a?.activeAt),
        latestSubmission: parseDateTimeShort(a?.submitedAt),
        submitted:
          results?.results?.filter((result) => !!result?.completedAt)?.length ||
          0,
      });
    }
    setIsLoading(false);
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const generateItemsPerPageOptions = (pageSize = 10) => {
    if (!!totalCount) {
      const fullPages = Math.floor(totalCount / pageSize);
      const remainder = totalCount % pageSize;
      const pages = new Array(fullPages).fill(pageSize);
      if (remainder > 0) {
        pages.push(remainder);
      }
      setItemsPerPageOptions(pages);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setCurrentPage(1); // Reset to the first page when changing the items per page option
  };

  const totalCount = gapAssessmentResults?.length;
  const startIndex = (currentPage - 1) * selectedOption;
  const endIndex = startIndex + selectedOption;
  const totalPages = Math.ceil(gapAssessmentResults?.length / selectedOption);
  const currentPageData = gapAssessmentResults?.slice(startIndex, endIndex);

  useEffect(() => {
    generateItemsPerPageOptions();
  }, [totalCount]);

  useEffect(() => {
    if (gapAssessmentsState?.data) setup();
  }, [gapAssessmentsState?.data]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!!infoMsg && (
            <Alert
              color={"green"}
              onDismiss={() => setInfoMsg("")}
              icon={HiCheckCircle}
            >
              <span className="text-base font-semibold">{infoMsg}</span>
            </Alert>
          )}

          {!!errMsg && (
            <Alert
              color={"red"}
              onDismiss={() => setErrMsg("")}
              icon={HiExclamationCircle}
            >
              <span className="text-base font-semibold">{errMsg}</span>
            </Alert>
          )}

          <PageHeader
            variant="primePage"
            pageName={gapAssessment?.assessmentName}
            // customBtn={
            //   <Button
            //     customClasses={"w-full laptop:w-fit"}
            //     leftIcon={<HiOutlinePause className="text-icons size-5" />}
            //     label={"Pause"}
            //     labelStyles={"text-xs font-semibold text-primary"}
            //     variant={"white"}
            //   />
            // }
            customBreadcrumbs={
              <Breadcrumbs
                icon={HiChartPie}
                items={{
                  "": ROUTES.ROOT,
                  Assessments: ROUTES.ASSESSMENTS,
                  "SMB1001 GAP Assessments": ROUTES.GAP,
                  [gapAssessment?.assessmentName]: ROUTES.VIEW_GAP,
                }}
              />
            }
          />

          <div className="flex flex-col gap-5 self-stretch">
            <span className="text-primary text-base font-bold">Summary</span>

            <div className="flex flex-col gap-8 laptop:flex-row">
              <div className="flex w-full flex-col">
                <DetailRow label={`${dashboard?.orgTitle} Completed`}>
                  <span className="label-completed-text">
                    {gapAssessment?.submitted}
                  </span>
                </DetailRow>
                <DetailRow label={"Assessment link"}>
                  {gapAssessment?.linkStatus === "complete" ? (
                    <StatusLabel
                      variant={"completed"}
                      label={"Active"}
                      animate
                    />
                  ) : (
                    ""
                  )}
                </DetailRow>
                <DetailRow label={"Active since"}>
                  {gapAssessment?.generated}
                </DetailRow>
                <DetailRow label={"Latest submission"}>
                  {gapAssessment?.latestSubmission}
                </DetailRow>
              </div>

              <LinkPanel
                description={`Copy your link and distribute through internal communications or standard channels to all of your ${dashboard?.orgPlural} to complete.`}
                link={ROUTES.ASSESSMENT_LINK.replace(":token", "")}
                title={"Your Assessment Link"}
                token={gapAssessment?.token}
                handleErrAlert={setErrMsg}
                handleInfoAlert={setInfoMsg}
              />
            </div>
          </div>

          <PageHeader variant="titleOnly" pageTitle={"Assessment results"} />

          <div className="flex flex-col gap-0">
            <div className="hidden laptop:block">
              <Table theme={AlternateTableTheme} hoverable>
                <Table.Head>
                  <Table.HeadCell>Organisation Name</Table.HeadCell>
                  <Table.HeadCell>Assessed To</Table.HeadCell>
                </Table.Head>

                <Table.Body>
                  {currentPageData?.map((item, index) => (
                    <Table.Row key={index} className="font-medium">
                      <Table.Cell>
                        <Link
                          className="hover:text-link"
                          to={ROUTES.RESULT_GAP.replace(":id", item?.id)}
                          title={item?.name}
                        >
                          {item?.name}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {!!item?.completedAt ? (
                          <>
                            {!!RESULTS[item?.result] ? (
                              <>
                                <DisplayLevelIcon
                                  level={RESULTS[item?.result]}
                                />

                                {RESULTS[item?.result]}
                              </>
                            ) : (
                              <>Ineligible for minimum certification</>
                            )}
                          </>
                        ) : (
                          <>Assessment not completed</>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>

            {/*************** Mobile *******************/}
            <div className="flex flex-col laptop:hidden">
              <div className="surface-secondary text-primary border border-b-0 p-4 text-xs font-semibold uppercase">
                Organisations Assessed to
              </div>
              <div className="flex flex-col gap-0 border border-b-0 border-t-0">
                {currentPageData?.map((item, index) => (
                  <div
                    key={index}
                    className="hover:surface-db-stepper flex flex-col gap-2 border-b p-5"
                  >
                    <div className="text-sm font-medium hover:font-semibold">
                      <Link
                        to={ROUTES.RESULT_GAP.replace(":id", item?.id)}
                        title={item?.name}
                      >
                        {item?.name}
                      </Link>
                    </div>
                    <div className="font-medium">
                      <DisplayLevelIcon level={RESULTS[item?.result]} />

                      {RESULTS[item?.result]}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <FlowbitePagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              totalCount={totalCount}
              itemsPerPageOptions={itemsPerPageOptions}
              selectedOption={selectedOption}
              handleOptionClick={handleOptionClick}
            />
          </div>
        </>
      )}
    </>
  );
}
