import { useRef, useState } from "react";

import { Button } from "components/buttons";
import { DisplayLevelIcon } from "components/display-level-icon";
import { FlowbitePagination } from "components/flowbite";
import { StatusLabel } from "components/labels";
import { Table, Tooltip } from "flowbite-react";
import { useTableScrollPosition } from "hooks";
import { HiArrowRight, HiLink } from "react-icons/hi";
import { RiArrowRightUpLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { ROUTES } from "variables";

export function GapAssessmentsTable({
  data,
  setInfoMsg = () => {},
  setErrMsg = () => {},
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOption, setPageOption] = useState(10);

  const tableRef = useRef(null);
  const tableScroll = useTableScrollPosition(tableRef);

  const totalPages = Math.ceil(data?.length / pageOption);

  const handlePageOptionChange = (value) => {
    setPageOption(value);
    setCurrentPage(Math.min(currentPage, Math.ceil(data?.length / value)));
  };

  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  const getRenders = () => {
    const startIndex = (currentPage - 1) * pageOption;
    const currentGapAssessments = data?.slice(
      startIndex,
      startIndex + pageOption,
    );

    return currentGapAssessments?.map((gapAssessment, index) =>
      !!gapAssessment ? (
        <TableRow
          key={index}
          item={gapAssessment}
          scrollPos={tableScroll.scrollLeft > 0}
          setInfoMsg={setInfoMsg}
          generateAssessmentLink={generateAssessmentLink}
          handleCopyAssessmentLink={handleCopyAssessmentLink}
        />
      ) : (
        <></>
      ),
    );
  };

  const getMobileRenders = () => {
    const startIndex = (currentPage - 1) * pageOption;
    const currentGapAssessments = data?.slice(
      startIndex,
      startIndex + pageOption,
    );

    return currentGapAssessments?.map((gapAssessments, index) =>
      !!gapAssessments ? (
        <MobileRow
          key={index}
          item={gapAssessments}
          setInfoMsg={setInfoMsg}
          generateAssessmentLink={generateAssessmentLink}
          handleCopyAssessmentLink={handleCopyAssessmentLink}
        />
      ) : (
        <></>
      ),
    );
  };

  const generateAssessmentLink = (token) => {
    return `${window.location.origin}${ROUTES.ASSESSMENT_LINK.replace(":token", token)}`;
  };

  const handleCopyAssessmentLink = (token) => {
    navigator.clipboard.writeText(generateAssessmentLink(token)).then(
      () => {
        setInfoMsg("Your assessment link has been copied successfully!");
      },
      (error) => {
        setErrMsg("Failed to copy assessment link to clipboard!");
        console.error(error);
      },
    );
  };

  return (
    <section className="cybercert-gap-assessments-table border-primary flex flex-col self-stretch">
      {/****************************** PC TABLE ******************************/}
      <div className="hidden laptop:block">
        <Table ref={tableRef} hoverable>
          <Table.Head>
            <Table.HeadCell
              className={`w-auto ${tableScroll.scrollLeft > 0 ? "shadow-table" : ""}`}
            >
              Assessments
            </Table.HeadCell>
            <Table.HeadCell className="w-36">Completed</Table.HeadCell>
            <Table.HeadCell className="w-52">Assessment Link</Table.HeadCell>
            <Table.HeadCell className="w-36">Questions</Table.HeadCell>
            <Table.HeadCell className="w-36">Status</Table.HeadCell>
          </Table.Head>

          <Table.Body>
            {!!data?.length ? (
              getRenders()
            ) : (
              <Table.Row>
                <Table.Cell colSpan={5} className="text-center">
                  No GAP assessments currently available
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      {/****************************** MOBILE TABLE ******************************/}
      <div className="laptop:hidden">
        <div className="surface-secondary text-primary border p-4 text-xs font-semibold uppercase">
          Assessments
        </div>

        <div className="flex flex-col border-x">
          {!!data?.length ? (
            getMobileRenders()
          ) : (
            <div className="border-b p-5 text-center">
              No GAP assessments currently available
            </div>
          )}
        </div>
      </div>

      {data?.length > 0 && (
        <FlowbitePagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalCount={data?.length}
          selectedOption={pageOption}
          handleOptionClick={handlePageOptionChange}
        />
      )}
    </section>
  );
}

function TableRow({
  item,
  scrollPos,
  setInfoMsg = () => {},
  generateAssessmentLink = () => {},
  handleCopyAssessmentLink = () => {},
}) {
  const navigate = useNavigate();

  return (
    <Table.Row>
      <Table.Cell
        className={`whitespace-nowrap font-medium text-gray-900 dark:text-white${scrollPos > 0 ? " shadow-table" : ""}`}
      >
        {item?.status === "complete" ? (
          <Link
            className="hover:text-link"
            to={`${ROUTES.VIEW_GAP.replace(":id", item?.id)}`}
          >
            <DisplayLevelIcon level={item?.level} />
            {item?.level}
          </Link>
        ) : (
          <>
            <DisplayLevelIcon level={item?.level} />
            {item?.level}
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        {item?.submissions?.[item?.token] ||
          (item?.status === "complete" ? 0 : "N/A")}
      </Table.Cell>
      <Table.Cell className="max-h-[54px] py-0">
        {item?.token === null ? (
          <Button
            customClasses={"w-full laptop:w-fit"}
            leftIcon={<HiArrowRight className="text-primary h-3 w-3" />}
            label={"Generate"}
            labelStyles={"text-xs font-semibold text-primary"}
            variant={"white"}
            onClick={() =>
              navigate(ROUTES.ADD_GAP, {
                state: {
                  maximumLevel: item?.levelId,
                  selectedDashboard: item?.dashboardRoute,
                },
              })
            }
          />
        ) : (
          <div className="flex flex-row items-center gap-2">
            <div className="surface-secondary w-fit rounded-lg px-2 py-1 font-medium">
              <code className="text-xs">{item?.token}</code>
            </div>

            <Tooltip content="Copy Assessment Link">
              <button
                className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                onClick={() => handleCopyAssessmentLink(item?.token)}
              >
                <HiLink className="size-5" />
              </button>
            </Tooltip>

            <Tooltip content="Open Assessment Link">
              <Link
                to={generateAssessmentLink(item?.token)}
                target="_blank"
                rel="noreferrer"
                className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                onClick={() =>
                  setInfoMsg(
                    "Your assessment link has been opened successfully!",
                  )
                }
              >
                <RiArrowRightUpLine className="size-5" />
              </Link>
            </Tooltip>
          </div>
        )}
      </Table.Cell>
      <Table.Cell>{item?.questions}</Table.Cell>
      <Table.Cell>
        {item?.status &&
          (() => {
            switch (item?.status) {
              case "notstarted":
                return (
                  <StatusLabel
                    variant={"notstarted"}
                    label={"Not started"}
                    animate
                  />
                );
              case "inprogress":
                return (
                  <StatusLabel
                    variant={"inprogress"}
                    label={"In Progress"}
                    animate
                  />
                );
              case "complete":
                return (
                  <StatusLabel variant={"completed"} label={"Active"} animate />
                );
              default:
                return null;
            }
          })()}
      </Table.Cell>
    </Table.Row>
  );
}

function MobileRow({
  item,
  setInfoMsg = () => {},
  generateAssessmentLink = () => {},
  handleCopyAssessmentLink = () => {},
}) {
  const navigate = useNavigate();

  return (
    <div className="hover:surface-db-stepper flex flex-col gap-2 border-b p-5">
      <div className="flex flex-row justify-between">
        <div
          className={`text-primary whitespace-nowrap font-medium ${
            item?.status === "complete" ? `hover:font-semibold` : ``
          }`}
        >
          <DisplayLevelIcon level={item?.level} />
          {item?.status === "complete" ? (
            <Link to={`${ROUTES.VIEW_GAP.replace(":id", item?.id)}`}>
              {item?.level}
            </Link>
          ) : (
            <>{item?.level}</>
          )}
        </div>
        <div>
          {item?.status &&
            (() => {
              switch (item?.status) {
                case "notstarted":
                  return (
                    <StatusLabel
                      variant={"notstarted"}
                      label={"Not started"}
                      animate
                    />
                  );
                case "inprogress":
                  return (
                    <StatusLabel
                      variant={"inprogress"}
                      label={"In Progress"}
                      animate
                    />
                  );
                case "complete":
                  return (
                    <StatusLabel
                      variant={"completed"}
                      label={"Active"}
                      animate
                    />
                  );
                default:
                  return null;
              }
            })()}
        </div>
      </div>
      <div>
        Completed:{" "}
        {item?.submissions?.[item?.token] ||
          (item?.status === "complete" ? 0 : "N / A")}
      </div>
      <div>{item?.questions} questions</div>
      <div className="max-h-[54px] py-0">
        {item?.token === null ? (
          <>
            <Button
              customClasses={"w-fit"}
              leftIcon={<HiArrowRight className="text-primary h-3 w-3" />}
              label={"Generate"}
              labelStyles={"text-xs font-semibold text-primary"}
              variant={"white"}
              onClick={() =>
                navigate(ROUTES.ADD_GAP, {
                  state: {
                    maximumLevel: item?.levelId,
                    selectedDashboard: item?.dashboardRoute,
                  },
                })
              }
            />
          </>
        ) : (
          <>
            <div className="flex flex-row items-center gap-3">
              <div className="surface-secondary w-fit rounded-lg px-2 py-1 font-medium hover:bg-gray-200">
                <code className="text-xs">{item?.token}</code>
              </div>

              <Tooltip content="Copy Assessment Link">
                <button
                  className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                  onClick={() => handleCopyAssessmentLink(item?.token)}
                >
                  <HiLink className="size-5" />
                </button>
              </Tooltip>

              <Tooltip content="Open Assessment Link">
                <Link
                  to={generateAssessmentLink(item?.token)}
                  target="_blank"
                  rel="noreferrer"
                  className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
                  onClick={() =>
                    setInfoMsg(
                      "Your assessment link has been opened successfully!",
                    )
                  }
                >
                  <RiArrowRightUpLine className="size-5" />
                </Link>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
