export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

/**
 * Determines whether the current window or tab was opened using `window.open()`.
 *
 * This function checks the `window.opener` property to identify if the current window
 * or tab was opened programmatically by another window using `window.open()`. It is
 * useful for deciding if it is safe to call `window.close()` to close the current window
 * or tab, as many modern browsers restrict closing windows or tabs that were not opened
 * programmatically.
 *
 * @returns {boolean} - Returns `true` if the window was opened with `window.open()`,
 *                      and `false` if it was not.
 *
 * @example
 * if (canCloseWindow()) {
 *   window.close();
 * } else {
 *   console.log('This window was not opened using window.open() and cannot be closed programmatically.');
 * }
 */
export const canCloseWindow = () => {
  if (window.opener) {
    return true;
  } else {
    return false;
  }
};

export const delay = (milliseconds) =>
  new Promise((resolve) => setTimeout(() => resolve(), milliseconds));

// Function to check if two values are equal, including null and undefined
export const isEqual = (value1, value2) => {
  return value1 === value2 || (value1 == null && value2 == null);
};

// Function to take an ISO string formatted date and return a message showing how long ago this was
export const getTimeAgo = (isoString) => {
  const now = new Date();
  const past = new Date(isoString);
  const seconds = Math.floor((now - past) / 1000);

  if (seconds < 60) {
    return `${seconds} secs ago`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} mins ago`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hrs ago`;
  } else {
    const days = Math.floor(seconds / 86400);
    return `${days} days ago`;
  }
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getCurrentQuarter = () => {
  const month = new Date().getMonth() + 1; // Adding 1 because getMonth() returns zero-based index
  return Math.ceil(month / 3);
};

export const getMonthCodesForQuarter = (quarter) => {
  const startMonth = (quarter - 1) * 3 + 1;
  const monthCodes = [];

  for (let i = 0; i < 3; i++) {
    const monthIndex = startMonth + i;
    const month = new Date(2000, monthIndex - 1, 1).toLocaleString("en-us", {
      month: "short",
    });
    monthCodes.push(month);
  }

  return monthCodes;
};

export const getLetters = (string) => {
  return (
    string
      .replace(/ /g, "")
      .match(/[A-Za-z]/g)
      ?.join("") || ""
  );
};

// Function to detect OS
export const detectOS = () => {
  const userAgent = window.navigator.userAgent;
  let os = "";
  if (userAgent.indexOf("Win") !== -1) os = "Windows";
  else if (userAgent.indexOf("Mac") !== -1) os = "Mac";
  else if (userAgent.indexOf("Linux") !== -1) os = "Linux";
  else os = "Unknown";
  return os;
};

// Function to detect Browser
export const detectBrowser = () => {
  const userAgent = window.navigator.userAgent;
  let browser = "";
  if (userAgent.indexOf("Chrome") !== -1) browser = "Chrome";
  else if (userAgent.indexOf("Firefox") !== -1) browser = "Firefox";
  else if (userAgent.indexOf("Safari") !== -1) browser = "Safari";
  else if (userAgent.indexOf("Edge") !== -1) browser = "Edge";
  else browser = "Unknown";
  return browser;
};

// Function to open zendesk help widget
export const openZEWidget = () => {
  window.zE.activate();
};

// Function to get a random number in a specific range
export function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}
