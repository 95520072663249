import { API } from "aws-amplify";

export const updatePartnerDetails = async ({
  name,
  website,
  logo,
  favicon,
  dashboardCustomer,
  userData,
  // dashboardCustomer: { dashboard: 'msp', ABN: '12312312312', name: 'foo' }
  // userData: { firstname: 'foo', lastname: 'bar', email: 'a@.a.b', phone: '0433222111', role: 'Owner' } })
}) => {
  let result;
  try {
    const myInit = {
      body: {
        name,
        website,
        logo,
        favicon,
        dashboardCustomer,
        userData,
      },
    };
    result = await API.post("partner", "/details/update", myInit);
    // console.log(result);
  } catch (error) {
    console.error(error);
  }
  return result;
};
