import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function OverlaySpinner({ size = "xl", color = "darkGray" }) {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 z-20 flex items-center justify-center rounded-lg bg-white">
      <Spinner size={size} color={color} theme={SpinnerTheme} />
    </div>
  );
}
