export const parseDateShort = (d) => {
  if (!d) {
    return "NA";
  }
  const date = new Date(d);
  return `${date.getDate()} ${date.toLocaleString("en-US", {
    month: "short",
  })} ${date.getFullYear()}`;
};

export const parseDateTimeShort = (d) => {
  if (!d) {
    return "NA";
  }
  // Parse the ISO string into a Date object
  const date = new Date(d);

  // Use toLocaleString to format the date as desired
  // Specify the locale (e.g., 'en-US') and options for formatting
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true, // Use 12-hour format
  };

  // Return the formatted string
  return date.toLocaleString("en-US", options);
};
