import React from "react";

export const StatusLabel = ({ variant, label, animate }) => {
  switch (variant) {
    case "completed":
      return (
        <>
          <div className="flex w-fit cursor-pointer flex-row items-center gap-1 rounded-lg bg-green-50 px-2 py-1 hover:bg-green-100 hover:ring-4 hover:ring-green-200 focus:ring-4 focus:ring-green-500">
            <div className="relative flex h-3 w-3 items-center justify-center rounded-full bg-green-100">
              <div className="h-1.5 w-1.5 rounded-full bg-green-500" />
              {animate && (
                <div className="absolute h-1.5 w-1.5 animate-ping rounded-full ring-1 ring-green-300" />
              )}
            </div>

            <span className="label-completed-text whitespace-nowrap text-xs font-semibold">
              {label}
            </span>
          </div>
        </>
      );
    case "inprogress":
      return (
        <>
          <div className="flex w-fit cursor-pointer flex-row items-center gap-1 rounded-lg bg-orange-50 px-2 py-1 hover:bg-orange-100 hover:ring-4 hover:ring-orange-300 focus:ring-4 focus:ring-orange-500">
            <div className="relative flex h-3 w-3 items-center justify-center rounded-full bg-orange-100">
              <div className="h-1.5 w-1.5 rounded-full bg-orange-500" />
              {animate && (
                <div className="absolute h-1.5 w-1.5 animate-ping rounded-full ring-1 ring-orange-300" />
              )}
            </div>

            <span className="label-progress-text whitespace-nowrap text-xs font-semibold">
              {label}
            </span>
          </div>
        </>
      );
    case "assessed":
      return (
        <>
          <div className="flex w-fit cursor-pointer flex-row items-center gap-1 rounded-lg bg-blue-50 px-2 py-1 hover:bg-blue-100 hover:ring-4 hover:ring-blue-300 focus:ring-4 focus:ring-blue-500">
            <div className="relative flex h-3 w-3 items-center justify-center rounded-full bg-blue-100">
              <div className="h-1.5 w-1.5 rounded-full bg-blue-500" />
              {animate && (
                <div className="absolute h-1.5 w-1.5 animate-ping rounded-full ring-1 ring-blue-300" />
              )}
            </div>

            <span className="label-assess-text whitespace-nowrap text-xs font-semibold">
              {label}
            </span>
          </div>
        </>
      );
    case "assessing":
      return (
        <>
          <div className="flex w-fit cursor-pointer flex-row items-center gap-1 rounded-lg bg-blue-50 px-2 py-1 hover:bg-blue-100 hover:ring-4 hover:ring-blue-300 focus:ring-4 focus:ring-blue-500">
            <div className="relative flex h-3 w-3 items-center justify-center rounded-full bg-blue-100">
              <div className="h-1.5 w-1.5 rounded-full bg-blue-500" />
              {animate && (
                <div className="absolute h-1.5 w-1.5 animate-ping rounded-full ring-1 ring-blue-300" />
              )}
            </div>

            <span className="label-assess-text whitespace-nowrap text-xs font-semibold">
              {label}
            </span>
          </div>
        </>
      );
    case "notstarted":
      return (
        <>
          <div className="flex w-fit cursor-pointer flex-row items-center gap-1 rounded-lg bg-gray-50 px-2 py-1 hover:bg-gray-100 hover:ring-4 hover:ring-gray-300 focus:ring-4 focus:ring-gray-500">
            <div className="relative flex h-3 w-3 items-center justify-center rounded-full bg-gray-100">
              <div className="h-1.5 w-1.5 rounded-full bg-gray-500" />
              {animate && (
                <div className="absolute h-1.5 w-1.5 animate-ping rounded-full ring-1 ring-gray-300" />
              )}
            </div>

            <span className="whitespace-nowrap text-xs font-semibold text-gray-500">
              {label}
            </span>
          </div>
        </>
      );
    default:
      return (
        <>
          <div className="flex w-fit cursor-pointer flex-row items-center gap-1 rounded-lg bg-gray-50 px-2 py-1 hover:bg-gray-100 hover:ring-4 hover:ring-gray-300 focus:ring-4 focus:ring-gray-500">
            <div className="relative flex h-3 w-3 items-center justify-center rounded-full bg-gray-100">
              <div className="h-1.5 w-1.5 rounded-full bg-gray-500" />
              {animate && (
                <div className="absolute h-1.5 w-1.5 animate-ping rounded-full ring-1 ring-gray-300" />
              )}
            </div>

            <span className="whitespace-nowrap text-xs font-semibold text-gray-500">
              {label}
            </span>
          </div>
        </>
      );
  }
};
