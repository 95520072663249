import { Button } from "components";
import { Footer, Navbar } from "layouts";
import { HiChartPie } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "variables";

export function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className="surface-primary flex h-screen w-full flex-col justify-between">
        <div className="flex h-full flex-col gap-0">
          <Navbar variant="mfaCheck" />
          <div className="flex flex-col items-center justify-center gap-8 p-8 tablet:m-auto">
            <h1 className="text-primary text-4xl font-bold">404</h1>
            <p className="w-[60%] text-center">
              Sorry, the page you are looking for does not exist or has been
              moved.
            </p>

            <Button
              btnWidth={"w-fit"}
              leftIcon={<HiChartPie className="size-5" />}
              label={"Back to Dashboard"}
              labelStyles={"font-medium"}
              variant="white"
              onClick={() => navigate(ROUTES.ROOT)}
            />
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
