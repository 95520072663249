import { useEffect, useState } from "react";

import { AlternateTableTheme } from "assets";
import {
  AssessmentsResultsPanel,
  Button,
  DetailRow,
  DisplayLevelIcon,
} from "components";
import { Table } from "flowbite-react";
import { BsShieldFillExclamation } from "react-icons/bs";
import {
  HiArrowRight,
  HiCheck,
  HiOutlineDeviceMobile,
  HiOutlineMail,
} from "react-icons/hi";
import { RiInformationLine } from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import { getBaseIdRequirements, getFlatGAPAssessmentQuestions } from "services";
import { parseDateTimeShort } from "utils";
import { ROUTES } from "variables";

const RESULTS = [null, "BRONZE", "SILVER", "GOLD", "PLATINUM", "DIAMOND"];

export function ResultAssessment({
  organisation,
  assessment,
  assessmentResult,
}) {
  const { state } = useLocation();

  const [sortedResults, setSortedResults] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    // display no first in descending order
    let flatQuestions = getFlatGAPAssessmentQuestions([
      assessmentResult?.answers,
    ]);
    setSortedResults(() => {
      let results = flatQuestions?.sort((a, b) => {
        if (a.value === "no" && b.value === "no") {
          return a.requirement.localeCompare(b.requirement);
        } else if (a.value === "no") {
          return -1;
        } else if (b.value === "no") {
          return 1;
        } else {
          return a.requirement.localeCompare(b.requirement);
        }
      });

      const questionnaire = assessment?.GAP?.flatMap((a) => a);

      results?.forEach((result, index) => {
        results[index] = {
          ...result,
          question: questionnaire?.find(
            (question) => question?.requirement === result?.requirement,
          )?.question,
          status: { no: "missing", yes: "implemented" }[result.value],
        };
      });

      return results;
    });
  }, [assessment, assessmentResult]);

  return (
    <>
      <div className="flex flex-grow flex-col justify-between self-stretch">
        <div className="flex flex-col gap-8 p-8">
          {/* <div className="flex flex-col gap-2">
            {assessmentResult?.result > 0 ? (
              <>
                <div className="text-primary text-xl font-bold">
                  Your organisation is immediately qualified for a{" "}
                  {RESULTS[assessmentResult?.result - 1]} certification.
                </div>
              </>
            ) : (
              <>
                <div className="text-primary text-xl font-bold">
                  Your organisation is ineligible for minimum certification.
                </div>
              </>
            )}
            <span className="text-sm text-gray">
              You have completed the SMB1001 GAP assessment.
            </span>
          </div> */}

          <div className="flex flex-col gap-5 self-stretch">
            <span className="text-primary text-base font-bold">Results</span>

            <div className="flex flex-col gap-8 laptop:flex-row">
              <div className="flex w-full flex-col">
                {console.log(assessmentResult)}
                <div>
                  <DetailRow label={"Implemented requirements"}>
                    <span className="label-completed-text">
                      {Math?.min(
                        getBaseIdRequirements(
                          assessmentResult?.implementedRequirements,
                        )?.length,
                        assessment?.GAP[assessment?.GAP?.length - 1]?.length,
                      )}
                    </span>{" "}
                    / {assessment.GAP[assessment.GAP.length - 1].length}
                  </DetailRow>
                  <DetailRow label={"Qualified level"}>
                    <div className="flex flex-row items-center gap-0">
                      {!!RESULTS[assessmentResult?.result] ? (
                        <>
                          <DisplayLevelIcon
                            level={RESULTS[assessmentResult?.result]}
                          />

                          {RESULTS[assessmentResult?.result]}
                        </>
                      ) : (
                        <>
                          <BsShieldFillExclamation className="text-icons mr-2 size-4" />
                          <span>Ineligible for minimum certification</span>
                        </>
                      )}
                    </div>
                  </DetailRow>
                  <DetailRow label={"Completed by"}>
                    {!state?.preview
                      ? assessmentResult?.completedBy
                      : "N/A for Preview"}
                  </DetailRow>
                  <DetailRow label={"Verified via"}>
                    {!state?.preview ? (
                      <div className="flex flex-row items-center gap-2">
                        {assessmentResult?.verifiedVia &&
                          (() => {
                            switch (assessmentResult?.verifiedVia) {
                              case "email":
                                return (
                                  <HiOutlineMail className="text-icons size-5" />
                                );
                              case "mobile":
                                return (
                                  <HiOutlineDeviceMobile className="text-icons size-5" />
                                );
                              default:
                                return null;
                            }
                          })()}
                        {assessmentResult?.verifiedData}
                      </div>
                    ) : (
                      "N/A for Preview"
                    )}
                  </DetailRow>
                  <DetailRow label={"Completed on"}>
                    {parseDateTimeShort(assessmentResult?.completedAt)}
                  </DetailRow>
                </div>
              </div>

              <AssessmentsResultsPanel
                certificationStatus={RESULTS[organisation?.certificationLevel]}
                assessmentResult={{
                  result: {
                    ...assessmentResult,
                    qualifiedLevel: RESULTS[assessmentResult?.result],
                    completedOn: parseDateTimeShort(
                      assessmentResult?.completedAt,
                    ),
                  },
                  assessment,
                }}
              />
            </div>
          </div>

          <span className="text-gray text-sm">
            Your response has been recorded.
          </span>

          <div className="flex flex-col gap-4 tablet:flex-row">
            <Button
              customClasses="w-full tablet:w-fit"
              leftIcon={<HiArrowRight className="text-icons size-4" />}
              label={"You can now close this window"}
              variant={"white"}
              onClick={() => {
                if (state?.preview) {
                  navigate(ROUTES.VIEW_GAP.replace(":id", assessment?.id));
                } else {
                  window.close();
                  navigate(ROUTES.ROOT);
                }
              }}
              labelStyles={"font-semibold text-sm"}
            />

            {/* {!!assessmentResult?.result !== null &&
              assessmentResult?.result !== undefined && (
                <Button
                  customClasses="w-full tablet:w-fit"
                  label={"Redo Assessment"}
                  leftIcon={<HiRefresh className="size-4" />}
                  variant={"blue"}
                  onClick={() => {}}
                />
              )} */}
          </div>
        </div>

        <section id="cybercert-assessment-result-responses">
          <div className="flex flex-col gap-8 self-stretch px-5">
            <span className="text-xl font-bold">GAP Assessment</span>

            {/******************* PC *********************/}
            <div className="hidden laptop:block">
              <Table theme={AlternateTableTheme} hoverable>
                <Table.Head>
                  <Table.HeadCell>Required For</Table.HeadCell>
                  <Table.HeadCell>Question</Table.HeadCell>
                  <Table.HeadCell>Answer</Table.HeadCell>
                  <Table.HeadCell>Status</Table.HeadCell>
                </Table.Head>
                <Table.Body>
                  {sortedResults?.map((item, index) => (
                    <Table.Row key={index} className="font-medium">
                      <Table.Cell>{item.requirement}</Table.Cell>
                      <Table.Cell>{item.question}</Table.Cell>
                      <Table.Cell>
                        <span className="capitalize">{item.value}</span>
                      </Table.Cell>
                      <Table.Cell>
                        {item.status &&
                          (() => {
                            switch (item.status) {
                              case "missing":
                                return (
                                  <div className="text-gray flex w-fit flex-row items-center gap-1 whitespace-nowrap rounded-lg bg-gray-100 p-1 text-xs font-medium">
                                    <RiInformationLine />
                                    Control missing
                                  </div>
                                );
                              case "implemented":
                                return (
                                  <div className="flex w-fit flex-row items-center gap-1 rounded-lg bg-green-50 p-1 text-xs font-medium text-green-700">
                                    <HiCheck />
                                    Implemented
                                  </div>
                                );
                              default:
                                return null;
                            }
                          })()}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>

            {/******************* Mobile *********************/}

            <div className="flex flex-col laptop:hidden">
              <div className="surface-secondary text-primary border p-4 text-xs font-semibold uppercase">
                Assessments
              </div>

              <div className="flex flex-col border-x">
                {sortedResults?.map((item, index) => (
                  <div
                    key={index}
                    className="hover:surface-db-stepper flex flex-col gap-2 border-b p-5"
                  >
                    <div className="font-medium">{item.question}</div>
                    <div className="font-medium">
                      <span className="text-gray pr-1 font-normal">
                        Required for:
                      </span>
                      {item.requirement}
                    </div>
                    <div className="font-medium capitalize">
                      <span className="text-gray pr-1 font-normal">
                        Answer:
                      </span>
                      {item.value}
                    </div>
                    <div className="font-medium">
                      {item.status &&
                        (() => {
                          switch (item.status) {
                            case "missing":
                              return (
                                <div className="text-gray flex w-fit flex-row items-center gap-1 whitespace-nowrap rounded-lg bg-gray-100 p-1 text-xs font-medium">
                                  <RiInformationLine />
                                  Control missing
                                </div>
                              );
                            case "implemented":
                              return (
                                <div className="flex w-fit flex-row items-center gap-1 rounded-lg bg-green-50 p-1 text-xs font-medium text-green-700">
                                  <HiCheck />
                                  Implemented
                                </div>
                              );
                            default:
                              return null;
                          }
                        })()}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
