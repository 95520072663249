const styles = {
  primary:
    "btn-bg btn-text focus:ring-text-link-light enabled:hover:text-text-brand-light flex items-center justify-center gap-2 rounded-lg px-3 py-2 outline-none focus:ring-4 enabled:hover:bg-blue-300 enabled:hover:shadow-md",
  nav: "btn-bg btn-text focus:ring-text-link-light enabled:hover:text-text-brand-light flex items-center justify-center gap-4 rounded-md py-0.5 pl-10 pr-5 text-xxs outline-none focus:ring-4 enabled:hover:bg-blue-300 enabled:hover:shadow-md",
  icon: "text-icons focus:ring-text-link-light flex items-center justify-center rounded bg-transparent p-1 outline-none focus:ring-2 enabled:hover:bg-gray-200 enabled:hover:shadow-md enabled:hover:dark:text-gray-700",
  surface:
    "surface-nav text-primary primary flex items-center justify-center gap-2 rounded-lg px-3 py-2 outline outline-1 outline-gray-200 focus:ring-4 focus:ring-gray-400 enabled:hover:bg-gray-100 enabled:hover:shadow-md",
  disabled:
    "disabled:opacity-50 disabled:cursor-not-allowed  flex items-center justify-center gap-2 rounded-lg px-3 py-2 outline outline-1 outline-gray-200 focus:ring-4 focus:ring-gray-400 enabled:hover:bg-gray-100 enabled:hover:shadow-md",
};

export function FlowbiteButton({
  disabled,
  name = "",
  variant = "primary",
  onClick = () => {},
  className = "",
  children,
}) {
  return (
    <>
      <button
        disabled={disabled}
        name={name}
        className={`text-sm font-semibold -outline-offset-1 transition-all duration-100 ${styles[variant]} ${className}`}
        onClick={onClick}
      >
        {children}
      </button>
    </>
  );
}
