import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function PartnerPlatformFullWalkthroughDocs({ resourceLinks, title }) {
  return (
    <>
      <h2 className="text-2xl font-semibold">{title}</h2>
      {resourceLinks?.fullWalkthrough ? (
        <>
          <video className="w-full border border-gray-300" controls>
            <source src={resourceLinks?.fullWalkthrough} type="video/mp4" />
          </video>
        </>
      ) : (
        <>
          <Spinner theme={SpinnerTheme} color="darkGray" />
        </>
      )}

      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-lg text-black">
          Partner Platform Overview
        </p>
        <p>
          Once logged in, you have access to two dashboards. The certification
          dashboard, which lets you view and manage your organizations Cyber
          Cert certifications. This is also what your clients will have access
          to when they login. Your Partner dashboard is also listed here, which
          lets you view and manage your Cyber Assurance Program. In the partner
          dashboard we can initially see several widgets.
        </p>

        <p className="font-medium text-black">Partner Dashboard</p>
        <p>
          "Our clients" gives a high level summary of your client’s current
          CyberCert certification status. "Certification status" also gives more
          detail, organizing by newly certified and expiring certificates for
          the current quarter. Any available "certification subscription
          credits" for your account will be shown in this widget here.
        </p>

        <p className="font-medium text-black">GAP Assessments</p>
        <p>
          The GAP assessment functionality lets you send a private survey to
          your clients to determine their appropriate CyberCert certification
          level. To begin, head to Assessments, GAP Assessments and generate a
          new GAP assessment. There are 5 options to choose from which range
          from “Diamond and below” down to “Bronze only”. Each option has a
          different scope of questions and the results will be used to recommend
          a certification level. To distribute the survey, copy the assessment
          link and send it to your clients. The client will be asked to enter
          the email address as listed on CyberCert and their name for reference.
          During the survey, the client will be asked to answer questions about
          their current cybersecurity posture. After the survey is completed,
          the results will be shown on the survey results page including their
          current account status and a recommended certification level.
        </p>

        <p className="font-medium text-black">Subscriptions Management</p>
        <p>
          On the subscriptions page there are two methods to distribute
          subscriptions to your clients: Invite links or the Discounted Offer
          link. If your account has available credits, you can simply copy the
          link from the certification subscriptions credit widget and provide
          this link to your clients. When businesses use one of these links to
          sign up and register their CyberCert account, they will receive the
          chosen certification and can start the certification process
          immediately. On the left widget is the discounted partner offer link
          that gives any organisation a 35% discount at checkout to purchase a
          certification themselves. Simply copy this link and provide it to your
          clients. When they complete the purchase as shown and register their
          CyberCert account, they can start their certification immediately.
        </p>

        <p className="font-medium text-black">Partner Resources</p>
        <p>
          In the resources page you can also find example certificates, example
          letters of attestation, high resolution copies of the 2D and animated
          3D badges, the CyberCert brand kit, and copies of the workbook and
          worksheet documents.
        </p>

        <p className="font-medium text-black">Knowledge Base</p>
        <p>
          On the subscriptions page there are two methods to distribute
          subscriptions to your clients: Invite links or the Discounted Offer
          link. If your account has available credits, you can simply copy the
          link from the certification subscriptions credit widget and provide
          this link to your clients. When businesses use one of these links to
          sign up and register their CyberCert account, they will receive the
          chosen certification and can start the certification process
          immediately. On the left widget is the discounted partner offer link
          that gives any organisation a 35% discount at checkout to purchase a
          certification themselves. Simply copy this link and provide it to your
          clients. When they complete the purchase as shown and register their
          CyberCert account, they can start their certification immediately.
        </p>

        <p className="font-medium text-black">Account Management</p>
        <p>
          In settings you can manage your organisation details and add other
          admin users, assigning them access to the partner dashboard as well.
        </p>
      </div>
    </>
  );
}
