import { useState } from "react";

import { Button } from "components/buttons";
import { Checkbox } from "components/checkbox";
import { useDashboard } from "contexts";
import { HiCheck } from "react-icons/hi";

export function ConfirmFooter({ handleNextStep }) {
  const { dashboard } = useDashboard();

  const [checked, setChecked] = useState(false);

  const handleCheckbox = () => {
    setChecked(!checked);
  };

  return (
    <>
      <div className="surface-db-stepper flex flex-col gap-4 p-8">
        <div className="text-primary text-lg font-semibold">
          Confirm your link distribution
        </div>
        <div className="flex flex-row items-center gap-2">
          <Checkbox checked={checked} onChange={handleCheckbox} />
          <p>
            I have distributed the link to all of my {dashboard?.orgPlural} so
            they can start their GAP assessments.
          </p>
        </div>
        <div className="mt-3 w-fit">
          <Button
            leftIcon={<HiCheck className="size-4" />}
            label={"Next Step"}
            variant={`${!checked ? "disabled" : "blue"}`}
            onClick={handleNextStep}
            disabled={!checked}
          />

          {/* this button should be disabled until checkbox is ticked */}
        </div>
      </div>
    </>
  );
}
