import { useEffect, useState } from "react";

import { generateGAPAssessment } from "api";
import { LoadingSpinner } from "components";
import { useDashboard } from "contexts";
import { Footer, Navbar } from "layouts";
import { SidebarProcess } from "layouts/sidebar/sidebar-process";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "utils";
import { INITIAL_GAP_ASSESSMENTS } from "variables";

import { DistributeLink } from "./distribute";
import { FinishAssessmentSetup } from "./finish";
import { SetupAssessment } from "./setup";

export function AddGapAssessment() {
  const { dashboard } = useDashboard();
  const { state } = useLocation();

  const [isLoading, setIsLoading] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [assessmentSetup, setAssessmentSetup] = useState({
    dashboard: dashboard?.route,
    maximumLevel: state?.maximumLevel || 1,
  });
  const [generatedAssessment, setGeneratedAssessment] = useState();

  const stepTitles = ["Setup Assessment", "Distribute Links", "Finish"];

  const getSteps = () => {
    return stepTitles.map((_, index) => {
      if (index < currentStep || currentStep >= stepTitles.length - 1) {
        return "completed";
      } else if (index === currentStep) {
        return "inprogress";
      } else {
        return "todo";
      }
    });
  };

  const handleChange = (event) => {
    setAssessmentSetup((prevSetup) => ({
      ...prevSetup,
      [event.target.name]: event.target.value,
    }));
  };

  const handleNextStep = async () => {
    setIsLoading(true);

    if (!!assessmentSetup?.dashboard && !!assessmentSetup?.maximumLevel) {
      const result = await generateGAPAssessment({
        maximumLevel: assessmentSetup?.maximumLevel,
        dashboard: assessmentSetup?.dashboard,
      });
      setGeneratedAssessment(result?.assessment);
      if (!result?.assessment) {
        setIsLoading(false);
        return;
      }
    }

    setCurrentStep((prevStep) =>
      Math.min(prevStep + 1, stepTitles?.length - 1),
    );

    setIsLoading(false);
  };

  useEffect(() => scrollToTop, [currentStep]);

  return (
    <>
      <Navbar
        variant="primeProcess"
        processTitle={`Generate ${
          INITIAL_GAP_ASSESSMENTS.filter(
            (a) => a.levelId === parseInt(assessmentSetup.maximumLevel),
          )[0]?.level
        }`}
      />

      <div className="surface-primary flex flex-grow flex-col self-stretch laptop:flex-row">
        <SidebarProcess
          title="Steps"
          steps={getSteps()}
          stepTitles={stepTitles}
        />

        <div className="flex flex-grow flex-col justify-between self-stretch">
          {!!isLoading ? (
            <LoadingSpinner />
          ) : (
            [
              <SetupAssessment
                assessmentSetup={assessmentSetup}
                handleChange={handleChange}
                handleNextStep={handleNextStep}
              />,
              <DistributeLink
                generatedAssessment={generatedAssessment}
                progress={currentStep}
                handleNextStep={handleNextStep}
              />,
              <FinishAssessmentSetup />,
            ][currentStep]
          )}

          <Footer />
        </div>
      </div>
    </>
  );
}
