import React from "react";

import { PaginationTheme } from "assets";
import { Pagination } from "flowbite-react";

export function FlowbitePagination({
  currentPage,
  totalPages,
  onPageChange,
  totalCount,
  selectedOption,
  handleOptionClick,
}) {
  const generateItemsPerPageOptions = (totalCount) => {
    let options = [];

    if (totalCount > 100) {
      const pages = Math.ceil(totalCount / 100);
      options = Array.from({ length: pages }, (_, index) => (index + 1) * 100);
    } else if (totalCount > 50) {
      options = [10, 20, 50, 100];
    } else if (totalCount > 20) {
      options = [10, 20, 50];
    } else if (totalCount > 10) {
      options = [10, 20];
    } else {
      options = [10];
    }

    return options;
  };

  // Generate dynamic itemsPerPageOptions based on totalCount
  const itemsPerPageOptions = generateItemsPerPageOptions(totalCount);

  return (
    <div
      id="customPagination"
      className={`flex flex-col items-center justify-between gap-5 border border-t-0 px-5 py-5 laptop:flex-row laptop:py-3`}
    >
      {totalCount > itemsPerPageOptions[0] ? (
        <div className="text-gray flex items-center gap-2 text-sm">
          Showing
          {itemsPerPageOptions.map((option, index) => (
            <button
              key={index}
              disabled={option === selectedOption}
              className={`min-w-6 py-1 ${
                option === selectedOption
                  ? "text-primary rounded bg-gray-100 px-1 font-semibold ring-1 ring-gray-300"
                  : "text-gray px-1 hover:underline"
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {Math.min(
                option,
                totalCount - (currentPage - 1) * selectedOption,
              )}
            </button>
          ))}
          of
          <span className="text-primary px-0 py-1 text-sm font-semibold">
            {totalCount}
          </span>
        </div>
      ) : (
        <div className="text-gray flex items-center gap-2 text-sm">
          Showing
          <button
            className={`text-primary min-w-6 rounded bg-gray-100 px-2 py-1 font-semibold ring-1 ring-gray-300`}
            onClick={() => handleOptionClick(totalCount)}
          >
            {totalCount}
          </button>
          of
          <span className="text-primary px-0 py-1 text-sm font-semibold">
            {totalCount}
          </span>
        </div>
      )}

      {totalCount >= itemsPerPageOptions[0] && (
        <Pagination
          theme={PaginationTheme}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          previousLabel=""
          nextLabel=""
          showIcons
        />
      )}
    </div>
  );
}
