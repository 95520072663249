import { useEffect, useState } from "react";

import { UpdateButton } from "components/buttons";
import { AccountCreationCard } from "components/cards";
import { DetailRow } from "components/detail-row";
import { FlowbiteButton } from "components/flowbite";
import { AddUsersModal, UpdateUserModal } from "components/modals";
import { UsersTable } from "components/tables";
import { HiPlus } from "react-icons/hi";
import { parseDateTimeShort } from "utils";

export function AdminSettingsUserTab({
  data,
  handlePasswordReset = async () => {},
  handleSync = async () => {},
}) {
  const [userData, setUserData] = useState();
  const [usersData, setUsersData] = useState([]);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateUser, setUpdateUser] = useState({});

  const handleCloseAddModal = async (refetch = true) => {
    setShowAddUsersModal(false);
  };

  const handleOpenUpdateUsersModal = (user) => {
    setIsUpdateModalOpen(true);
    setUpdateUser(user);
  };

  const handleCloseUpdateUsersModal = () => {
    setIsUpdateModalOpen(false);
    setUpdateUser({});
  };

  useEffect(() => {
    let uData = data?.user;
    uData = {
      ...uData,
      name: `${uData?.firstName} ${uData?.lastName}`,
      online: parseDateTimeShort(uData?.loginAt),
    };
    let usData = data?.users.map((u) => ({
      ...u,
      name: `${u?.firstName} ${u?.lastName}`,
      online: parseDateTimeShort(u?.loginAt),
      ABN: data?.partner?.ABN,
    }));
    setUsersData(usData);
    setUserData(uData);
  }, [data]);

  return (
    <>
      <section id="cybercert-admin-settings-users">
        <div className="flex flex-col gap-8 tablet:px-5">
          <div className="flex flex-col gap-8 self-stretch laptop:flex-row">
            <div className="flex w-full flex-col gap-8 self-stretch">
              <div className="flex flex-col gap-5 self-stretch">
                <span className="text-primary text-sm font-bold">
                  {userData?.isAdmin ? "Admin " : ""}User Details
                </span>

                <div className="">
                  <DetailRow label="Name">
                    {userData?.firstName} {userData?.lastName}
                  </DetailRow>
                  <DetailRow label="Work Email Address">
                    {userData?.email}
                  </DetailRow>
                  <DetailRow label="Mobile">{userData?.phone}</DetailRow>
                  <DetailRow label="Role">{userData?.role}</DetailRow>
                  {!userData?.isSSO && (
                    <DetailRow label="Password">
                      <div className="flex">
                        <input
                          className="w-full border-none bg-inherit p-0"
                          disabled
                          type="password"
                          value={"password"}
                        />
                        <UpdateButton onClick={handlePasswordReset} />
                      </div>
                    </DetailRow>
                  )}
                </div>
              </div>

              <FlowbiteButton
                size="small"
                className="w-full laptop:w-fit"
                onClick={() => handleOpenUpdateUsersModal(userData)}
              >
                Update
              </FlowbiteButton>
            </div>

            <div className="flex w-full flex-col gap-8">
              <AccountCreationCard />
            </div>
          </div>

          <span className="text-primary text-sm font-bold">All Users</span>

          <UsersTable data={usersData} handleSync={handleSync} />

          <FlowbiteButton
            size="small"
            className="w-full laptop:w-fit"
            onClick={() => setShowAddUsersModal(true)}
          >
            <HiPlus className="size-4" />
            Add Users
          </FlowbiteButton>
        </div>
      </section>

      <AddUsersModal
        isOpen={showAddUsersModal}
        handleCloseModal={handleCloseAddModal}
        handleSync={handleSync}
      />

      <UpdateUserModal
        isOpen={isUpdateModalOpen}
        user={updateUser}
        handleCloseModal={handleCloseUpdateUsersModal}
        handleSync={handleSync}
      />
    </>
  );
}
