import { useState } from "react";

import { AssessmentFooter, AssessmentTitle, Button } from "components";
import { HiChevronDown } from "react-icons/hi";

const ASSESSMENT_DETAIL = {
  title: "SMB1001 GAP Assessment",
  description:
    "Your organisation has been requested to complete a short assessment to determine your certification status.",
  duration: "Takes about 1-5 mins ~",
  // requestedBy: assessment?.partner?.name,
  // requestedTo: "Acme Pty Ltd",
  // requestedABN: "12345678910",
  // representative: "Jane Smith",
  // verifiedVia: "email",
  // verifiedData: "jsmith@acme.com",
  // verifiedUser: false,
};

export function SidebarAssessment({ assessment }) {
  const [showFooter, setShowFooter] = useState(false);

  const toggleFooter = () => {
    setShowFooter(!showFooter);
  };

  return (
    <>
      {/******************** Mobile Assessment Sidebar ********************/}
      <div className="surface-nav border-primary flex w-full flex-col gap-8 border-b p-8 laptop:hidden">
        <AssessmentTitle
          title={ASSESSMENT_DETAIL.title}
          description={ASSESSMENT_DETAIL.description}
          duration={ASSESSMENT_DETAIL.duration}
        />

        {showFooter && (
          <AssessmentFooter
            requestedBy={assessment?.partner?.name}
            requestedTo={assessment?.organisation?.name}
            requestedABN={assessment?.organisation?.ABN}
            representative={assessment?.assessmentResult?.completedBy}
            verifiedVia={assessment?.assessmentResult?.verifiedVia}
            verifiedData={assessment?.assessmentResult?.verifiedData}
            verifiedUser={!!assessment?.organisation}
          />
        )}

        <Button
          customClasses={`w-fit`}
          rightIcon={
            <HiChevronDown
              className={`size-5 transform ${showFooter ? "rotate-180" : ""}`}
            />
          }
          label={showFooter ? "Hide Details" : "View Details"}
          labelStyles={"font-semibold text-sm"}
          variant={"white"}
          onClick={toggleFooter}
        />
      </div>

      {/******************** Laptop Assessment Sidebar ********************/}
      <div
        className={`surface-nav border-primary relative hidden w-[360px] border-r p-8 laptop:block`}
      >
        <div className="flex h-full flex-col justify-between">
          <AssessmentTitle
            title={ASSESSMENT_DETAIL.title}
            description={ASSESSMENT_DETAIL.description}
            duration={ASSESSMENT_DETAIL.duration}
          />

          <AssessmentFooter
            requestedBy={assessment?.partner?.name}
            requestedTo={assessment?.organisation?.name}
            requestedABN={assessment?.organisation?.ABN}
            representative={assessment?.assessmentResult?.completedBy}
            verifiedVia={assessment?.assessmentResult?.verifiedVia}
            verifiedData={assessment?.assessmentResult?.verifiedData}
            verifiedUser={!!assessment?.organisation}
          />
        </div>
      </div>
    </>
  );
}
