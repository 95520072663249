import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";

import ReactCanvasConfetti from "react-canvas-confetti";
import { randomInRange } from "utils";

export const Confetti = forwardRef((_, ref) => {
  const refConfettiInstance = useRef(null);

  const params = useMemo(
    () => ({
      duration: 2 * 1000, // 2seconds
      opts: { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 },
      styles: {
        position: "fixed",
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
      },
    }),
    [],
  );

  const handleInit = useCallback(({ confetti }) => {
    refConfettiInstance.current = confetti;
  }, []);

  const makeShot = useCallback(() => {
    if (refConfettiInstance.current) {
      refConfettiInstance.current({
        ...params.opts,
        particleCount: 90,
        origin: {
          x: randomInRange(0.1, 0.3),
          y: Math.random() - 0.2,
        },
      });
      refConfettiInstance.current({
        ...params.opts,
        particleCount: 90,
        origin: {
          x: randomInRange(0.7, 0.9),
          y: Math.random() - 0.2,
        },
      });
    }
  }, [params.opts]);

  useImperativeHandle(ref, () => ({
    fire() {
      const interval = setInterval(makeShot, 400);
      setTimeout(() => {
        clearInterval(interval);
      }, params.duration);
    },
  }));

  return <ReactCanvasConfetti style={params.styles} onInit={handleInit} />;
});
