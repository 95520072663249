import { useState } from "react";

import { Breadcrumbs, Button } from "components";
import { useDashboard } from "contexts";
import { Modal, Tooltip } from "flowbite-react";
import { PageHeader } from "layouts";
import {
  HiChartPie,
  HiCheck,
  HiClock,
  HiQuestionMarkCircle,
} from "react-icons/hi";
import { IoMdVideocam } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "variables";

export function AssessmentsPage() {
  const navigate = useNavigate();

  const { dashboard } = useDashboard();

  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const assessments = [
    {
      title: "SMB1001 GAP Assessment",
      description: `Quickly assess ${dashboard?.orgSingle} conformity to SMB1001 certification levels.`,
      duration: "Less than 5 mins",
      available: true,
      link: ROUTES.GAP,
    },
    // {
    //   title: "Tier Categorisation",
    //   description: `Categorise ${dashboard?.orgPlural} into Tier 1 or Tier 2 ${dashboard?.orgTitle}.`,
    //   duration: "Less than 10 mins",
    //   available: false,
    //   link: "",
    // },
    // {
    //   title: "Single Click",
    //   description: "Complete an assessment and certify immediately.",
    //   duration: "Less than 30 - 15 mins",
    //   available: false,
    //   link: "",
    // },
  ];

  const handleVideo = () => {
    setIsVideoOpen(true);
  };

  return (
    <>
      <PageHeader
        variant="primePage"
        pageName={`All Assessments`}
        pageDescription={`Conduct assessments to gain deeper insights into the cyber maturity of each ${dashboard?.orgSingle} and facilitate their certifications.`}
        customBreadcrumbs={
          <Breadcrumbs
            icon={HiChartPie}
            items={{
              "": ROUTES.ROOT,
              Assessments: ROUTES.ASSESSMENTS,
            }}
          />
        }
      />

      <div className="flex flex-col gap-8 laptop:mt-0 laptop:flex-row">
        {assessments.map((assessment, index) => (
          <div
            key={index}
            className="panel-common !shadow-none flex w-[350px] flex-col justify-between gap-5"
          >
            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between self-stretch">
                <h3 className="text-lg font-semibold">{assessment.title}</h3>

                <div className="flex items-center gap-1">
                  {/* <Tooltip // RANI TODO
                    animation="duration-150"
                    content="Video Tutorial"
                    placement="bottom"
                    trigger="hover"
                  >
                    <HiMiniPlayCircle
                      className="btn-text-green size-5 cursor-pointer"
                      onClick={handleVideo}
                    />
                  </Tooltip> */}
                  <Tooltip
                    animation="duration-150"
                    content={
                      <div className="flex flex-col gap-2">
                        <span>SMB1001 GAP Assessment</span>
                        <span className="font-normal text-gray-300">
                          GAP Assessment assesses organisations conformity to
                          the SMB1001 certification levels.
                          <br />
                          <br />
                          It is a quick survey that asks respondents to list the
                          status of each implementation to then calculate the
                          recommended SMB1001 certification for the
                          organisation.
                        </span>
                      </div>
                    }
                    placement="bottom"
                    trigger="click"
                  >
                    <HiQuestionMarkCircle className="text-icons size-5 cursor-pointer" />
                  </Tooltip>
                </div>
              </div>
              <p>{assessment.description}</p>
              <div className="flex flex-row items-center gap-1">
                <HiClock className="text-icons size-4" />{" "}
                <p>{assessment.duration}</p>
              </div>
            </div>
            {assessment.available ? (
              <Button
                variant={"white"}
                leftIcon={<HiCheck className="size-4" />}
                label={"Get Started"}
                labelStyles={"font-semibold text-sm"}
                customClasses={"w-fit"}
                onClick={() => navigate(assessment.link)}
              />
            ) : (
              <div className="flex w-fit items-center rounded-lg bg-gray-200 px-2 py-1">
                <span className="text-gray text-xs font-bold uppercase">
                  Available soon
                </span>
              </div>
            )}
          </div>
        ))}
      </div>

      <Modal
        popup
        size="5xl"
        show={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        theme={{ header: { popup: "border-b-0" } }}
      >
        <Modal.Header>
          <div className="flex items-center gap-3">
            <IoMdVideocam />
            <span className="text-secondary">SMB1001 GAP Assessment</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="w-full border border-gray-300"
            src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/widget_1.mp4"
            controls
            playsInline
          ></video>
        </Modal.Body>
      </Modal>
    </>
  );
}
