import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";

export function DisplayLevelIcon({ level, size }) {
  const getIcon = (level) => {
    if (level?.includes("DIAMOND")) {
      return (
        <img
          src={IconDiamond}
          alt="Diamond"
          className={
            size ? `${size} mr-2 inline-block` : "mr-2 inline-block size-5"
          }
        />
      );
    } else if (level?.includes("PLATINUM")) {
      return (
        <img
          src={IconPlatinum}
          alt="Platinum"
          className={
            size ? `${size} mr-2 inline-block` : "mr-2 inline-block size-5"
          }
        />
      );
    } else if (level?.includes("GOLD")) {
      return (
        <img
          src={IconGold}
          alt="Gold"
          className={
            size ? `${size} mr-2 inline-block` : "mr-2 inline-block size-5"
          }
        />
      );
    } else if (level?.includes("SILVER")) {
      return (
        <img
          src={IconSilver}
          alt="Silver"
          className={
            size ? `${size} mr-2 inline-block` : "mr-2 inline-block size-5"
          }
        />
      );
    } else if (level?.includes("BRONZE")) {
      return (
        <img
          src={IconBronze}
          alt="Bronze"
          className={
            size ? `${size} mr-2 inline-block` : "mr-2 inline-block size-5"
          }
        />
      );
    }
    return null;
  };

  return getIcon(level);
}
