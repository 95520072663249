import { useEffect, useState } from "react";

import { placeholderLogo } from "assets";
import { LoadingSpinner } from "components";
import { useVerifyPartnerAccount } from "hooks";
import { Footer, Navbar, Sidebar } from "layouts";

export function LayoutWrapper({ children }) {
  const [isLoading, setIsLoading] = useState(false);

  const verifyPartnerState = useVerifyPartnerAccount();
  let partnerLogoL = !verifyPartnerState?.data?.partner?.logo?.includes(
    "favicon",
  )
    ? verifyPartnerState?.data?.partner?.logo
    : null;

  useEffect(() => {
    if (verifyPartnerState?.data?.result) {
      setIsLoading(false);
    }
  }, [verifyPartnerState?.data]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Navbar
        variant="primeNoSearch"
        primeLogo={
          verifyPartnerState?.data?.partner?.logoUrl ||
          verifyPartnerState?.data?.partner?.faviconUrl ||
          placeholderLogo
        }
        primeLogoL={partnerLogoL}
        orgName={verifyPartnerState?.data?.partner?.name || "CAP Partner"}
      />

      <div className="grid flex-grow grid-rows-[auto_1fr] laptop:grid-cols-[auto_1fr] laptop:grid-rows-1">
        <Sidebar variant="prime" />

        <div className="grid grid-cols-1 grid-rows-[1fr_auto]">
          <main className="relative mx-0 flex w-full flex-col gap-8 px-8 pt-8 tv:mx-auto tv:max-w-[1536px]">
            {children}
          </main>

          {/* <div className=""></div> */}

          <Footer />
        </div>
      </div>
    </>
  );
}
