import React, { cloneElement } from "react";

import { useDashboard } from "contexts";
import { Tooltip } from "flowbite-react";
import { BsBookmarkFill } from "react-icons/bs";
import { RiShieldFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { getAppPage } from "services";
import { ROUTES } from "variables";

export function PlatformSwitcher() {
  const { dashboard } = useDashboard();

  return (
    <div className="flex flex-row items-center">
      <Tooltip content="Change Dashboard" placement="bottom">
        <Link
          className="btn-bg flex flex-row items-center gap-1 rounded-l-lg border border-blue-300 p-2 outline-none transition-all hover:bg-blue-200 focus:ring-2 focus:ring-inset focus:ring-blue-300"
          to={ROUTES.ROOT}
        >
          <div className="relative flex size-5 flex-row items-center">
            <RiShieldFill className="text-primary h-full w-full" />
            {!!dashboard?.icon && // render if dashboard is selected
              cloneElement(dashboard?.icon, {
                className:
                  "absolute object-center text-white h-2.5 w-2.5 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2",
              })}
          </div>
          <span className="btn-text text-xs font-semibold hidden tablet:flex">
            {dashboard?.title}
          </span>
        </Link>
      </Tooltip>

      <Tooltip content="Change Platform" placement="bottom">
        <Link
          className="border-primary flex flex-row items-center gap-1 rounded-r-lg border bg-white p-2 shadow-sm outline-none transition-all hover:bg-gray-200 focus:border-blue-300 focus:ring-2 focus:ring-inset focus:ring-blue-300"
          to={getAppPage()}
        >
          <div className="relative flex size-5 flex-row items-center">
            <BsBookmarkFill className="text-icons size-4" />
          </div>
          <span className="hidden tablet:flex text-gray text-xs font-semibold">
            Certification
          </span>
        </Link>
      </Tooltip>
    </div>
  );
}
