const baseWidth = 245;
const baseHeight = 123;

export function SemiCircleSlider({
  width = 245,
  height = 123,
  emptyColor = "#F3F4F6",
  fillColor = "#31C48D",
  notchColor = "#15803D",
  gradientColor = "#B7CEC5",
  value,
  className,
}) {
  const aspectRatio = baseWidth / baseHeight;
  if (width / height > aspectRatio) {
    width = height * aspectRatio;
  } else {
    height = width / aspectRatio;
  }

  const angle = (value / 100) * 180;

  return (
    <>
      <div
        className={`relative overflow-hidden p-0.5 ${className}`}
        style={{ width: width, height: height }}
      >
        {/* Empty Bar */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 245 123`}
          fill="none"
          className="absolute bottom-0 z-20"
        >
          <path
            d="M245 122.5C245 106.413 241.831 90.4837 235.675 75.6213C229.519 60.7589 220.496 47.2546 209.121 35.8794C197.745 24.5042 184.241 15.481 169.379 9.32476C154.516 3.16855 138.587 -7.03182e-07 122.5 0C106.413 7.03182e-07 90.4837 3.16856 75.6213 9.32476C60.7589 15.481 47.2546 24.5042 35.8794 35.8794C24.5042 47.2546 15.481 60.7589 9.32475 75.6213C3.16855 90.4837 -1.40636e-06 106.413 0 122.5H18.4348C18.4348 108.834 21.1265 95.3017 26.3562 82.676C31.586 70.0502 39.2514 58.5781 48.9148 48.9148C58.5781 39.2514 70.0502 31.586 82.6759 26.3563C95.3017 21.1265 108.834 18.4348 122.5 18.4348C136.166 18.4348 149.698 21.1265 162.324 26.3562C174.95 31.586 186.422 39.2514 196.085 48.9148C205.749 58.5781 213.414 70.0502 218.644 82.676C223.874 95.3017 226.565 108.834 226.565 122.5H245Z"
            fill={emptyColor}
          />
        </svg>

        {/* Fill Bar */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 245 123`}
          fill="none"
          className="absolute bottom-0 z-20 origin-bottom"
          style={{ transform: `rotate(${angle + 180}deg)` }}
        >
          <path
            d="M245 122.5C245 106.413 241.831 90.4837 235.675 75.6213C229.519 60.7589 220.496 47.2546 209.121 35.8794C197.745 24.5042 184.241 15.481 169.379 9.32476C154.516 3.16855 138.587 -7.03182e-07 122.5 0C106.413 7.03182e-07 90.4837 3.16856 75.6213 9.32476C60.7589 15.481 47.2546 24.5042 35.8794 35.8794C24.5042 47.2546 15.481 60.7589 9.32475 75.6213C3.16855 90.4837 -1.40636e-06 106.413 0 122.5H18.4348C18.4348 108.834 21.1265 95.3017 26.3562 82.676C31.586 70.0502 39.2514 58.5781 48.9148 48.9148C58.5781 39.2514 70.0502 31.586 82.6759 26.3563C95.3017 21.1265 108.834 18.4348 122.5 18.4348C136.166 18.4348 149.698 21.1265 162.324 26.3562C174.95 31.586 186.422 39.2514 196.085 48.9148C205.749 58.5781 213.414 70.0502 218.644 82.676C223.874 95.3017 226.565 108.834 226.565 122.5H245Z"
            fill={fillColor}
          />
        </svg>

        {/* Slider Notch */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${baseWidth} 2`}
          fill="none"
          className="absolute bottom-0 z-20"
          style={{
            transform: `
                rotate(${angle}deg) 
                translateX(${Math.sin((angle * Math.PI) / 180) * 0.5 - 1.5}px)
              `,
          }}
        >
          <path
            d="M0 1.49999C4.32866e-08 1.00485 0.00300204 0.509723 0.00900581 0.0146237L22.1576 0.283207C22.1527 0.688783 22.1502 1.09439 22.1502 1.49999L0 1.49999Z"
            fill={notchColor}
          />
        </svg>

        {/* Shade */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 245 123"
          fill="none"
          className="absolute bottom-0 z-10"
        >
          <path
            d="M245 122.5C245 106.413 241.831 90.4837 235.675 75.6213C229.519 60.7589 220.496 47.2546 209.121 35.8794C197.745 24.5042 184.241 15.481 169.379 9.32476C154.516 3.16855 138.587 -7.03182e-07 122.5 0C106.413 7.03182e-07 90.4837 3.16856 75.6213 9.32476C60.7589 15.481 47.2546 24.5042 35.8794 35.8794C24.5042 47.2546 15.481 60.7589 9.32475 75.6213C3.16855 90.4837 -1.40636e-06 106.413 0 122.5L122.5 122.5H245Z"
            fill="url(#paint0_linear_4305_120346)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_4305_120346"
              x1="123"
              y1="122"
              x2="126"
              y2="3.1914e-06"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor={gradientColor} />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </>
  );
}
