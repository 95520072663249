import { useEffect, useState } from "react";

import { PlaceholderAvatar } from "assets";
import { getTimeAgo } from "utils";

export function RecentActivityCard({ avatar, name, id, date, msg }) {
  const [timeAgo, setTimeAgo] = useState(getTimeAgo(date));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(getTimeAgo(date));
    }, 1000); // Change update time here

    return () => clearInterval(interval);
  }, [date]);

  return (
    <>
      <div className="border-primary surface-white flex flex-col gap-4 self-stretch rounded-lg border px-5 py-4">
        <div className="flex gap-4">
          <div className="border-secondary size-12.5 flex items-center justify-center rounded-full border-2">
            <img
              src={avatar || PlaceholderAvatar}
              alt={`${name || "Placeholder"} Avatar`}
              className="h-6 w-6"
            />
          </div>
          <div className="flex flex-col">
            <span className="text-primary text-base font-bold">
              {name} ({id})
            </span>
            <span className="text-gray text-sm font-normal">{timeAgo}</span>
          </div>
        </div>

        <span className="text-sub text-base font-normal">{msg}</span>
      </div>
    </>
  );
}
