import { useRef } from "react";

import { BsCloudUpload } from "react-icons/bs";
import { HiOutlineSearch } from "react-icons/hi";

import { FlowbiteButton } from "../button";

export function FileInputFlowbite({ onUpload }) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    onUpload(file);
  };

  return (
    <div className="flex h-[228px] flex-col items-center justify-center gap-2.5 self-stretch border-b">
      <div className="flex flex-col items-center gap-4">
        <div className="flex flex-col items-center gap-2">
          <BsCloudUpload className="text-icons h-10 w-10" />

          <span className="text-gray text-sm">
            <span className="font-semibold">Click to upload</span> or drag and
            drop
          </span>

          <span className="text-gray text-xs font-semibold">
            Max. File Size: 30MB
          </span>
        </div>

        <FlowbiteButton variant="primary" onClick={handleButtonClick}>
          <HiOutlineSearch className="size-4" />
          Browse File
        </FlowbiteButton>
      </div>

      <input
        type="file"
        id="dropzone-file"
        className="hidden"
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
    </div>
  );
}
