export function Button({
  btnWidth = "",
  className = "",
  customClasses,
  label,
  labelStyles = "",
  leftIcon,
  name = "",
  iconOnly,
  rightIcon,
  variant = "default",
  disabled,
  animate,
  type,
  onClick = () => {},
}) {
  const variants = {
    blue: "btn-bg btn-text border border-transparent outline-none focus:ring-4 focus:ring-blue-400 enabled:hover:bg-blue-200 disabled:opacity-50 enabled:hover:dark:bg-blue-700",
    disabled: `bg-gray-200 hover:bg-gray-300 dark:bg-dark-3 focus:ring-4 focus:ring-gray-400 dark:#202225 cursor-not-allowed`,
    white: `surface-primary border border-primary outline-none enabled:hover:bg-gray-200 focus:ring-4 focus:ring-gray-400 dark:enabled:hover:bg-gray-700 disabled:opacity-70`,
    black: `bg-black text-white outline-none focus:ring-4 focus:ring-blue-300 enabled:hover:bg-gray-800 disabled:opacity-50`,
    gray: `surface-secondary text-sub`,
    plain: `text-link`,
    red: "bg-red-100 text-red-600 border border-transparent outline-none focus:ring-4 focus:ring-red-400 enabled:hover:bg-red-200 disabled:opacity-50 enabled:hover:dark:bg-red-700",
    green:
      "bg-green-100 text-green-600 outline-none focus:ring-4 focus:ring-green-400 enabled:hover:bg-green-200 disabled:opacity-50",
    iconOnly: ``,
    iconAndTextOnly: `m-0 p-0`,
    purple: `flex flex-row items-center gap-1.5 bg-purple-700 text-white px-3 py-0 h-[44px] rounded-lg enabled:hover:bg-purple-800 text-sm font-semibold w-fit focus:ring-4 focus:ring-purple-400`,
    sky: `flex flex-row items-center gap-1.5 bg-sky-700 text-white px-3 py-0 h-[44px] rounded-lg enabled:hover:bg-sky-800 text-sm font-semibold w-fit focus:ring-4 focus:ring-sky-400`,
  };

  return (
    <>
      <button
        className={`${variants[variant]} ${btnWidth} flex items-center justify-center gap-2 rounded-lg px-3 py-2 ${className} ${customClasses} ${animate}`}
        onClick={onClick}
        disabled={disabled}
        name={name}
        type={type}
      >
        {iconOnly && <>{iconOnly}</>}
        {leftIcon && <div className="flex w-fit items-center">{leftIcon}</div>}
        <div className={!!labelStyles ? labelStyles : "text-sm font-semibold"}>
          {label}
        </div>
        {rightIcon && (
          <div className="flex w-fit items-center">{rightIcon}</div>
        )}
      </button>
    </>
  );
}
