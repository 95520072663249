import { API } from "aws-amplify";

/**
 * Fetch resource from S3
 *
 * Example:
 * fetchResourceS3({ scope: "t2", type: "ALL" }); // Fetch all resources for t2
 * fetchResourceS3({ scope: "dashboard", type: "ALL" }); // Fetch all resources for dashboard (partner)
 *
 * @param {string} scope
 *                    | "t2"
 *                    | "dashboard"
 * @param {string} type
 *                    | "ALL": any completed attestation letters
 *                    | "ATTESTATION_LETTER":
 *                          + (t2) any completed attestation letters (t2)
 *                    | "ATTESTATION_LETTER_DEMO":
 *                          + (dashboard) all 3 of the demo attestation letters
 *                    | "CERTIFICATE":
 *                          + (t2) any completed certificates
 *                    | "CERTIFICATE_DEMO":
 *                          + (dashboard) all 3 of the the demo certification letters
 *                    | "BADGE":
 *                          | (t2) folder badges for certified level
 *                          | (dashboard) folder badges for all levels
 *                    | "WORKBOOK":
 *                          | (t2) workbook for certified level
 *                          | (dashboard) workbook for all levels
 *                    | "OVERVIEW_DECK":
 *                          + overview deck for all levels
 *                    | "PARTNER_DECK":
 *                          + (dashboard) partner deck for all levels
 *                    | "STYLE_GUIDE"
 *                          + style guide for all levels
 *                    | "ASSURANCE_PROGRAM"
 *                          + assurance program for MSP partner
 *                    | "KNOWLEDGE_CENTER"
 *                          + knowledge center for partner
 *                    | "CLIENT_DECK":
 *                          + (dashboard) client deck for all levels
 * @param {string} id (optional) id of specific certification
 * @returns urls of the resources
 */
export const fetchResourceS3 = async (input = {}) => {
  let result;
  let { scope, type, id } = input;

  try {
    const myInit = {
      queryStringParameters: {
        scope: scope || "dashboard",
        type: type || "ALL",
        id,
      },
    };
    result = await API.get("s3", "/fetch", myInit);
  } catch (e) {
    console.error(e, e.stack);
  }
  return result;
};
