import React from "react";

import { NavLink, useLocation } from "react-router-dom";

export function SidebarItemLink({
  leftIcon,
  pageName,
  rightIcon,
  link,
  isSidebarOpen,
}) {
  const transitionClass = "transition-all duration-2000 ease-out-back";
  const location = useLocation();

  return (
    <li className="w-fit rounded-lg hover:bg-gray-100">
      <NavLink
        to={link || "/404"}
        isActive={() => location.pathname.startsWith(link)}
        className={({ isActive }) =>
          `${isActive ? "text-primary font-semibold" : "text-icons font-medium"} ${
            isSidebarOpen ? "laptop:w-full" : "hidden laptop:block laptop:w-fit"
          } ${transitionClass}`
        }
      >
        <div className="flex items-center justify-start gap-3 self-stretch px-2">
          {isSidebarOpen ? leftIcon : <span>{leftIcon}</span>}
          <span
            className={`text-primary flex-1 self-stretch whitespace-nowrap text-left text-base ${
              isSidebarOpen ? `laptop:block` : `laptop:hidden`
            }`}
          >
            {pageName}
          </span>
        </div>
        {rightIcon && <div className="flex items-center">{rightIcon}</div>}
      </NavLink>
    </li>
  );
}

export function SidebarItemButton({
  leftIcon,
  pageName,
  rightIcon,
  link,
  isSidebarOpen,
  onClick = () => {},
}) {
  const transitionClass = "transition-all duration-2000 ease-out-back";

  return (
    <li className="w-fit rounded-lg hover:bg-gray-100">
      <button
        className={`text-icons font-medium ${isSidebarOpen ? "laptop:w-full" : "hidden laptop:block laptop:w-fit"} ${transitionClass}`}
        onClick={onClick}
      >
        <div className="flex items-center justify-start gap-3 self-stretch px-2">
          {isSidebarOpen ? leftIcon : <span>{leftIcon}</span>}
          <span
            className={`text-primary flex-1 self-stretch whitespace-nowrap text-left text-base ${
              isSidebarOpen ? `laptop:block` : `laptop:hidden`
            }`}
          >
            {pageName}
          </span>
        </div>
        {rightIcon && <div className="flex items-center">{rightIcon}</div>}
      </button>
    </li>
  );
}
