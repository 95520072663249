import { useState } from "react";

import { CyberCertBlackLogo, StylingGuideThumbnail } from "assets";
import { Button, OverlaySpinner } from "components";
import { Alert } from "flowbite-react";
import { HiCheckCircle } from "react-icons/hi";
import { MdContentCopy } from "react-icons/md";

import { ResourceCard } from "./resource-card";

export function ResourcesBrandKit({ resources }) {
  const colourPalette = ["#16336D", "#195ADC", "#EFF7FF", "#F9FAFB"];

  const [copyHex, setCopyHex] = useState("");

  const handleClick = (colour) => {
    navigator.clipboard.writeText(colour).then(() => {
      setCopyHex(`The colour ${colour} has been copied to your clipboard.`);
    });
  };

  return (
    <>
      {!resources ? (
        <OverlaySpinner />
      ) : (
        <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
          <ResourceCard
            title="Logo"
            description="Our logo is available in SVG format. Please review our CyberCert
              Styling Guide document for avoiding incorrect use of our logo."
            img={CyberCertBlackLogo}
            classNameImg={"w-1/2"}
            onClick={() => {
              !!resources["logo"] && window.open(resources["logo"], "_blank");
            }}
          />
          <ResourceCard
            title="CyberCert Styling Guide"
            description="Please refer to this style guide for a better understanding of how to use our resources according to our brand's guidelines."
            img={StylingGuideThumbnail}
            classNameImg={"w-full"}
            onClick={() => {
              !!resources["style_guide"] &&
                window.open(resources["style_guide"], "_blank");
            }}
          />
          <div className="flex flex-col gap-8">
            {!!copyHex && (
              <Alert
                color={"green"}
                onDismiss={() => setCopyHex("")}
                icon={HiCheckCircle}
              >
                <span className="text-base font-semibold">{copyHex}</span>
              </Alert>
            )}
            <ResourceCard title="Colour Palette">
              <div className="grid grid-cols-2 gap-4 laptop:grid-cols-4">
                {colourPalette.map((colour) => (
                  <div
                    key={colour}
                    className="flex flex-col items-center justify-center gap-4"
                  >
                    <div
                      className="border-primary size-12.5 rounded-lg shadow laptop:h-[70px] laptop:w-[70px] desktop:h-[100px] desktop:w-[100px]"
                      style={{ backgroundColor: colour }}
                    />
                    <Button
                      variant="white"
                      label={colour}
                      labelStyles="font-medium text-xs"
                      customClasses="w-fit"
                      rightIcon={
                        <MdContentCopy className="text-icons size-4" />
                      }
                      onClick={() => handleClick(colour)}
                    />
                  </div>
                ))}
              </div>
            </ResourceCard>
          </div>
        </div>
      )}
    </>
  );
}
