import { useEffect, useState } from "react";

import { Button, TextInput } from "components";
import { useDashboard } from "contexts";
import { HiCheck, HiPlus, HiX } from "react-icons/hi";
import {
  validateABN,
  validateEmail,
  validateEmpty,
  validateMobile,
} from "utils";

export function ImportOrgsManual({
  orgs,
  setOrgs = () => {},
  setErrMsg = () => {},
  setWarnMsg = () => {},
  handleUploadOrgs = () => {},
}) {
  const { dashboard } = useDashboard();

  const checkDuplicateABNs = (orgs, currentIndex, newABN) => {
    let isDuplicateABN = false;

    // Strip new ABN here for processing
    newABN = newABN?.trim()?.replace(/\s/g, "");

    // Now add those ABNs from the other editor orgs and the new ABN value coming in
    const allABNs = [
      ...orgs
        ?.map((org, index) => {
          if (index !== currentIndex) {
            return org?.ABN?.trim()?.replace(/\s/g, "");
          } else {
            return newABN;
          }
        })
        .filter((ABN) => ABN !== ""),
    ];

    // If ABN appears more than twice it is a duplicate
    orgs?.forEach((org, index) => {
      const checkABN = index !== currentIndex ? org?.ABN : newABN;

      if (allABNs.indexOf(checkABN) !== allABNs.lastIndexOf(checkABN)) {
        orgs[index].duplicateABN = true;
        isDuplicateABN = true;
      } else {
        orgs[index].duplicateABN = false;
      }
    });

    if (isDuplicateABN) {
      setWarnMsg(
        "You have entered a duplicate ABN. Please check you have not already uploaded this ABN."
      );
    } else {
      setWarnMsg(null);
    }
  };

  const handleAddNew = () => {
    setOrgs((prevOrgs) => [
      ...prevOrgs,
      {
        name: "",
        ABN: "",
        email: "",
        mobile: "",
        error: {
          name: false,
          ABN: false,
          email: false,
          mobile: false,
        },
        duplicate: false,
      },
    ]);
  };

  const handleRemove = (target) => {
    if (orgs?.length === 1) {
      setOrgs([
        {
          name: "",
          ABN: "",
          email: "",
          mobile: "",
          error: {
            name: false,
            ABN: false,
            email: false,
            mobile: false,
          },
          duplicate: false,
        },
      ]);
      return;
    }
    setOrgs((prev) => prev.filter((_, index) => index !== target));
  };

  const handleInput = (event, index) => {
    const newOrgs = [...orgs];
    newOrgs[index][event.target.name] =
      event.target.name === "ABN"
        ? event.target.value.replace(/ /g, "")
        : event.target.value;
    newOrgs[index]["error"][event.target.name] = false;

    checkDuplicateABNs(newOrgs, index, event?.target?.value);

    setOrgs(newOrgs);
  };

  const handlePaste = (event, index) => {
    event.preventDefault();

    const paste = event.clipboardData.getData("text").replace(/\s/g, "");
    if (/[0-9\s]/.test(paste)) {
      const newOrgs = [...orgs];

      const input = event.target;
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;
      const originalValue = newOrgs[index][event.target.name];
      const value =
        originalValue.substring(0, startPos) +
        paste +
        originalValue.substring(endPos);
      input.setSelectionRange(startPos + paste.length, startPos + paste.length);

      newOrgs[index][event.target.name] = value;
      newOrgs[index].error[event.target.name] = false;
      newOrgs[index].duplicate = false;

      setOrgs(newOrgs);
    }
  };

  const handleSubmit = async () => {
    let submitOrgs = [
      ...orgs?.map((org) => ({
        ...org,
        name: org?.name?.trim(),
        ABN: org?.ABN?.replace(/\s/g, ""),
        email: org?.email?.trim(),
        mobile: org?.mobile?.replace(/\s/g, ""),
      })),
    ];

    let isInvalidField = false;
    submitOrgs.forEach((org) => {
      if (!validateEmpty(org?.name)) {
        org.error.name = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.ABN) || !validateABN(org?.ABN)) {
        org.error.ABN = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.email) || !validateEmail(org?.email)) {
        org.error.email = true;
        isInvalidField = true;
      }
      if (validateEmpty(org?.mobile) && !validateMobile(org?.mobile)) {
        org.error.mobile = true;
        isInvalidField = true;
      }
    });

    if (!!isInvalidField) {
      setErrMsg("You have entered an invalid field. Please check again.");
      setOrgs(submitOrgs);
      return;
    }

    handleUploadOrgs(submitOrgs);
  };

  useEffect(() => {
    if (
      !orgs?.some((org) => Object.values(org.error).some((error) => !!error))
    ) {
      setErrMsg(null);
    }
  }, [orgs]);

  return (
    <>
      <div className="flex flex-col gap-4 self-stretch">
        <div className="text-primary hidden text-sm font-medium laptop:flex laptop:gap-4">
          <span className="invisible">{orgs?.length}.</span>
          <span className="ml-1 w-full">{dashboard?.orgTitleSingle}</span>
          <span className="ml-1 w-full">ABN</span>
          <span className="ml-1 w-full">Email</span>
          <span className="ml-1 w-full">Mobile</span>
          <span className="size-5 shrink-0"></span>
        </div>

        {orgs?.map((org, index) => (
          <ImportOrgsManualRow
            key={index}
            item={org}
            index={index}
            handleInput={handleInput}
            handlePaste={handlePaste}
            handleRemove={handleRemove}
            handleSubmit={handleSubmit}
          />
        ))}

        <button
          className="btn-text flex w-fit flex-row items-center gap-1 self-stretch rounded px-1 
                      focus-visible:ring-2 focus-visible:ring-blue-300 focus-visible:ring-offset-2 enabled:hover:opacity-80"
          onClick={handleAddNew}
        >
          <HiPlus className="h-3.5 w-3.5" />
          <span className="text-sm font-semibold">Add New</span>
        </button>
      </div>

      <section>
        <Button
          label="Submit"
          leftIcon={<HiCheck className="size-4" />}
          variant="blue"
          onClick={handleSubmit}
        />
      </section>
    </>
  );
}

function ImportOrgsManualRow({
  item,
  index,
  handleInput = () => {},
  handlePaste = () => {},
  handleRemove = () => {},
  handleSubmit = () => {},
}) {
  const { dashboard } = useDashboard();

  return (
    <form
      className="flex flex-col items-start gap-4 laptop:flex-row laptop:items-center"
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit();
      }}
    >
      <div className="text-primary flex flex-row gap-1 text-sm font-medium">
        <span className="flex laptop:hidden">{dashboard?.orgTitleSingle}</span>{" "}
        {index + 1}.
      </div>

      <TextInput
        color={!!item?.error?.["name"] ? "failure" : "gray"}
        name="name"
        placeholder={`Enter ${dashboard?.orgTitleSingle} name`}
        required
        type="text"
        value={item?.name}
        className={"w-full"}
        onInput={(event) => handleInput(event, index)}
        // onPaste={handlePaste}
      />

      <TextInput
        color={
          !!item?.error?.["ABN"]
            ? "failure"
            : !!item?.duplicateABN
              ? "warning"
              : "gray"
        }
        name="ABN"
        placeholder={`Enter ${dashboard?.orgSingle} ABN`}
        required
        type="tel"
        value={item?.ABN}
        className={"w-full"}
        onInput={(event) => handleInput(event, index)}
        // onPaste={handlePaste}
      />

      <TextInput
        color={!!item?.error?.["email"] ? "failure" : "gray"}
        name="email"
        placeholder={`Enter ${dashboard?.orgSingle} email`}
        required
        type="email"
        className={"w-full"}
        value={item?.email}
        onInput={(event) => handleInput(event, index)}
        // onPaste={handlePaste}
      />

      <TextInput
        color={!!item?.error?.["mobile"] ? "failure" : "gray"}
        id={`mobile-${index}`}
        label="Optional"
        name="mobile"
        placeholder={`Enter ${dashboard?.orgSingle} mobile`}
        required
        type="tel"
        className={"w-full"}
        value={item?.mobile}
        onInput={(event) => handleInput(event, index)}
        // onPaste={handlePaste}
      />

      <button
        className="rounded focus:ring-2 focus:ring-gray-400 enabled:hover:bg-gray-200"
        type="button"
        onClick={() => handleRemove(index)}
      >
        <HiX className="text-gray size-5" />
      </button>
    </form>
  );
}
