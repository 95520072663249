import { useEffect, useState } from "react";

import { StatusPartnerOrgsData } from "api";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const key = "partnerOrgsStatusData";

export const useFetchPartnerOrgsStatusData = (dashboard) => {
  return useQuery({
    queryKey: [key, dashboard],
    // refetchInterval: 1000,
    queryFn: () => StatusPartnerOrgsData({ dashboard }),
  });
};

export const useSyncPartnerOrgsStatusData = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ dashboard }) => StatusPartnerOrgsData({ dashboard }),
    onSuccess: (data, variables) => {
      queryClient.setQueryData([key, variables.dashboard], data);
    },
  });

  return async ({ dashboard }) => {
    await mutation.mutateAsync({ dashboard });
  };
};

export const usePartnerOrgsStatus = (dashboard) => {
  // Initialize useQuery to fetch data
  const { data, error, isLoading, isError, isSuccess } = useQuery({
    queryKey: [key, dashboard],
    queryFn: () => StatusPartnerOrgsData({ dashboard }),
  });

  // Get query client to manage cache and mutations
  const queryClient = useQueryClient();

  // State to control polling
  const [isPolling, setIsPolling] = useState(false);

  // Function to start polling
  const startPolling = () => {
    setIsPolling(true);
  };

  // Function to stop polling
  const stopPolling = () => {
    setIsPolling(false);
  };

  // Effect to trigger polling when POST request is made
  useEffect(() => {
    if (isPolling) {
      const intervalId = setInterval(() => {
        queryClient.invalidateQueries([key, dashboard]);
      }, 1000); // Adjust the polling interval as needed

      return () => clearInterval(intervalId);
    }
  }, [isPolling, key, queryClient]);

  // Function to update the state by refetching data
  const updateState = async () => {
    // Refetch data for the given query key
    await queryClient.invalidateQueries([key, dashboard]);
  };

  // Return query state and update function
  return {
    partnerOrgsStatus: data,
    error,
    isLoading,
    isError,
    isSuccess,
    updatePartnerOrgsStatus: updateState,
    startPolling,
    stopPolling,
  };
};
