import { useEffect, useState } from "react";
import { ProgressBarTheme } from "assets";
import { FileInputFlowbite } from "components";
import { useDashboard } from "contexts";
import { Progress } from "flowbite-react";
import { HiDocument, HiDownload, HiX } from "react-icons/hi";
import Papa from "papaparse";
import { sanitizeInputWithSpace } from "utils";

export function ImportOrgsUpload({
  setIsLoading,
  setErrMsg,
  setWarnMsg,
  handleImportComplete = () => {},
}) {
  const [upload, setUpload] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const { dashboard } = useDashboard();

  /**
   * Handle the completion of the upload process
   */
  const handleUploadFinish = () => {
    setUploadComplete(true);

    // Read the file content and log it
    // const reader = new FileReader();
    // reader.onload = (event) => {
    //   console.log(event.target.result);
    // };
    // reader.readAsText(upload);
    if (!upload) {
      return;
    }

    Papa.parse(upload, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        if (!results.data?.length) {
          handleImportComplete([]);
        }
        const lowercasedData = results.data.map((item) => {
          const lowercasedItem = {};
          for (const key in item) {
            if (item.hasOwnProperty(key)) {
              lowercasedItem[sanitizeInputWithSpace(key.toLowerCase())] =
                item[key];
            }
          }
          return lowercasedItem;
        });
        handleImportComplete(lowercasedData);
      },
    });
  };

  const handleUploadStart = (file) => {
    setUploadProgress(0);
    setUpload(file);
  };

  const handleUploadCancel = () => {
    setUpload(null);
  };

  useEffect(() => {
    if (!upload) return;

    const interval = setInterval(() => {
      // Simulate upload progress
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return prevProgress;
        }
        return prevProgress + 1;
      });
    }, 50);

    return () => clearInterval(interval);
  }, [upload]);

  useEffect(() => {
    if (uploadProgress >= 100) {
      handleUploadFinish();
    }
  }, [uploadProgress]);

  return (
    <div className="border-primary surface-primary flex flex-col gap-5 self-stretch rounded-lg border p-5">
      {!!upload ? (
        <>
          <span className="text-primary text-base font-semibold">
            Adding {dashboard?.orgTitle}
          </span>

          <div className="flex items-center gap-3 self-stretch">
            <div className="flex items-center gap-1 self-stretch">
              <HiDocument className="text-icons h-10 w-10" />

              <div className="flex flex-col self-stretch">
                <span className="text-link text-sm font-semibold leading-tight">
                  {upload?.name}
                </span>

                <span className="text-gray text-xs font-normal">
                  ({upload?.size})
                </span>
              </div>
            </div>

            <div className="flex-grow">
              <Progress
                progress={uploadProgress}
                size="md"
                theme={ProgressBarTheme}
                color="blue"
              />
            </div>

            <span className="text-gray text-sm font-medium">
              {uploadComplete ? "Completed " : "Uploading "} {uploadProgress}%
            </span>

            <button
              className="rounded hover:bg-gray-200 focus:ring-2 focus:ring-gray-400"
              onClick={handleUploadCancel}
            >
              <HiX className="text-gray size-5" />
            </button>
          </div>
        </>
      ) : (
        <>
          <span className="text-primary text-base font-semibold">
            Import a File
          </span>

          <FileInputFlowbite onUpload={handleUploadStart} />

          <div className="flex flex-col items-center gap-1 self-stretch tablet:flex-row">
            <span className="text-gray text-center text-xs font-normal tablet:text-left">
              Download our supplier template that you can fill in and import.
            </span>
            <a
              href="https://cybercert-public-import-template.s3.ap-southeast-2.amazonaws.com/template.csv"
              download
              onClick={(event) => {
                event.preventDefault();
                window.location.href =
                  "https://cybercert-public-import-template.s3.ap-southeast-2.amazonaws.com/template.csv";
              }}
            >
              <button className="text-link flex items-center gap-1 self-stretch rounded ring-blue-400 ring-offset-1 focus-visible:ring-1 enabled:hover:opacity-80">
                <HiDownload className="h-3.5 w-3.5" />
                <span className="whitespace-nowrap text-xs font-semibold">
                  Download Template
                </span>
              </button>
            </a>
          </div>
        </>
      )}
    </div>
  );
}
