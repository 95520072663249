import { useEffect, useState } from "react";

export function FilterButton({ children, impIsSelected, ...rest }) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(impIsSelected);
  }, [impIsSelected]);

  const handleFilterSelection = () => {
    setIsSelected((prevSelected) => !prevSelected);
  };

  return (
    <button
      className={`border-secondary flex items-center justify-center gap-2 rounded-lg border px-3 py-1 text-xs font-medium 
                  focus:ring-2 focus:ring-gray-400 focus:ring-offset-1 enabled:hover:shadow-sm
                  ${isSelected ? "bg-gray-900 text-white enabled:hover:bg-gray-600" : "text-primary surface-db-stepper enabled:hover:bg-gray-200"}`}
      onClick={handleFilterSelection}
      {...rest}
    >
      {children}
    </button>
  );
}
