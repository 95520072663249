import {
  useEffect,
  useState,
} from 'react';

/**
 * Custom hook to get the scroll position of a table's parent element.
 * @param {React.RefObject} ref - Reference to the table element.
 * @returns {Object} Scroll position with `scrollTop` and `scrollLeft` properties.
 */
export const useTableScrollPosition = (ref) => {
  // State to store scroll position
  const [scrollPosition, setScrollPosition] = useState({
    // scrollTop: 0,
    scrollLeft: 0,
  });

  useEffect(() => {
    // Function to update scroll position state
    const handleScroll = () => {
      if (ref.current && ref.current.parentElement) {
        setScrollPosition({
          //   scrollTop: ref.current.parentElement.scrollTop,
          scrollLeft: ref.current.parentElement.scrollLeft,
        });
      }
    };

    // Adding scroll event listener to the table's parent element
    const parentElement = ref.current ? ref.current.parentElement : null;
    if (parentElement) {
      parentElement.addEventListener("scroll", handleScroll);
    }

    // Cleanup function to remove event listener
    return () => {
      if (parentElement) {
        parentElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ref]); // Effect dependencies

  return scrollPosition;
};
