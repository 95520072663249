import { useEffect, useState } from "react";

import { TextInputTheme } from "assets";
import { Button } from "components";
import { PartnerBenefitCard } from "components/cards/partner-benefit-card";
import { TextInput } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import {
  filterNumberAndSpaceKeyPress,
  filterNumberPaste,
  parseDateShort,
} from "utils";

export function PartnerTypeOnboarding({
  partner,
  handleInput = () => {},
  handleNextStep = () => {},
}) {
  const [err, setErr] = useState(false);
  const [benefits, setBenefits] = useState([
    { label: "Subscription Discount", value: "50%" },
    { label: "Discount Expiry", value: "6 months" },
    { label: "Minimum Volume Purchase (Subscriptions)", value: 1000 },
    { label: "Volume Subscription Discount", value: "50%" },
  ]);

  useEffect(() => {
    // if (partner?.isTypeSupplyChain) { //@Rani dont add this back please
    setBenefits([
      {
        label: "Subscription Discount",
        value: `${partner?.subscriptionDiscount}%` || "NA",
      },
      {
        label: "Discount Expiry",
        value: partner?.codeExpiry ? parseDateShort(partner.codeExpiry) : "NA",
      },
      {
        label: "Minimum Volume Purchase (Subscriptions)",
        value: partner?.minimumVolume || "Nil",
      },
      {
        label: "Volume Subscription Discount",
        value: !!partner?.volumeDiscount ? `${partner?.volumeDiscount}%` : "NA",
      },
    ]);
    //}
  }, [partner]);

  return (
    <>
      <div className="flex flex-col">
        <div className="text-primary w-fit text-lg font-bold">
          Your Partner Type
        </div>

        <div className="text-gray w-fit text-sm">
          Finalised partner information as per our mutually agreed-upon
          contract.
        </div>
      </div>

      <div className="text-primary flex flex-col gap-8 self-stretch text-sm font-medium laptop:flex-row">
        <PartnerBenefitCard partnerBenefits={benefits} />

        <div className="flex w-full flex-col justify-between gap-8 self-stretch">
          <div className="flex flex-col-reverse justify-center gap-4 self-stretch laptop:gap-8">
            <div className="flex flex-col gap-2 self-stretch">
              Partner Type
              <TextInput
                name="partner-type"
                type="text"
                value={partner?.partnerType}
                disabled
                required
                shadow
                onChange={handleInput}
                onKeyPress={filterNumberAndSpaceKeyPress}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                onPaste={filterNumberPaste}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>

            <div className="flex flex-col gap-2 self-stretch">
              Program Type
              <TextInput
                name="entity"
                type="text"
                value={"Cyber Assurance Program (CAP)"}
                disabled
                required
                shadow
                onChange={handleInput}
                // onKeyDown={(event) => handleKeyDownSubmission(event, submitRef)}
                color={!!err ? "failure" : "gray"}
                theme={TextInputTheme}
              />
            </div>
          </div>

          <Button
            customClasses={`w-fit hidden laptop:flex`}
            leftIcon={<HiCheck className="size-4" />}
            label={"Next Step"}
            labelStyles={"font-semibold text-sm"}
            variant={"blue"}
            onClick={handleNextStep}
          />
        </div>

        <Button
          customClasses={`laptop:hidden`}
          leftIcon={<HiCheck className="size-4" />}
          label={"Next Step"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={handleNextStep}
        />
      </div>
    </>
  );
}
