import { FaChevronRight } from "react-icons/fa";

export function FeatureList({ children }) {
  return (
    <>
      <div className="flex flex-col gap-2">{children}</div>
    </>
  );
}

export function FeatureListItem({ content }) {
  return (
    <>
      <div className="flex flex-row items-start gap-3">
        <span>
          <FaChevronRight className="text-link mt-1 h-3 w-3" />
        </span>
        {content}
      </div>
    </>
  );
}
