import { PaginationTheme } from "assets";
import { Button } from "components";
import { Pagination } from "flowbite-react";
import { Footer } from "layouts";
import { HiArrowLeft, HiArrowRight, HiX } from "react-icons/hi";

// const questions = [
//   "Engage a technical support specialist for your organization",
//   "Install and configure a firewall",
//   "Install anti-virus software on all organizational devices",
//   "Automatically install tested and approved software updates and patches on all organizational devices",
//   "Change passwords routinely",
// ];

export function AssessmentSections({
  currentStep,
  section,
  questions,
  save,
  submit,
  error,
  preview,
  disabledSubmit,
  handleAnswer = () => {},
  handleChangeStep = () => {},
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const handleInput = ({ id, value, question }) => {
    handleAnswer({ id, value, section, question });
  };

  return (
    <>
      <div className="flex flex-grow flex-col justify-between self-stretch">
        <div className="flex flex-col gap-8 p-8">
          <div className="flex flex-col items-center justify-between laptop:flex-row">
            <div className="text-primary text-xl font-bold">
              {/* Section {section} */}
              Questions
            </div>
            <span className="text-gray text-sm font-semibold">
              {questions[section - 1]?.length} questions to complete
            </span>
          </div>

          <div className="flex flex-col gap-16">
            {questions[section - 1]?.map((question) => (
              <div key={question?.id} className="flex flex-col gap-4">
                <div className="text-base font-normal">
                  {`${question?.questionNo}`} {question?.question}
                </div>
                <div>
                  <label className="inline-flex cursor-pointer items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name={`question_${question?.id}`}
                      value="yes"
                      onClick={() =>
                        handleInput({
                          id: question?.id,
                          value: "yes",
                          question: question?.question,
                        })
                      }
                      defaultChecked={question?.value === "yes"}
                    />
                    <span className="text-primary ml-2 text-base">Yes</span>
                  </label>
                  <label className="ml-4 inline-flex cursor-pointer items-center">
                    <input
                      type="radio"
                      className="form-radio"
                      name={`question_${question?.id}`}
                      value="no"
                      onClick={() =>
                        handleInput({
                          id: question?.id,
                          value: "no",
                          question: question?.question,
                        })
                      }
                      defaultChecked={question?.value === "no"}
                    />
                    <span className="text-primary ml-2 text-base">No</span>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-0">
          {!!error && (
            <div className="flex flex-col gap-3 pl-8 text-red-500 laptop:w-[50%]">
              <p className="font-bold">{error}</p>
            </div>
          )}

          <ActionAssessmentPanel
            currentStep={currentStep}
            numSections={questions?.length}
            submit={submit}
            save={save}
            preview={preview}
            disabledSubmit={disabledSubmit}
            handleChangeStep={handleChangeStep}
            handleNextStep={handleNextStep}
            handlePrevStep={handlePrevStep}
          />
          <Footer />
        </div>
      </div>
    </>
  );
}

function ActionAssessmentPanel({
  currentStep,
  numSections,
  submit,
  save,
  preview,
  disabledSubmit,
  handleChangeStep = () => {},
  handleNextStep = () => {},
  handlePrevStep = () => {},
}) {
  const onPageChange = (page) => {
    handleChangeStep(page - 1);
  };

  return (
    <>
      <div className="surface-db-stepper border-primary flex w-full flex-col justify-between gap-2 border-b border-t p-8 laptop:flex-row">
        <div className="flex flex-col items-center gap-2 laptop:flex-row">
          {!preview && (
            <Button
              customClasses={`w-full laptop:w-fit laptop:flex`}
              leftIcon={<HiX className="size-4" />}
              label={"Save & Exit"}
              labelStyles={"font-semibold text-sm"}
              variant={"white"}
              onClick={save}
            />
          )}
          {currentStep > 0 && (
            <>
              <Button
                customClasses={`w-full laptop:w-fit laptop:flex`}
                leftIcon={<HiArrowLeft className="size-4" />}
                label={"Back"}
                labelStyles={"font-semibold text-sm"}
                variant={"blue"}
                onClick={handlePrevStep}
              />
            </>
          )}
          <Button
            customClasses={`w-full laptop:w-fit laptop:flex`}
            leftIcon={<HiArrowRight className="size-4" />}
            label={"Continue"}
            labelStyles={"font-semibold text-sm"}
            variant={"blue"}
            onClick={handleNextStep}
          />
        </div>

        {numSections > 1 && (
          <>
            <div className="flex flex-col-reverse items-center gap-5 laptop:flex-row laptop:gap-2">
              <div className="flex flex-col items-center justify-center gap-2 laptop:flex-row">
                <span className="text-primary text-sm font-medium">Pages</span>
                <Pagination
                  currentPage={Math.max(
                    0,
                    Math.min(currentStep + 1, numSections),
                  )}
                  totalPages={numSections}
                  onPageChange={onPageChange}
                  theme={PaginationTheme}
                  previousLabel=""
                  nextLabel=""
                  showIcons
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
