function getBaseId(version) {
  const versionParts = version?.split(".");
  return versionParts?.slice(0, 2)?.join(".") + ".0.0";
}

/**
 *
 * @param {*} reqs
 * @returns
 */
export function getBaseIdRequirements(reqs) {
  return reqs?.length > 0 ? [...new Set(reqs?.map(getBaseId))] : [];
}

export function getGAPQuestionnaireCount(assessment) {
  return Array.from(
    new Set(
      assessment?.questionnaire
        ?.flatMap((item) => item)
        ?.map((item) => item?.requirement)
        .filter(Boolean)
        .reduce((acc, requirement) => {
          const [firstPart, secondPart] = requirement.split(".");
          const key = `${firstPart}.${secondPart}`;
          if (!acc.has(key)) {
            acc.add(key);
            return acc;
          }
          return acc;
        }, new Set())
    )
  ).length;
}

export function getFlatGAPAssessmentQuestions(questions) {
  let flatQuestions = questions?.flatMap((q) => q) || [];
  let result = [];
  flatQuestions.forEach((q) => {
    if (result.every((r) => r?.requirement !== q?.requirement)) {
      result.push({ ...q, questionNo: `${result.length + 1}.0` });
    }
  });
  return result;
}
