import { useState } from "react";

import { Modal, Tooltip } from "flowbite-react";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { IoMdVideocam } from "react-icons/io";
import {
  getCurrentQuarter,
  getCurrentYear,
  getMonthCodesForQuarter,
} from "utils";

export function InsightsCertStatusGraph({ data }) {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const getLargestValue = () => {
    let largest = 0;

    data?.months.forEach((item) => {
      if (item.new > largest) {
        largest = item.new;
      }
      if (item.expiring > largest) {
        largest = item.expiring;
      }
    });
    return largest;
  };

  const getHeight = (baseHeight, value) => {
    return (value / getLargestValue() || 0.2) * baseHeight;
  };

  const handleVideo = () => {
    setIsVideoOpen(true);
  };

  return (
    <>
      <div className="panel-common gap-5 flex-grow">
        <div className="flex items-center justify-between self-stretch">
          <div className="flex items-center gap-3 self-stretch">
            <span className="text-secondary text-base font-bold">
              Certification Status
            </span>
            <span className="text-gray text-xs font-medium">
              Q{getCurrentQuarter()} {getCurrentYear()}
            </span>
          </div>

          <div className="flex items-center gap-1">
            {/* <Tooltip
              animation="duration-150"
              content="Video Tutorial"
              placement="bottom"
              trigger="hover"
            >
              <HiMiniPlayCircle
                className="btn-text-green size-5 cursor-pointer"
                onClick={handleVideo}
              />
            </Tooltip> */}
            <Tooltip
              animation="duration-150"
              content={
                <div className="flex flex-col gap-2">
                  <span>Discounted Offers</span>
                  <span className="font-normal text-gray-300">
                    The certification status widget let's you see the Quarterly
                    status of your Cyber Assurance Program organisations.
                    <br />
                    Newly Certified - Refers to organisations that have
                    certified against an SMB1001 certification. <br />
                    Expiring Certificates - Refers to organisations that have an
                    expiring SMB1001 certification.
                  </span>
                </div>
              }
              placement="bottom"
              trigger="click"
            >
              <HiQuestionMarkCircle className="text-icons size-5 cursor-pointer" />
            </Tooltip>
          </div>

          {/* <Link to={ROUTE.ORGANISATIONS}>
            <HiQuestionMarkCircle className="text-icons hover:text-primary size-4 cursor-pointer" />
          </Link> */}
        </div>

        <div className="flex flex-col gap-8 tablet:flex-row tablet:gap-5 ">
          <div className="flex flex-shrink-0 flex-col justify-between gap-2">
            <div className="flex flex-col justify-center self-stretch">
              <span className="text-xs font-medium">Newly Certified</span>
              <span className="text-primary text-3xl font-bold leading-none">
                {data?.months?.reduce((prev, next) => prev + next.new, 0)}
              </span>
            </div>

            {/* <div className="flex items-center gap-1 text-green-500">
              <RiArrowRightUpLine className="size-4" />
              <span className="whitespace-nowrap text-xs font-medium">
                {data?.uptick}% more than last month
              </span>
            </div> */}

            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-1">
                <span className="h-2.5 w-2.5 bg-green-500" />
                <span className="text-primary whitespace-nowrap text-xs font-medium">
                  Newly Certified (
                  {data?.months?.reduce((prev, next) => prev + next.new, 0)})
                </span>
              </div>

              <div className="flex items-center gap-1">
                <span className="h-2.5 w-2.5 bg-orange-500" />
                <span className="text-primary whitespace-nowrap text-xs font-medium">
                  Expiring Certificates (
                  {data?.months?.reduce(
                    (prev, next) => prev + next.expiring,
                    0,
                  )}
                  )
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-1 flex-col tablet:flex-row">
            {data?.months?.map((item, index) => (
              <div
                key={index}
                className="border-secondary mb-3 flex w-full flex-col items-center justify-between gap-6 self-stretch border-b px-1 tablet:border-b-0 tablet:border-l"
              >
                <span className="text-gray text-xs font-medium">
                  {getMonthCodesForQuarter(getCurrentQuarter())[index]}
                </span>
                <div className="flex h-24 items-end justify-center gap-2 self-stretch text-xs font-bold text-white">
                  <div
                    style={{ height: getHeight(96, item.new) }}
                    className="flex max-w-9 flex-1 flex-col items-center justify-end rounded-t-sm bg-green-500"
                  >
                    {item.new}
                  </div>

                  <div
                    style={{ height: getHeight(96, item.expiring) }}
                    className="flex max-w-9 flex-1 flex-col items-center justify-end rounded-t-sm bg-orange-500"
                  >
                    {item.expiring}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Modal
        popup
        size="5xl"
        show={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        theme={{ header: { popup: "border-b-0" } }}
      >
        <Modal.Header>
          <div className="flex items-center gap-3">
            <IoMdVideocam />
            <span className="text-secondary">Certification Status</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="w-full border border-gray-300"
            src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/DiscountedOffers.mp4"
            controls
            playsInline
          ></video>
        </Modal.Body>
      </Modal>
    </>
  );
}
