export const BreadcrumbTheme = {
  root: {
    base: "",
    list: "flex flex-wrap items-center",
  },
  item: {
    base: "group flex items-center",
    chevron: "mx-1 size-4 text-gray-400 group-first:hidden md:mx-2",
    href: {
      off: "flex items-center text-sm font-medium text-gray-500 dark:text-gray-400",
      on: "flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white",
    },
    icon: "mr-2 size-4",
  },
};
