export const DefaultTableTheme = {
  root: {
    base: "w-full min-w-[1024px] table-fixed text-left text-sm text-primary text-nowrap",
    shadow:
      "absolute left-0 top-0 -z-10 h-full w-full bg-white drop-shadow-md dark:bg-black",
    wrapper: "relative overflow-x-scroll border-x",
  },
  body: {
    base: "group/body",
    cell: {
      base: "bg-white px-6 py-4 text-wrap first:sticky first:left-0 first:z-5",
    },
  },
  head: {
    base: "group/head text-xs uppercase text-secondary border-b border-primary dark:border-gray-700",
    cell: {
      base: "surface-secondary border-t px-6 py-3 first:sticky first:left-0 first:z-5 dark:bg-gray-700",
    },
  },
  row: {
    base: "group/row border-b border-primary dark:border-gray-700",
    hovered: "hover:surface-db-stepper dark:hover:bg-gray-600",
    striped:
      "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700",
  },
};

export const AlternateTableTheme = {
  root: {
    base: "w-full text-left text-sm text-primary",
    shadow:
      "absolute left-0 top-0 -z-10 h-full w-full bg-white drop-shadow-md dark:bg-black",
    wrapper: "relative overflow-x-scroll border-x",
  },
  body: {
    base: "group/body",
    cell: {
      base: "bg-white px-6 py-4 text-wrap first:sticky first:left-0 first:z-5",
    },
  },
  head: {
    base: "group/head text-xs uppercase text-secondary border-b border-primary dark:border-gray-700",
    cell: {
      base: "surface-secondary border-t px-6 py-3 first:sticky first:left-0 first:z-5 dark:bg-gray-700",
    },
  },
  row: {
    base: "group/row border-b border-primary dark:border-gray-700",
    hovered: "hover:surface-db-stepper dark:hover:bg-gray-600",
    striped:
      "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700",
  },
};

export const AutoTableTheme = {
  root: {
    base: "w-full text-left text-sm text-primary text-nowrap",
  },
};
