import { useRef } from "react";

import {
  AvatarTheme,
  CyberCertBlackLogo,
  cybercertIcon,
  DropdownTheme,
  SearchInputTheme,
} from "assets";
import { Button, PlatformSwitcher, SearchInput } from "components";
import { FlowbiteButton } from "components/flowbite";
import { useDashboard } from "contexts";
import { Avatar, Dropdown, Tooltip } from "flowbite-react";
import { useVerifyPartnerAccount } from "hooks";
import { BiLogOut } from "react-icons/bi";
import { BsBellFill } from "react-icons/bs";
import {
  HiCog,
  HiOfficeBuilding,
  HiSearch,
  HiSelector,
  HiUserCircle,
  HiX,
} from "react-icons/hi";
import { MdClose } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthService } from "services";
import { useElementSize } from "utils";
import { ROUTES } from "variables";

export function Navbar({
  variant = "loggedout",
  primeLogo,
  primeLogoL,
  orgName,
  processTitle,
  assessmentId,
  assessmentToken,
  assessmentUser,
  eligibilityCheck,
}) {
  const navigate = useNavigate();

  const betaRef = useRef(null);

  const { dashboard } = useDashboard();
  const { height } = useElementSize(betaRef);
  const { state, pathname } = useLocation();

  const verifyPartnerState = useVerifyPartnerAccount();

  let firstName =
    verifyPartnerState?.data?.user?.firstName ||
    verifyPartnerState?.data?.partner?.firstName;
  let lastName =
    verifyPartnerState?.data?.user?.lastName ||
    verifyPartnerState?.data?.partner?.lastName;

  const handleGoBack = () => {
    navigate(-1); // Go back one step in the history
  };

  const handleCloseAssessmentSession = () => {
    console.log(assessmentId, assessmentToken);
    if (state?.preview) {
      navigate(ROUTES.VIEW_GAP.replace(":id", assessmentId));
    } else {
      if (
        pathname === ROUTES.ASSESSMENT_LINK.replace(":token", assessmentToken)
      ) {
        window.location.href = ROUTES.ASSESSMENT_LINK.replace(
          ":token",
          assessmentToken,
        );
      } else {
        navigate(ROUTES.ASSESSMENT_LINK.replace(":token", assessmentToken), {
          replace: true,
        });
      }
    }
  };

  const primeContent = (
    <>
      <div className="flex items-center gap-3">
        {!!primeLogoL ? (
          <img src={primeLogoL} alt="Large Prime Logo" className="h-7.5" />
        ) : (
          <>
            <div className="border-surface-primary m-0 flex aspect-square w-9 items-center justify-center overflow-hidden rounded-full border object-cover p-0">
              {!!primeLogo ? (
                <img
                  src={primeLogo}
                  alt="Prime Logo"
                  className="aspect-square w-full scale-110 overflow-hidden object-cover"
                />
              ) : (
                <HiOfficeBuilding className="text-gray size-7.5" />
              )}
            </div>
            <span className="text-primary hidden whitespace-nowrap text-xl font-semibold tablet:block">
              {orgName}
            </span>
          </>
        )}
      </div>

      <div className="flex w-full px-8">
        <SearchInput
          id="search"
          type="text"
          name="search"
          placeholder="Search for ABN, Organisation Name or Email"
          icon={HiSearch}
          required
          shadow
          theme={SearchInputTheme}
          color="gray"
        />
      </div>

      <div className="flex justify-end gap-3">
        <Tooltip content="View Notifications" placement="bottom">
          <FlowbiteButton variant="icon">
            <BsBellFill className="h-6 w-6" />
          </FlowbiteButton>
        </Tooltip>
        <Avatar
          placeholderInitials={
            !!firstName
              ? `${firstName[0]?.toUpperCase()}${lastName[0]?.toUpperCase()}`
              : ""
          }
          rounded
          theme={AvatarTheme}
        />
      </div>
    </>
  );

  const primeContentNoSearch = (
    <>
      <div className="flex items-center gap-3">
        {!!primeLogoL ? (
          <img src={primeLogoL} alt="Large Prime Logo" className="h-7.5" />
        ) : (
          <>
            <div className="border-surface-primary m-0 flex aspect-square w-9 items-center justify-center overflow-hidden rounded-full border object-cover p-0">
              {!!primeLogo ? (
                <img
                  src={primeLogo}
                  alt="Prime Logo"
                  className="aspect-square w-full scale-110 overflow-hidden object-cover"
                />
              ) : (
                <HiOfficeBuilding className="text-gray size-7.5" />
              )}
            </div>
            <span className="text-primary hidden whitespace-nowrap text-xl font-semibold tablet:block">
              {orgName}
            </span>
          </>
        )}
      </div>

      <div className="flex w-fit items-center justify-end gap-3">
        <PlatformSwitcher />

        <div className="flex flex-row items-center gap-4">
          <Dropdown
            label={
              <Avatar
                placeholderInitials={
                  !!firstName
                    ? `${firstName[0]?.toUpperCase()}${lastName[0]?.toUpperCase()}`
                    : ""
                }
                className="text-base"
                rounded
                theme={AvatarTheme}
              />
            }
            arrowIcon={false}
            inline
            theme={DropdownTheme}
          >
            <Dropdown.Header>
              <span className="block text-sm">
                {firstName} {lastName}
              </span>
              <span className="block truncate text-sm font-medium">
                {verifyPartnerState?.data?.user?.email}
              </span>
            </Dropdown.Header>

            <Dropdown.Item
              className="hover:btn-text flex flex-row items-center gap-2 text-sm font-medium leading-tight outline-none focus:underline"
              onClick={() => navigate(ROUTES.ADMIN_SETTINGS)}
            >
              <HiCog className="size-5" />
              Settings
            </Dropdown.Item>
            <Dropdown.Item
              className="hover:btn-text flex flex-row items-center gap-2 text-sm font-medium leading-tight outline-none focus:underline"
              onClick={() => AuthService.signOut()}
            >
              <BiLogOut className="size-5" />
              Sign Out
            </Dropdown.Item>
          </Dropdown>
        </div>
      </div>
    </>
  );

  const primeOnboardingContent = (
    <>
      <img src={CyberCertBlackLogo} alt="cybercert-black-logo" />

      <div className="flex items-center gap-2 tablet:gap-4">
        <Button
          customClasses={"hidden tablet:flex h-9"}
          leftIcon={<HiUserCircle className="size-5" />}
          label={verifyPartnerState?.data?.user?.email}
          labelStyles={"font-medium"}
          variant={"blue"}
          // onClick={}
        />

        <div className="flex tablet:hidden">
          <Dropdown
            label={
              <>
                <div
                  className="btn-text flex items-center gap-2 rounded-lg bg-blue-50 p-2 text-sm font-medium leading-tight outline-none
                 group-hover:bg-blue-200 group-focus:ring-4 group-focus:ring-blue-300"
                >
                  <HiUserCircle className="size-5" />
                </div>
              </>
            }
            arrowIcon={false}
            inline
            theme={DropdownTheme}
          >
            <Dropdown.Header>
              <span className="block text-sm">Email</span>
              <span className="block truncate text-sm font-medium">
                {verifyPartnerState?.data?.user?.email}
              </span>
            </Dropdown.Header>

            <Dropdown.Item
              className="hover:btn-text flex flex-row items-center gap-2 text-sm font-medium leading-tight outline-none focus:underline"
              onClick={() => navigate(ROUTES.ADMIN_SETTINGS)}
            >
              <HiCog className="size-5" />
              Settings
            </Dropdown.Item>
            <Dropdown.Item
              className="hover:btn-text flex flex-row items-center gap-2 text-sm font-medium leading-tight outline-none focus:underline"
              onClick={() => AuthService.signOut()}
            >
              <BiLogOut className="size-5" />
              Sign Out
            </Dropdown.Item>
          </Dropdown>
        </div>

        <button
          className="hover:btn-text hidden items-center gap-2 text-sm font-medium leading-tight outline-none focus:underline tablet:flex"
          onClick={() => AuthService.signOut()}
        >
          <BiLogOut className="size-5" />
          Sign Out
        </button>
      </div>
    </>
  );

  const primeProcess = (
    <div className="flex items-center gap-3">
      {/* add functionality to save current progress before exit */}
      <Tooltip content="Save & Exit" placement="bottom">
        <FlowbiteButton variant="icon" onClick={handleGoBack}>
          <MdClose className="size-5" />
        </FlowbiteButton>
      </Tooltip>
      <div className="text-icons text-base font-semibold">|</div>
      <span className="text-primary whitespace-nowrap text-lg font-bold">
        {processTitle}
      </span>
    </div>
  );

  const loggedOutContent = (
    <>
      <div className="flex items-center gap-3">
        <Avatar image={cybercertIcon} />
        <span className="text-primary text-base font-semibold">CyberCert</span>
        <FlowbiteButton variant="nav">
          <span className="font-bold">DEVELOPMENT</span>
          <HiSelector className="h-[23px] w-[15px]" />
        </FlowbiteButton>
      </div>
      <div className="flex items-center justify-end gap-3">
        <span className="text-primary text-sm font-semibold">Help</span>
      </div>
    </>
  );

  const mfaCheck = (
    <div className="mx-auto flex w-full flex-row items-center justify-between p-8 tablet:my-0 tablet:px-[80px] tablet:py-2.5 laptop:max-w-[1600px] laptop:px-[120px] laptop:py-[25px]">
      <img
        src={CyberCertBlackLogo}
        alt="cybercert-black-logo"
        width={"160px"}
      />
    </div>
  );

  const primeSelectDashboard = (
    <>
      <div className="flex items-center gap-3">
        {!!primeLogoL ? (
          <img src={primeLogoL} alt="Large Prime Logo" className="h-7.5" />
        ) : (
          <>
            {!!primeLogo ? (
              <>
                <div className="border-surface-primary m-0 flex aspect-square w-9 items-center justify-center overflow-hidden rounded-full border object-cover p-0">
                  {!!primeLogo ? (
                    <img
                      src={primeLogo}
                      alt="Prime Logo"
                      className="aspect-square w-full scale-110 overflow-hidden object-cover"
                    />
                  ) : (
                    <HiOfficeBuilding className="text-gray size-7.5" />
                  )}
                </div>
                <span className="text-primary hidden whitespace-nowrap text-xl font-semibold tablet:block">
                  {orgName}
                </span>
              </>
            ) : (
              <>
                <img src={CyberCertBlackLogo} alt="cybercert-black-logo" />
              </>
            )}
          </>
        )}
      </div>

      <Dropdown
        label={
          <Avatar
            placeholderInitials={
              !!firstName
                ? `${firstName[0]?.toUpperCase()}${lastName[0]?.toUpperCase()}`
                : ""
            }
            className="text-base"
            rounded
            theme={AvatarTheme}
          />
        }
        arrowIcon={false}
        inline
        theme={DropdownTheme}
      >
        <Dropdown.Header>
          <span className="block text-sm">
            {firstName} {lastName}
          </span>
          <span className="block truncate text-sm font-medium">
            {verifyPartnerState?.data?.user?.email}
          </span>
        </Dropdown.Header>

        {/* <Dropdown.Item
          className="hover:btn-text flex flex-row items-center gap-2 text-sm font-medium leading-tight outline-none focus:underline"
          onClick={() => navigate(ROUTES.ADMIN_SETTINGS)}
        >
          <HiCog className="size-5" />
          Settings
        </Dropdown.Item> */}
        <Dropdown.Item
          className="hover:btn-text flex flex-row items-center gap-2 text-sm font-medium leading-tight outline-none focus:underline"
          onClick={() => AuthService.signOut()}
        >
          <BiLogOut className="size-5" />
          Sign Out
        </Dropdown.Item>
      </Dropdown>
    </>
  );

  const assessmentNav = (
    <>
      <div className="flex items-center gap-3">
        {!!primeLogoL ? (
          <img src={primeLogoL} alt="Large Prime Logo" className="h-7.5" />
        ) : (
          <>
            <div className="border-surface-primary m-0 flex aspect-square w-9 items-center justify-center overflow-hidden rounded-full border object-cover p-0">
              {!!primeLogo ? (
                <img
                  src={primeLogo}
                  alt="Prime Logo"
                  className="aspect-square w-full scale-110 overflow-hidden object-cover"
                />
              ) : (
                <HiOfficeBuilding className="text-gray size-7.5" />
              )}
            </div>

            <span className="text-primary hidden whitespace-nowrap text-xl font-semibold tablet:block">
              {orgName}
            </span>
          </>
        )}
      </div>

      {/* {verified && // only show these if user has been verified */}

      {eligibilityCheck &&
        (state?.preview ? (
          <Button
            customClasses={"flex h-9"}
            leftIcon={<HiX className="text-icons size-5" />}
            label={"Close Preview"}
            labelStyles={"font-medium"}
            variant={"white"}
            onClick={handleCloseAssessmentSession}
          />
        ) : (
          <div className="flex items-center gap-2 tablet:gap-4">
            <Button
              customClasses={"hidden tablet:flex h-9"}
              leftIcon={<HiUserCircle className="size-5" />}
              label={assessmentUser}
              labelStyles={"font-medium"}
              variant={"blue"}
            />

            <div className="flex tablet:hidden">
              <Dropdown
                label={
                  <>
                    <div
                      className="btn-text flex items-center gap-2 rounded-lg bg-blue-50 p-2 text-sm font-medium leading-tight outline-none 
                  group-hover:bg-blue-200 group-focus:ring-4 group-focus:ring-blue-300"
                    >
                      <HiUserCircle className="size-5" />
                    </div>
                  </>
                }
                arrowIcon={false}
                inline
                theme={DropdownTheme}
              >
                <Dropdown.Header>
                  <span className="block truncate text-sm font-medium">
                    {assessmentUser}
                  </span>
                </Dropdown.Header>

                <Dropdown.Item
                  className="hover:btn-text flex flex-row items-center gap-2 text-sm font-medium leading-tight outline-none focus:underline"
                  onClick={handleCloseAssessmentSession}
                >
                  <HiX className="size-5" />
                  Close
                </Dropdown.Item>
              </Dropdown>
            </div>

            <Button
              customClasses={"flex h-9"}
              leftIcon={<HiX className="text-icons size-5" />}
              label={"Close"}
              labelStyles={"font-medium"}
              variant={"white"}
              onClick={handleCloseAssessmentSession}
            />
          </div>
        ))}
    </>
  );

  const mfaNavbarStyles =
    "border-b surface-nav mx-auto max-h-[95px] flex w-full flex-row items-center justify-between";

  const normalNavbarStyles =
    "border-secondary surface-nav flex min-h-16 items-center justify-between self-stretch border-b px-5 py-0";

  return (
    <nav className={`sticky z-50 flex flex-col`} style={{ top: -height }}>
      {/* {variant !== "mfaCheck" && (
        <div
          ref={betaRef}
          className="flex w-full flex-row items-center justify-center gap-2 bg-gray-900 p-3 text-white"
        >
          <span className="btn-bg btn-text rounded-lg px-2 py-1 text-xs font-semibold uppercase">
            BETA
          </span>
          <div className="flex flex-row items-center gap-1">
            <p>New release!</p>
            <p className="hidden tablet:block">
              Let us know how we can improve it.
            </p>
            <button
              className="hidden flex-row items-center gap-1 text-white hover:text-blue-300 tablet:flex"
              onClick={openZEWidget}
            >
              Send Feedback
              <HiArrowRight className="size-4" />
            </button>
            <button
              className="flex flex-row items-center gap-1 text-white hover:text-blue-300 tablet:hidden"
              onClick={openZEWidget}
            >
              Help us improve
              <HiArrowRight className="h-3 w-3" />
            </button>
          </div>
        </div>
      )} */}

      <div
        className={
          variant !== "mfaCheck" ? normalNavbarStyles : mfaNavbarStyles
        }
      >
        {
          {
            loggedout: loggedOutContent,
            mfaCheck: mfaCheck,
            prime: primeContent,
            primeNoSearch: primeContentNoSearch,
            primeOnboarding: primeOnboardingContent,
            primeProcess: primeProcess,
            primeSelectDashboard: primeSelectDashboard,
            renderAssessmentNav: assessmentNav,
          }[variant]
        }
      </div>
    </nav>
  );
}
