import { useState } from "react";

import { Footer } from "layouts";

import { ResultAssessment } from "../result";

const RESULTS = ["BRONZE", "SILVER", "GOLD", "PLATINUM", "DIAMOND"];

export function FinishAssessment({
  organisation,
  assessment,
  assessmentResult,
}) {
  const [viewResult, setViewResult] = useState(false);

  return (
    <>
      {viewResult ? (
        <ResultAssessment
          organisation={organisation}
          assessment={assessment}
          assessmentResult={assessmentResult}
        />
      ) : (
        <div className="flex flex-grow flex-col justify-between self-stretch">
          <div className="flex flex-col gap-8 p-8">
            {assessmentResult?.result > 0 ? (
              <>
                <div className="flex flex-col gap-2">
                  <div className="text-primary text-xl font-bold">
                    Your organisation may immediately qualify for a{" "}
                    {RESULTS[assessmentResult?.result - 1]} certification.
                  </div>
                  <span className="text-gray text-sm">
                    Congratulations! You have completed the SMB1001 GAP
                    assessment.
                  </span>
                  {/* <div className="flex h-24 w-24 justify-center rounded-full bg-green-50">
                    <span className="text-15">🎉</span>
                  </div> */}
                </div>
              </>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="text-primary text-xl font-bold">
                  Eligibility for Bronze Certification Not Met
                </div>
                <span className="text-gray text-sm">
                  Review your SMB1001 GAP assessment for details.
                </span>
              </div>
            )}

            {/* <Button
              customClasses={`w-fit laptop:flex`}
              label={"View results"}
              labelStyles={"font-semibold text-sm"}
              variant={"blue"}
              onClick={() => setViewResult(true)}
            /> */}
          </div>

          <ResultAssessment
            organisation={organisation}
            assessment={assessment}
            assessmentResult={assessmentResult}
          />

          <Footer />
        </div>
      )}
    </>
  );
}
