import { useEffect, useState } from "react";

import { SemiCircleSlider } from "components/slider";
import { useDashboard } from "contexts";
import { format } from "date-fns";
import { Modal, Tooltip } from "flowbite-react";
import { BsShieldFillCheck } from "react-icons/bs";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { IoMdVideocam } from "react-icons/io";

export function InsightsCapGraph({ data }) {
  const [certRatio, setCertRatio] = useState(0);
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const { dashboard } = useDashboard();

  const formattedDate = format(new Date(), "EEE dd MMM");

  const handleVideo = () => {
    setIsVideoOpen(true);
  };

  useEffect(() => {
    setCertRatio(data?.CAP?.certified / data?.CAP?.total || 0);
  }, [data]);

  return (
    <>
      <div className="panel-common gap-5">
        <div className="flex items-start justify-between self-stretch laptop:items-center">
          <div className="flex flex-col items-center gap-3 self-stretch laptop:flex-row laptop:items-center">
            <span className="text-secondary text-base font-bold">
              Our {dashboard?.orgTitle}
            </span>
            <span className="text-gray text-xs font-medium">
              {formattedDate}
            </span>
          </div>

          <div className="flex items-center gap-1">
            {/* <Tooltip
              animation="duration-150"
              content="Video Tutorial"
              placement="bottom"
              trigger="hover"
            >
              <HiMiniPlayCircle
                className="btn-text-green size-5 cursor-pointer"
                onClick={handleVideo}
              />
            </Tooltip> */}
            <Tooltip
              animation="duration-150"
              content={
                <div className="flex flex-col gap-2">
                  <span>Our {dashboard?.orgTitle}</span>
                  <span className="font-normal text-gray-300">
                    The Our {dashboard?.orgTitle} widget lets you see at a quick
                    glance the Certification status of all the organisations
                    listed in your Partner Dashboard.
                    <br />
                    Certified & Non-Certified refers to organisations that are
                    certified with one or more SMB1001 certifications.
                  </span>
                </div>
              }
              placement="bottom"
              trigger="click"
            >
              <HiQuestionMarkCircle className="text-icons size-5 cursor-pointer" />
            </Tooltip>
          </div>

          {/* <Link to={ROUTE.ORGANISATIONS}>
            <HiQuestionMarkCircle className="text-icons hover:text-primary size-4 cursor-pointer" />
          </Link> */}
        </div>

        <div className="flex flex-col gap-5 tablet:flex-row tablet:justify-between">
          <div className="flex flex-col justify-between gap-5 self-stretch">
            <div className="flex flex-col gap-2 self-stretch">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <div className="text-sm">
                    <span className="text-primary font-bold">
                      {data?.CAP?.certified}
                    </span>{" "}
                    <span className="text-gray font-medium">
                      / {data?.CAP?.total} {dashboard?.orgTitle}
                    </span>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="h-2.5 w-2.5 bg-green-500" />
                    <div className="text-gray text-xs font-medium">
                      Certified
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="text-sm">
                    <span className="text-primary font-bold">
                      {data?.CAP?.total - data?.CAP?.certified}{" "}
                    </span>
                    <span className="text-gray font-medium">
                      / {data?.CAP?.total} {dashboard?.orgTitle}
                    </span>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="h-2.5 w-2.5 bg-gray-200" />
                    <div className="text-gray text-xs font-medium">
                      Non-Certified
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <span className="text-gray text-xs font-medium">
              Total: {data?.CAP?.total} {dashboard?.orgTitle}
            </span>
          </div>

          <div className="flex justify-center pb-5">
            <div className="relative h-fit">
              <SemiCircleSlider value={certRatio * 100} className={"z-10"} />

              <div className="absolute bottom-[38px] left-[102px] z-20 flex h-10 w-10 items-center justify-center rounded-full bg-gray-100">
                <BsShieldFillCheck className="size-5 text-green-500" />
              </div>

              <div className="text-icons absolute bottom-0 left-0 z-30 translate-x-1/4 translate-y-full text-xs font-medium">
                0%
              </div>

              <div className="text-icons absolute bottom-0 right-0 z-30 translate-x-1/4 translate-y-full text-xs font-medium">
                100%
              </div>

              <div className="absolute -bottom-[15px] left-1/2 z-30 -translate-x-1/2 text-3xl font-bold leading-none text-green-500">
                {Math.round(certRatio * 100)}%
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        popup
        size="5xl"
        show={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        theme={{ header: { popup: "border-b-0" } }}
      >
        <Modal.Header>
          <div className="flex items-center gap-3">
            <IoMdVideocam />
            <span className="text-secondary">Our {dashboard?.orgTitle}</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <video
            className="w-full border border-gray-300"
            src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/DiscountedOffers.mp4"
            controls
            playsInline
          ></video>
        </Modal.Body>
      </Modal>
    </>
  );
}
