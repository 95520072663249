export const validateEmpty = (str) => {
  if (!!str) {
    return str?.length > 0;
  }
  return false;
};

export const validateABN = (str) => {
  const abnRegex = /^(?!(\d)\1{10})\d{11}$/;

  return abnRegex.test(str?.replace(/\s+/g, ""));
};

export const validateEmail = (str) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(str?.replace(/\s+/g, ""));
};

export const validateMobile = (str) => {
  const mobileRegex = /^04\d{8}$/;

  return (
    mobileRegex.test(str?.replace(/\s+/g, "")) &&
    str?.replace(/\s+/g, "")?.length === 10
  );
};

export const validatePhoneNumber = (str) => {
  if (!str) return false;

  // Remove spaces, dashes, and parentheses
  const cleanedStr = str.replace(/[\s\-()]+/g, "");

  // Regex to match various phone number formats
  const phoneRegex = /^(\+?\d{1,3})?\d{6,14}$/;

  return phoneRegex.test(cleanedStr);
};

export const validateCode = (str) => {
  const codeRegex = /^\d{6}$/;

  return codeRegex.test(str.replace(/\s/g, ""));
};
