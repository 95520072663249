import { useEffect, useState } from "react";

import { AlertTheme } from "assets";
import { Button } from "components";
import { Alert } from "flowbite-react";
import { Footer } from "layouts";
import { HiArrowLeft, HiCheck, HiExclamationCircle } from "react-icons/hi";
import { RiInformationLine } from "react-icons/ri";
import { scrollToTop } from "utils";

export function ReviewResponses({
  questions,
  error,
  disabledSubmit,
  handlePrevStep = () => {},
  handleSubmit = () => {},
}) {
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (error) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [error]);

  const handleFormSubmit = () => {
    if (disabledSubmit || error) {
      scrollToTop();
    }
    handleSubmit();
  };

  return (
    <>
      <div className="flex flex-grow flex-col justify-between self-stretch">
        <div className="flex flex-col gap-8 p-8">
          {showAlert && (
            <Alert
              color={"red"}
              onDismiss={() => setShowAlert(false)}
              icon={HiExclamationCircle}
              theme={AlertTheme}
              className="w-full"
            >
              <div className="alert-flowbite">
                <div className="text-base font-semibold">{error}</div>
              </div>
            </Alert>
          )}

          <div className="flex flex-col items-center justify-between laptop:flex-row">
            <div className="text-primary text-xl font-bold">Review</div>
            <span className="text-gray text-sm font-semibold">
              {questions?.flatMap((q) => q)?.length} total questions
            </span>
          </div>

          <div className="flex flex-col gap-4">
            {questions?.map((section) =>
              section?.map((question, index) => (
                <div key={index} className="flex flex-row justify-between">
                  <div className="text-base font-medium">{`${index + 1}.0 ${
                    question?.question
                  }`}</div>
                  <div>
                    <div className="inline-flex items-center">
                      {question?.value === "yes" ? (
                        <>
                          <HiCheck className="size-5 text-green-400" />

                          <span className="text-primary ml-2 text-base capitalize">
                            {question?.value}
                          </span>
                        </>
                      ) : question?.value === "no" ? (
                        <>
                          <RiInformationLine className="text-icons size-5" />

                          <span className="text-primary ml-2 text-base capitalize">
                            {question?.value}
                          </span>
                        </>
                      ) : question?.value === null ? (
                        "-"
                      ) : null}
                    </div>
                  </div>
                </div>
              )),
            )}
          </div>
        </div>

        <div className="flex flex-col gap-0">
          {/* {!!error && (
            <div className="flex flex-col gap-3 pl-8 text-red-500 laptop:w-[50%]">
              <p className="font-bold">{error}</p>
            </div>
          )} */}

          <ActionAssessmentPanel
            disabledSubmit={disabledSubmit}
            handleSubmit={handleFormSubmit}
            handlePrevStep={handlePrevStep}
          />
          <Footer />
        </div>
      </div>
    </>
  );
}

function ActionAssessmentPanel({
  disabledSubmit,
  handlePrevStep = () => {},
  handleSubmit = () => {},
}) {
  return (
    <>
      <div
        className={`border-primary flex w-full flex-col gap-2 border-b border-t p-8 laptop:flex-row ${disabledSubmit ? "surface-db-stepper" : "bg-green-50"}`}
      >
        <Button
          customClasses={`w-full laptop:w-fit laptop:flex`}
          leftIcon={<HiArrowLeft className="size-4" />}
          label={"Back"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={handlePrevStep}
        />

        <Button
          customClasses={`w-full laptop:w-fit laptop:flex`}
          leftIcon={<HiCheck className="size-4" />}
          label={"Submit"}
          labelStyles={"font-semibold text-sm"}
          variant={disabledSubmit ? "disabled" : "green"}
          onClick={handleSubmit}
          disabledSubmit={disabledSubmit}
        />
      </div>
    </>
  );
}
