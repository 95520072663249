import { useEffect, useState } from "react";

export const useElementSize = (elementRef) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    // Function to update width and height states from element dimensions
    const updateSize = () => {
      if (elementRef.current) {
        setWidth(elementRef.current.clientWidth);
        setHeight(elementRef.current.clientHeight);
      }
    };

    // Call the function initially to set the height
    updateSize();

    // Add event listener for window resize
    window.addEventListener("resize", updateSize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [elementRef]);

  return { width, height };
};
