import { AssessmentFooter, Stepper } from "components";

export function SidebarAssessmentProcess({
  assessmentResult = {},
  organisation = {},
  partner = {},
  steps = [],
  stepTitles = [],
  title = "",
}) {
  const processedSteps = Array.isArray(steps) ? steps : [];
  const processedStepTitles = Array.isArray(stepTitles) ? stepTitles : [];

  return (
    <div className="surface-nav border-border-primary flex min-w-[250px] flex-col justify-between border-b p-8 laptop:border-b-0 laptop:border-r">
      <Stepper
        title={title}
        steps={processedSteps}
        stepTitles={processedStepTitles}
      />

      <AssessmentFooter
        requestedBy={partner?.name}
        requestedTo={organisation?.name}
        requestedABN={organisation?.ABN}
        representative={assessmentResult?.completedBy}
        verifiedVia={assessmentResult?.verifiedVia}
        verifiedData={assessmentResult?.verifiedData}
        verifiedUser={!!organisation}
      />
    </div>
  );
}
