import { StepperIcon } from "./stepper-icon";

export function Stepper({ title = "", steps = [], stepTitles = [] }) {
  const getStepClass = (index) => {
    const isInProgress = steps[index] === "inprogress";
    const isLastStep = index === steps.length - 1;
    const isLastStepCompleted = isLastStep && steps[index] === "completed";

    if (isLastStepCompleted) {
      return "text-base font-bold text-primary";
    } else if (isInProgress) {
      return "text-base font-bold text-orange-500";
    } else {
      return "hidden laptop:block laptop:text-primary laptop:font-bold laptop:text-base";
    }
  };

  return (
    <div className="flex flex-col gap-3 self-stretch whitespace-nowrap">
      <span className="text-primary text-base font-semibold">{title}</span>

      <div className="flex flex-col gap-3 self-stretch laptop:flex-row">
        <div
          className={`
              surface-db-stepper rounded-4xl flex h-9 w-full flex-row items-center justify-between gap-3 self-stretch px-2 py-3 laptop:h-auto 
              laptop:w-fit laptop:flex-col laptop:justify-normal laptop:gap-4.5 laptop:px-1 laptop:py-3
            `}
        >
          {steps.map((step, index) => (
            <StepperIcon key={index} variant={step} animate />
          ))}
        </div>

        <div
          className={`
              flex flex-col gap-3 pt-3.5 text-center
              laptop:mx-0 laptop:gap-4.5 laptop:pt-3 laptop:text-left
            `}
        >
          {stepTitles.map((title, index) => (
            <div key={index} className={getStepClass(index)}>
              {title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
