import React from "react";
import { Modal } from "react-bootstrap";
export function SignAttestationLetterDocs() {
  return (
    <>
      <h2 className="text-2xl font-semibold">Sign the Attestation Letter</h2>
      <Modal.Body>
        <video
          className="w-full border border-gray-300"
          src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/resources/Cert+App+Clip+05.mp4"
          controls
          playsInline
        ></video>
      </Modal.Body>
      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-black">
          Now that the attestation letter has been generated and sent, check
          your inbox.
        </p>
        <p>
          It usually arrives within a few minutes. If the letter was sent to
          someone other than yourself, be sure to let them know to look out for
          it.
        </p>
        <p>
          Once the attestation letter is signed digitally, that will conclude
          the certification process.
        </p>
      </div>
    </>
  );
}
