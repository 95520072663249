import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import { Button, StatusLabel, StepSlider } from "components";
import { HiExternalLink } from "react-icons/hi";
import { Link } from "react-router-dom";
import { getAppPage } from "services";
import { handleSpaceKeyDownClick } from "utils";

const IMAGES = [IconBronze, IconSilver, IconGold, IconPlatinum, IconDiamond];

export function CertCard({
  id,
  title,
  standard,
  level,
  currentStep,
  totalSteps,
  showCertDetails,
  status,
}) {
  let isCertCompleted = currentStep >= totalSteps && status === "COMPLETED";

  return (
    <>
      <div className="border-primary flex w-[365px] flex-col gap-4 rounded-lg border p-5">
        <div className="flex justify-between self-stretch">
          <div className="flex flex-col gap-4 self-stretch">
            <div className="flex flex-col gap-2 self-stretch">
              <span className="text-primary text-lg font-bold uppercase">
                {title}
              </span>
              <span className="text-primary text-base font-normal">
                {standard}: Level {level}
              </span>
              <span className="text-gray text-sm font-medium leading-none">
                Certification
              </span>
            </div>

            {/* <div className="flex h-[21px] w-fit items-center justify-center overflow-hidden rounded text-xs font-medium leading-none">
              {currentStep === 0
                ? STATUSES["not-started"]
                : currentStep === totalSteps
                  ? STATUSES.completed
                  : STATUSES["in-progress"]}
            </div> */}

            <StatusLabel
              variant={
                isCertCompleted
                  ? "completed"
                  : currentStep === 1
                    ? "notstarted"
                    : "inprogress"
              }
              label={isCertCompleted ? "Completed" : "In progress"}
              animate
            />
          </div>

          <img
            src={IMAGES[level - 1]}
            alt={`${title} Icon`}
            className="h-16 w-16"
          />
        </div>

        <StepSlider
          currentStep={Math.min(
            isCertCompleted ? currentStep : currentStep - 1,
            totalSteps,
          )}
          totalSteps={totalSteps}
        />

        <div className="flex flex-row items-center justify-between gap-4">
          <Button
            variant="white"
            label="View Details"
            customClasses={"w-fit"}
            onClick={showCertDetails}
          />
          {isCertCompleted ? (
            <Link
              to={`${getAppPage()}/issued/${id}`}
              target="_blank"
              rel="noreferrer"
              className="text-primary hover:text-link flex flex-row items-center gap-1 rounded-lg px-1 
                        focus:outline-none focus:ring-4 focus:ring-blue-300 focus:ring-offset-4"
              onKeyDown={handleSpaceKeyDownClick}
            >
              Verify
              <HiExternalLink className="size-5" />
            </Link>
          ) : (
            <div className="text-primary flex cursor-not-allowed flex-row items-center gap-1 px-1 text-opacity-30">
              Verify
              <HiExternalLink className="size-5" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
