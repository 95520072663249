export const filterPasteBase = (event, paste) => {
  // Prevent default paste behavior
  event.preventDefault();

  // Insert the filtered text at the cursor position in the state-controlled input
  const start = event.target.selectionStart;
  const end = event.target.selectionEnd;
  const oldValue = event.target.value;
  const newValue = oldValue.slice(0, start) + paste + oldValue.slice(end);

  // Update the input with the new value
  event.target.value = newValue;

  // If you want to adjust the cursor position, you can add this optional part:
  setTimeout(() => {
    // Number type inputs don't have access to selection range so this will throw error if used
    if (event.target.type !== "number") {
      event.target.setSelectionRange(end + paste.length, end + paste.length);
    }
  }, 0);
};

export const filterNumberPaste = (event) => {
  event.preventDefault();
  const pastedData = event.clipboardData || window.clipboardData;
  let pastedText = pastedData.getData("text");
  pastedText = pastedText.replace(/\s/g, "");
  if (/^[0-9]+$/.test(pastedText)) {
    const input = event.target;
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    const originalValue = input.value;
    input.value =
      originalValue.substring(0, startPos) +
      pastedText +
      originalValue.substring(endPos);
    input.setSelectionRange(
      startPos + pastedText.length,
      startPos + pastedText.length,
    );
  }
};

export const filterAlphaKeyPress = (event) => {
  if (!/[a-zA-Z\s]/.test(event.key)) {
    event.preventDefault();
  }
};

export const filterAlphaPaste = (event) => {
  const value = event.clipboardData.getData("Text");
  if (!/^[A-Za-z\s]+$/.test(value)) {
    event.preventDefault();
  }
};

export const filterPhoneNoKeyPress = (event) => {
  if (!/[+0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const filterPhoneNoPaste = (event) => {
  const value = event.clipboardData.getData("Text");
  if (!/^[+0-9]+$/.test(value)) {
    event.preventDefault();
  }
};

export const filterNumberKeyPress = (event) => {
  if (!/[0-9]/.test(event.key) && !(event.metaKey || event.ctrlKey)) {
    event.preventDefault();
  }
};

export const filterNumberAndSpaceKeyPress = (event) => {
  if (
    !/[0-9\s]/.test(event.key) &&
    !event.metaKey &&
    !event.ctrlKey &&
    !event.altKey &&
    event.key !== "Backspace" &&
    event.key !== "Delete"
  ) {
    event.preventDefault();
  }
};

export const handleEnterKeyDownSubmission = (event, callback = () => {}) => {
  if (
    event?.key === "Enter" &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    callback();
  }
};

export const handleSpaceKeyDownClick = (event) => {
  if (
    event?.key === " " &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    event?.target?.click();
  }
};

export const handleCheckboxSubmission = (event) => {
  if (
    (event?.key === "Enter" || event?.key === " ") &&
    !(event?.metaKey || event?.ctrlKey || event?.altKey)
  ) {
    event.preventDefault();
    event?.target?.parentElement?.click();
  }
};
