import { Button } from "components";
import { HiDownload } from "react-icons/hi";

export function ResourceCard({
  title,
  description,
  img,
  children,
  classNameImg,
  onClick,
  classNamePanel,
}) {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <span className="text-base font-bold">{title}</span>
        <p className="text-gray">{description}</p>
      </div>

      <div
        className={`surface-50 relative flex h-[290px] items-center justify-center gap-8 rounded-lg p-8 ${classNamePanel}`}
      >
        {img && (
          <img
            src={img}
            alt={title}
            className={`${classNameImg} pointer-events-none overflow-hidden`}
          />
        )}
        {children ? (
          <div>{children}</div>
        ) : (
          <Button
            variant="white"
            label="Download"
            customClasses="w-fit absolute bottom-4 left-4"
            rightIcon={<HiDownload className="text-icons h-5 w-5" />}
            onClick={onClick}
          />
        )}
      </div>
    </div>
  );
}
