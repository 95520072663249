import { useState } from "react";

import { AlertTheme } from "assets";
import { Button } from "components";
import { FeatureList, FeatureListItem } from "components/list/feature-list";
import { FinishFooter } from "components/tables/finish-footer";
import { useDashboard } from "contexts";
import { Alert } from "flowbite-react";
import { HiArrowRight, HiCheckCircle, HiOfficeBuilding } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "variables";

export function FinishAssessmentSetup() {
  const { dashboard } = useDashboard();

  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(true);

  const handleDismiss = () => {
    setShowAlert(false);
  };

  return (
    <>
      <div className="flex h-full w-full flex-col justify-between gap-8 overflow-auto">
        <div className="flex w-full flex-col gap-8 p-8">
          {showAlert && (
            <Alert
              color={"green"}
              onDismiss={handleDismiss}
              icon={HiCheckCircle}
              theme={AlertTheme}
            >
              <div className="alert-flowbite flex flex-row items-center gap-3">
                <div className="text-base font-semibold">
                  You have successfully confirmed the distribution of this
                  assessment to all of your {dashboard?.orgPlural}.
                </div>
              </div>
            </Alert>
          )}

          <div className="text-primary text-lg font-bold">Next Steps</div>

          <div className="flex flex-col gap-5">
            <FeatureList>
              <FeatureListItem
                content={
                  <>
                    <span>
                      <span className="font-semibold">
                        Your SMB1001 GAP Assessment
                      </span>{" "}
                      has been generated and we are waiting on results.
                    </span>
                  </>
                }
              />
            </FeatureList>
          </div>
        </div>
        <FinishFooter
          description={`Your next step is waiting on your ${dashboard?.orgPlural}’ gap results.`}
        >
          <div className="mt-3 flex w-fit flex-row items-center gap-12">
            <Button
              leftIcon={<HiArrowRight className="text-icons size-4" />}
              label={"Continue to Assessments"}
              variant={"white"}
              onClick={() => (window.location.href = ROUTES.GAP)}
              labelStyles={"font-semibold text-sm"}
            />
            <Button
              leftIcon={<HiOfficeBuilding className="text-icons size-4" />}
              label={`View ${dashboard?.orgTitle}`}
              variant={"white"}
              onClick={() => navigate(ROUTES.ORGANISATIONS)}
              labelStyles={"font-semibold text-sm"}
            />
          </div>
        </FinishFooter>
      </div>
    </>
  );
}
