import { useEffect, useRef, useState } from "react";

import { Button, LoadingSpinner } from "components";
import { Spinner } from "flowbite-react";
import { Footer, LandingFooter, Navbar } from "layouts";
import { MFASetupOnboarding } from "pages/onboarding";
import { useLocation } from "react-router-dom";
import { AuthService } from "services";
import { ROUTES } from "variables";

const NUM_DIGITS = 6;

export function AuthMfaPage() {
  const { state } = useLocation();

  const inputRefs = useRef([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isFirstTry, setIsFirstTry] = useState(true);
  const [err, setErr] = useState(null);
  const [code, setCode] = useState(["", "", "", "", "", ""]);

  const setup = async () => {
    if (!state?.email || !state?.password) {
      window.location.href = ROUTES.ROOT;
    }
    setIsLoading(false);
  };

  const handleChangeCode = (value) => {
    setErr(null);
    setCode(value);
  };

  const handleInput = (index, event) => {
    const value = event.target.value;
    if (!isNaN(value) && value !== "") {
      // Add digit to code
      const newCode = [...code];
      newCode[index] = value.slice(-1);
      handleChangeCode(newCode);

      // Move focus to the next input
      if (index < code.length - 1) {
        inputRefs.current[index + 1].select();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    // Enter key should submit
    if (
      event?.key === "Enter" &&
      !(event?.metaKey || event?.ctrlKey || event?.altKey)
    ) {
      verifyMfaCode(code);
    }

    // Backspace and Delete keys
    if (event.keyCode === 8 || event.keyCode === 46) {
      // If backspace key is pressed and the current input box is empty, move selection to the previous input
      if (event.keyCode === 8 && index > 0 && code[index] === "") {
        inputRefs.current[index - 1].select();
      }

      // If backspace or delete is pressed and the current input box is not empty, delete the digit
      else if (
        (event.keyCode === 8 || event.keyCode === 46) &&
        index >= 0 &&
        code[index] !== ""
      ) {
        const newCode = [...code];
        newCode[index] = "";
        // Shift remaining digits to the left
        for (let i = index; i < newCode.length - 1; i++) {
          newCode[i] = newCode[i + 1];
        }
        newCode[newCode.length - 1] = "";
        handleChangeCode(newCode);

        if (index > 0 && event.keyCode === 8) {
          inputRefs.current[index - 1].focus();
        }
      }
    }

    // Left and right arrow keys
    if (event.keyCode === 37) {
      // Left arrow key pressed, move focus to the previous input box
      event.preventDefault();
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else if (event.keyCode === 39) {
      // Right arrow key pressed, move focus to the next input box
      event.preventDefault();
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }

    // Stop CTRL+Z
    if (event.ctrlKey || event.metaKey) {
      // Check if Ctrl (or Command on Mac) is pressed
      if (event.key === "z") {
        // Check if Z key is pressed
        event.preventDefault(); // Prevent the default undo behavior
        // Do nothing
      }
    }
  };

  const handlePaste = (index, event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain");

    // Must be only numbers
    if (!/[0-9\s]/.test(pasteData)) return;

    const numSpaces = 6 - index;
    const codeArray = pasteData
      .replace(/\s/g, "")
      .split("")
      .filter((char) => !isNaN(char))
      .slice(0, numSpaces);
    const newCode = [...code];
    codeArray.forEach((char, i) => {
      newCode[i + index] = char;
    });
    inputRefs?.current?.[codeArray?.length + index - 1]?.select();
    handleChangeCode(newCode);
  };

  const handleSelect = (event) => {
    event.preventDefault();
    event?.target?.setSelectionRange(
      event?.target?.value?.length,
      event?.target?.value?.length,
    );
  };

  const verifyMfaCode = async () => {
    setIsLoading(true);
    setIsFirstTry(false);

    if (code.some((digit) => !digit)) {
      setErr({
        type: "code",
        msg: "Please enter verification code",
      });
      setIsLoading(false);
      return;
    }

    await AuthService.verifyMFA(
      state.email,
      state.password,
      code.join(""),
      () => (window.location.href = ROUTES.ROOT),
      () => { },
      () => {
        setErr({
          type: "code",
          msg: "Verification code is incorrect.",
        });
        setIsLoading(false);
      },
    );
  };

  useEffect(() => {
    // Focus on the first input element when the component mounts
    inputRefs?.current[0]?.focus();
  }, [isLoading]);

  useEffect(() => {
    if (
      code?.length === NUM_DIGITS &&
      code.every((digit) => !!digit) &&
      isFirstTry
    )
      verifyMfaCode();
  }, [code]);

  useEffect(() => {
    setup();
  }, []);

  return (
    <>
      <div className="flex h-screen flex-col justify-between">
        <Navbar variant="mfaCheck" />

        <div className="flex h-full w-full flex-col items-center justify-center p-8">
          {!!isLoading ? (
            <LoadingSpinner />
          ) : (
            <div className="w-responsive flex flex-col gap-4 p-8 laptop:flex-row laptop:gap-[72px] desktop:-mt-[55px]">
              <div className="flex w-full flex-col gap-8">
                <span className="text-4xl font-semibold text-black">
                  Verify your account
                </span>

                <span className="flex text-sm font-normal text-gray-600 tablet:w-[80%]">
                  CyberCert requires all users to secure their accounts with
                  MFA. Please verify your account to proceed.
                </span>
              </div>

              <div className="flex w-full flex-col gap-4 self-stretch">
                <div className="flex w-full flex-col gap-3.5 self-stretch">
                  <div className="flex flex-row desktop:translate-x-9">
                    {!!err && (
                      <span className="body-sm font-medium text-red-600">
                        {err.msg}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-row desktop:translate-x-9">
                    <span className="text-sm font-normal text-gray-600">
                      Please input code for verification
                    </span>
                  </div>

                  <div className="flex justify-end gap-2 self-stretch tablet:gap-4">
                    {code.map((digit, index) => (
                      <input
                        key={index}
                        disabled={isLoading}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        value={digit}
                        className="h-[70px] w-full appearance-none rounded-lg border-none p-3 text-center text-2xl font-medium caret-transparent outline outline-1 outline-gray-300 selection:bg-transparent focus:outline-gray-900 focus:ring-0 disabled:bg-gray-100 desktop:w-[12%]"
                        onChange={(event) => handleInput(index, event)}
                        onKeyDown={(event) => handleKeyDown(index, event)}
                        onPaste={(event) => handlePaste(index, event)}
                        onSelect={handleSelect}
                      />
                    ))}
                  </div>
                </div>

                <div className="flex flex-row justify-end">
                  <Button
                    disabled={isLoading}
                    customClasses={`w-full tablet:w-fit py-1.5 px-7 bg-gray-900 text-white focus:ring-4 focus:ring-blue-300`}
                    label={"Verify"}
                    labelStyles={"text-base font-semibold"}
                    leftIcon={isLoading && <Spinner />}
                    onClick={verifyMfaCode}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
}

export function AuthMfaSetupPage() {
  return (
    <>
      <div className="flex h-screen flex-col justify-between">
        <Navbar variant="mfaCheck" />
        <div className="flex h-full w-full flex-col items-center justify-center p-8">
          <div className="surface-white border-primary flex w-full flex-col gap-0 rounded-lg border p-8 shadow tablet:flex-row tablet:gap-8 laptop:max-w-[1024px]">
            <div className="flex w-full flex-col justify-between gap-2 tablet:gap-0">
              <div className="flex flex-col gap-8 laptop:gap-8">
                <div className="text-[38px] font-semibold leading-none text-black">
                  MFA is required
                </div>

                <p className="flex w-full text-sm leading-normal text-gray-600 tablet:w-[90%] laptop:w-[95%]">
                  CyberCert requires all users to secure their accounts with
                  MFA. Please setup MFA in your account to continue.
                </p>
              </div>
            </div>

            <div className="flex w-full flex-col gap-5">
              <MFASetupOnboarding
                handleNextStep={() => {
                  window.location.href = ROUTES.ROOT;
                }}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
