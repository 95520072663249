import { OrganisationDetailsAssessmentsTable } from "components";

export function OrganisationDetailsGap({ assessmentDetails }) {
  return (
    <>
      <span className="text-secondary text-lg font-bold">GAP Assessment</span>

      <OrganisationDetailsAssessmentsTable data={assessmentDetails} />
    </>
  );
}
