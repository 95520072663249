import { TextInput } from "components/input";
import { formatPrice } from "utils";
import { CERT_LVL_BADGES, CERT_LVLS, CERT_LVLS_PRICES } from "variables";

export function BulkPurchaseTable({
  bulkPurchase = {},
  isLoading = false,
  handleInput = () => {},
}) {
  return (
    <div className="border-primary flex self-stretch border text-xs font-bold">
      <div className="flex flex-1 flex-col">
        <div className="surface-db-stepper text-gray border-primary flex items-center border-b p-4">
          LEVEL
        </div>
        {Object.keys(CERT_LVLS).map((level) => (
          <div
            key={level}
            className="border-primary flex h-[55px] items-center gap-4 border-b px-4 py-2 uppercase"
          >
            <img
              className="h-7"
              src={CERT_LVL_BADGES[level]}
              alt={`${CERT_LVLS[level]} Badge`}
            />
            {CERT_LVLS[level]}
          </div>
        ))}
      </div>

      <div className="flex flex-1 flex-col">
        <div className="surface-db-stepper text-gray border-primary flex flex-row gap-1 items-center border-b p-4">
          <span className="hidden tablet:flex">PURCHASE</span> QTY
        </div>
        {Object.keys(CERT_LVLS).map((level) => (
          <div
            key={level}
            className="border-primary flex h-[55px] items-center border-b px-4 py-2 uppercase"
          >
            <TextInput
              disabled={isLoading}
              name={`qty${CERT_LVLS[level]}`}
              sizing="xs"
              type="tel"
              value={bulkPurchase[`qty${CERT_LVLS[level]}`]}
              onInput={level <= 3 ? handleInput : () => {}}
            />
          </div>
        ))}
      </div>

      <div className="flex flex-1 flex-col">
        <div className="surface-db-stepper text-gray border-primary flex items-center border-b p-4">
          PRICE
        </div>
        {Object.keys(CERT_LVLS).map((level) => (
          <div
            key={level}
            className="border-primary flex h-[55px] items-center border-b px-4 py-2 text-xs font-normal"
          >
            {formatPrice(CERT_LVLS_PRICES[level])}
          </div>
        ))}
      </div>
    </div>
  );
}
