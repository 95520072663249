import React from "react";
import { Modal } from "react-bootstrap";

export function CompletingCertificationDocs() {
  return (
    <>
      <h2 className="text-2xl font-semibold">Completing your Certification</h2>
      <Modal.Body>
        <video
          className="w-full border border-gray-300"
          src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/resources/Cert+App+Clip+06.mp4"
          controls
          playsInline
        ></video>
      </Modal.Body>
      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-black">
          Congratulations, now that the attestation letter is signed your
          organisation is now officially cyber certified!
        </p>
        <p>
          You can view the signed attestation letter and your new certification
          directly from the dashboard.
        </p>
        <p>You can also generate a Credly badge for sharing on social media.</p>
        <p>
          Congratulations, you're now a CyberCert-certified organisation! Stay
          secure and share your certification with pride.
        </p>
      </div>
    </>
  );
}
