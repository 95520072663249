import { API } from "aws-amplify";

export const UploadPartnerOrgsData = async ({ dashboard, data, type }) => {
  let result;

  try {
    let myInit = {
      body: {
        // prev,
        data: {
          dashboard,
          data,
          type,
        },
        op: "upload",
      },
    };

    result = await API.post("partner", "/organisationOp", myInit);
    // console.log(result);
  } catch (e) {
    console.error(e);
  }

  return result;
};
