import { useState } from "react";

import { FeatureList, FeatureListItem } from "components";
import { LinkPanel } from "components/link-panel";
import { ConfirmFooter } from "components/tables/confirm-footer";
import { useDashboard } from "contexts";
import { Alert } from "flowbite-react";
import { HiCheckCircle, HiExclamationCircle } from "react-icons/hi";
import { ROUTES } from "variables";

const LEVELS = [null, "BRONZE", "SILVER", "GOLD", "PLATINUM", "DIAMOND"];

export function DistributeLink({
  handleNextStep,
  generatedAssessment,
  progress,
}) {
  const { dashboard } = useDashboard();

  const [infoMsg, setInfoMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  return (
    <>
      <div className="flex h-full w-full flex-col justify-between">
        <div className="flex flex-col gap-8 p-8">
          {!!infoMsg && (
            <Alert
              color={"green"}
              onDismiss={() => setInfoMsg("")}
              icon={HiCheckCircle}
            >
              <span className="text-base font-semibold">{infoMsg}</span>
            </Alert>
          )}

          {!!errMsg && (
            <Alert
              color={"red"}
              onDismiss={() => setErrMsg("")}
              icon={HiExclamationCircle}
            >
              <span className="text-base font-semibold">{errMsg}</span>
            </Alert>
          )}

          <div className="text-primary w-fit text-lg font-bold">
            <span>Assessment Link</span>
          </div>

          <div className="flex flex-grow flex-col justify-between gap-8 self-stretch text-sm font-medium laptop:flex-row">
            <div className="flex w-full flex-col gap-5">
              Share your {LEVELS[generatedAssessment?.maximumLevel]} and below,
              SMB1001 GAP assessment link with your {dashboard?.orgPlural} to
              collect the necessary information.
              {/* and promptly categorise them into their respective tiers. */}
              <FeatureList>
                <FeatureListItem
                  content={
                    <>
                      <span>
                        Distribute through internal communications or standard
                        channels for all of your{" "}
                        {dashboard?.orgTitle?.toLowerCase()} to complete.
                      </span>
                    </>
                  }
                />
              </FeatureList>
            </div>

            <LinkPanel
              title={"Your Assessment Link"}
              description={`Copy your link and distribute to all of your ${dashboard?.orgTitle?.toLowerCase()} to complete.`}
              link={ROUTES.ASSESSMENT_LINK.replace(":token", "")}
              token={generatedAssessment?.token}
              id={generatedAssessment?.id}
              progress={progress}
              handleInfoAlert={setInfoMsg}
              handleErrAlert={setErrMsg}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <ConfirmFooter handleNextStep={handleNextStep} />
        </div>
      </div>
    </>
  );
}
