import { useState } from "react";

import { AlertTheme } from "assets";
import { Button } from "components";
import { FeatureList, FeatureListItem } from "components/list/feature-list";
import { FinishFooter } from "components/tables/finish-footer";
import { useDashboard } from "contexts";
import { Alert } from "flowbite-react";
import { Footer } from "layouts";
import { HiArrowRight, HiCheckCircle, HiOfficeBuilding } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "variables";

export function FinishAddOrganisations({ handleFinish = () => {} }) {
  const navigate = useNavigate();
  const { dashboard } = useDashboard();

  const [showAlert, setShowAlert] = useState(true);

  const handleDismiss = () => {
    setShowAlert(false);
  };

  return (
    <>
      <div className="flex w-full flex-col justify-between gap-8 overflow-auto p-8">
        <div className="flex w-full flex-col gap-8">
          {showAlert && (
            <Alert
              color={"green"}
              onDismiss={handleDismiss}
              icon={HiCheckCircle}
              theme={AlertTheme}
            >
              <div className="alert-flowbite flex flex-row items-center gap-3">
                <div className="text-base font-semibold">
                  Your {dashboard?.orgPlural} are officially confirmed and
                  linked to your organisation.
                </div>
              </div>
            </Alert>
          )}

          <div className="text-primary text-lg font-bold">Next Steps</div>

          <div className="flex flex-col gap-5">
            <FeatureList>
              <FeatureListItem
                content={
                  <>
                    <span>
                      <span className="font-semibold">
                        Continue by assessing your {dashboard?.orgPlural}
                      </span>{" "}
                      using a GAP assessment.
                    </span>
                  </>
                }
              />
            </FeatureList>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-0">
        <FinishFooter
          description={`Your next step is to conduct an assessment of your ${dashboard?.orgPlural}.`}
        >
          <div className="mt-3 flex w-fit flex-row items-center gap-3">
            <Button
              leftIcon={<HiArrowRight className="text-icons size-4" />}
              label={"Continue to Assessments"}
              variant={"white"}
              onClick={() => {
                handleFinish();
                navigate(ROUTES.ASSESSMENTS);
              }}
            />
            <Button
              leftIcon={<HiOfficeBuilding className="text-icons size-4" />}
              label={`View ${dashboard?.orgTitle}`}
              variant={"white"}
              onClick={() => {
                handleFinish();
                navigate(ROUTES.ORGANISATIONS);
              }}
            />
          </div>
        </FinishFooter>
        <Footer />
      </div>
    </>
  );
}
