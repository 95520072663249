import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import { StatusLabel } from "components";
import { parseDateShort } from "utils";

import { getLandingPage } from "./environments";
import { isNonNegativeNumber } from "./numbers";

export const setupDashboardData = ({
  setPartner,
  setData,
  setShowOnboardingAlert = () => {},
  verifyPartnerState,
  fetchSuppliersState,
}) => {
  const verify = verifyPartnerState?.data;

  setPartner(verify?.partner);

  if (verify?.partner?.onboardStep === "DONE") {
    setShowOnboardingAlert(true);
  }

  let suppliersData = fetchSuppliersState?.data;

  let allCustomers = suppliersData?.partner?.customers;

  let certifiedCustomers = allCustomers?.filter((customer) =>
    customer?.certifications?.some(
      (certification) => certification?.progress?.status === "COMPLETED"
    )
  );
  let certified = certifiedCustomers?.length || 0;
  let total = allCustomers?.length || 0;

  // Processing new and expiring certs for current quarter
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const startMonth = Math.floor(currentMonth / 3) * 3;
  const endMonth = startMonth + 2;

  let months = [
    {
      new: 0,
      expiring: 0,
    },
    {
      new: 0,
      expiring: 0,
    },
    {
      new: 0,
      expiring: 0,
    },
  ];
  certifiedCustomers?.forEach((customer) => {
    // Get the latest completed cert
    const latestCert = customer?.certifications
      ?.filter((cert) => cert?.progress?.status === "COMPLETED")
      ?.sort(
        (a, b) =>
          new Date(b?.progress?.completedDate) -
          new Date(a.progress?.completedDate)
      )?.[0];

    // Check if the completedDate falls within the current quarter of the current year
    const completedDate = new Date(latestCert?.progress?.completedDate);
    const completedYear = completedDate?.getFullYear();
    const completedMonth = completedDate?.getMonth();
    if (
      completedYear === currentYear &&
      completedMonth >= startMonth &&
      completedMonth <= endMonth
    ) {
      months[completedMonth % 3].new++;
    }

    // Check if the expiredDate falls within the current quarter of the current year
    const expiredDate = new Date(latestCert?.progress?.expiredDate);
    const expiredYear = expiredDate?.getFullYear();
    const expiredMonth = expiredDate?.getMonth();
    if (
      expiredYear === currentYear &&
      expiredMonth >= startMonth &&
      expiredMonth <= endMonth
    ) {
      months[expiredMonth % 3].expiring++;
    }
  });

  let tierInvites = [
    suppliersData?.invites?.filter((invite) => invite?.level === 1),
    suppliersData?.invites?.filter((invite) => invite?.level === 2),
    suppliersData?.invites?.filter((invite) => invite?.level === 3),
    suppliersData?.invites?.filter((invite) => invite?.level === 4),
    suppliersData?.invites?.filter((invite) => invite?.level === 5),
  ];
  let credits = [
    {
      name: "Bronze",
      available: 0,
      total: 0,
      image: IconBronze,
      alt: "IconBronze",
    },
    {
      name: "Silver",
      available: 0,
      total: 0,
      image: IconSilver,
      alt: "IconSilver",
    },
    {
      name: "Gold",
      available: 0,
      total: 0,
      image: IconGold,
      alt: "IconGold",
    },
    {
      name: "Platinum",
      available: 0,
      total: 0,
      image: IconPlatinum,
      alt: "IconPlatinum",
    },
    {
      name: "Diamond",
      available: 0,
      total: 0,
      image: IconDiamond,
      alt: "iconDiamond",
    },
  ];
  credits = credits.map((cur, i) => ({
    ...cur,
    available: tierInvites[i]?.reduce((acc, cur) => acc + cur?.tally, 0),
    total: tierInvites[i]?.reduce((acc, cur) => acc + cur?.totalTally, 0),
    link: !!suppliersData?.invites?.filter(
      (invite) => invite?.level === i + 1
    )[0]?.code
      ? `${getLandingPage()}/invite/${suppliersData?.invites?.filter((invite) => invite?.level === i + 1)[0]?.code}`
      : null,
  }));

  let suppliers = allCustomers
    ?.map((customer) => {
      return {
        ...customer,
        name: customer?.name,
        ...getHighestCertificationLevel(customer),
        assessmentResults: (customer?.assessmentResults || []).sort((a, b) => {
          const dateA = new Date(a.updatedAt);
          const dateB = new Date(b.updatedAt);
          return dateB - dateA;
        }),
      };
    })
    ?.filter(Boolean)
    ?.sort((a, b) => {
      var nameA = a?.name?.toUpperCase();
      var nameB = b?.name?.toUpperCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

  let discount = {
    used: suppliersData?.partnerOfferAccepts?.length || 0,
    total: suppliersData?.partner?.customers?.length || 0,
    discount: !!suppliersData?.partnerOffer?.discount
      ? `${suppliersData?.partnerOffer?.discount}%` || "0%"
      : null,
    status: suppliersData?.partnerOffer?.isActive ? "active" : "inactive ",
    expiry: parseDateShort(suppliersData?.partnerOffer?.expiry),
    code: suppliersData?.partnerOffer?.id,
  };

  let customerSubscriptions = suppliersData?.partner?.customers
    ?.map((customer) => {
      let recommendedLevel = customer?.assessmentResults?.[0]?.result;
      let certificationLevel = customer?.certificationLevel;

      if (isNonNegativeNumber(recommendedLevel)) {
        return {
          certified:
            certificationLevel > 0 && certificationLevel >= recommendedLevel,
          recommendedLevel: recommendedLevel,
          certificationLevel,
        };
      } else if (certificationLevel > 0) {
        return {
          certified: true,
          recommendedLevel: 0,
          certificationLevel,
        };
      }
      return null;
    })
    .filter(Boolean);

  let subscriptions = [
    {
      name: "Bronze",
      issued: customerSubscriptions?.filter((s) => s?.certificationLevel === 1)
        .length,
      required: customerSubscriptions?.filter(
        (s) => s?.recommendedLevel === 1 && s?.certified === false
      )?.length,
      color: "",
    },
    {
      name: "Silver",
      issued: customerSubscriptions?.filter((s) => s?.certificationLevel === 2)
        .length,
      required: customerSubscriptions?.filter(
        (s) => s?.recommendedLevel === 2 && s?.certified === false
      )?.length,
      color: "",
    },
    {
      name: "Gold",
      issued: customerSubscriptions?.filter((s) => s?.certificationLevel === 3)
        .length,
      required: customerSubscriptions?.filter(
        (s) => s?.recommendedLevel === 3 && s?.certified === false
      )?.length,
      color: "",
    },
    {
      name: "Platinum",
      issued: customerSubscriptions?.filter((s) => s?.certificationLevel === 4)
        .length,
      required: customerSubscriptions?.filter(
        (s) => s?.recommendedLevel === 4 && s?.certified === false
      )?.length,
      color: "",
    },
    {
      name: "Diamond",
      issued: customerSubscriptions?.filter((s) => s?.certificationLevel === 5)
        .length,
      required: customerSubscriptions?.filter(
        (s) => s?.recommendedLevel === 5 && s?.certified === false
      )?.length,
      color: "",
    },
    {
      name: "Ineligible",
      required: customerSubscriptions?.filter(
        (s) => s?.recommendedLevel === 0 && s?.certified === false
      )?.length,
      color: "",
    },
  ];

  setData({
    ...suppliersData,
    insights: {
      allCustomers,
      tierInvites,
      CAP: {
        certified,
        total,
      },
      months,
      credits,
      // uptick: 79,
      suppliers,
      discount,
      subscriptions,
    },
  });
};

const getHighestCertificationLevel = (customer) => {
  let highestCert = null;

  customer?.certifications?.forEach((cert) => {
    if (cert?.progress?.status === "COMPLETED") {
      if (!highestCert || cert?.level > highestCert?.level) {
        highestCert = {
          certificationId: cert?.id,
          certification: [
            null,
            "Bronze",
            "Silver",
            "Gold",
            "Platinum",
            "Diamond",
          ][cert?.level],
          source: cert?.source,
          status: "Completed",
          expiry: cert?.progress?.expiredDate,
        };
      }
    }
  });

  if (highestCert) {
    return highestCert;
  } else {
    // no completed cert
    const certification = customer?.certification;
    const progress = certification?.progress;
    return {
      certificationId: certification?.id,
      certification: [null, "Bronze", "Silver", "Gold", "Platinum", "Diamond"][
        customer?.certification?.level
      ],
      source: customer?.certification?.source,
      status:
        progress?.step > 1
          ? getCertificationProgressPercentage({
              currentStep: progress?.step,
              totalStep: certification?.totalStep,
            })
          : "0/5", // "0%"
      expiry: null,
    };
  }
};

export const getClientListItemStatus = (customer) => {
  let certification = customer?.certification,
    status = customer?.status,
    assessmentResult = customer?.assessmentResults[0],
    recommended = false;

  if (!customer?.certification && !(customer?.assessmentResults?.length > 0)) {
    certification = "non-certified";
    status = "No activity";
  } else if (!!certification && status !== "Completed") {
    certification = null;
  } else if (!certification && isNonNegativeNumber(assessmentResult?.result)) {
    status = "Assessed";
  } else if (!certification && !!assessmentResult) {
    status = "Assessing";
  }

  if (!certification && isNonNegativeNumber(assessmentResult?.result)) {
    certification = [
      "Ineligible",
      "Bronze (Recommended)",
      "Silver (Recommended)",
      "Gold (Recommended)",
      "Platinum (Recommended)",
      "Diamond (Recommended)",
    ][assessmentResult?.result];
    recommended = true;
  }
  return {
    ...customer,
    name: customer?.addedAs || customer?.name,
    inProgressCertification: customer?.certification,
    certification,
    recommended,
    source: customer?.source,
    statusTxt: status,
    status:
      // <div
      //   onClick={(event) => {
      //     event.preventDefault();
      //     event.stopPropagation();
      //     if (status === "Completed") {
      //       // only when cert is completed
      //       window.open(`${getAppPage()}/issued/${customer?.certificationId}`);
      //     }
      //   }}
      // >
      getClientListItemStatusLabel(status),
    // </div>
    expiry: !!customer?.expiry ? customer?.expiry : "None",
  };
};

const getCertificationProgressPercentage = ({ currentStep, totalStep }) => {
  if (currentStep === 1) {
    return "0/5";
  }
  if (currentStep < 4) {
    return `${Math.round((Math.max(0, currentStep - 1) / totalStep) * 5)}/5`;
  } else {
    return `${Math.round((Math.max(0, currentStep) / totalStep) * 5)}/5`;
  }
};

export const getClientListItemStatusLabel = (status) => {
  // if (!status) {
  //   return <></>;
  // }
  switch (true) {
    case !status || status === "No activity":
      return (
        <StatusLabel variant={"notstarted"} label={"No activity"} animate />
      );
    case status === "Not started":
      return (
        <StatusLabel variant={"notstarted"} label={"Not started"} animate />
      );
    case status.includes("Assessing"):
      return <StatusLabel variant={"assessing"} label={status} animate />;
    case status.includes("Assessed"):
      return <StatusLabel variant={"assessed"} label={status} animate />;
    case status.includes("In progress"):
      return <StatusLabel variant={"inprogress"} label={status} animate />;
    case status.includes("%"):
      return <StatusLabel variant={"inprogress"} label={status} animate />;
    case status.includes("/5"): // n/5 step
      return <StatusLabel variant={"inprogress"} label={status} animate />;
    case status === "Completed":
      return <StatusLabel variant={"completed"} label={"Completed"} animate />;
    default:
      return <></>;
  }
};

export const getCertificationUsedOfferInformation = (source) => {
  if (source?.includes("offer")) {
    return source?.split("offer/")[1]?.split("/")[1]?.split(",")[0];
  } else {
    return "NA";
  }
};
