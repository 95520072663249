export function ToggleButton({
  checked,
  checkedText,
  uncheckedText,
  handleToggle = () => {},
}) {
  return (
    <>
      {/** TODO: Add cursor-pointer back in **/}
      <label className="inline-flex items-center">
        <input
          checked={checked}
          className="peer sr-only"
          disabled
          type="checkbox"
          onChange={handleToggle}
        />
        <div className="peer relative h-4 w-8 rounded-full bg-gray-900 after:absolute after:start-0.5 after:top-0.5 after:h-3 after:w-3 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-4 peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800 rtl:peer-checked:after:-translate-x-4"></div>
        <span className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {!!checked ? checkedText : uncheckedText}
        </span>
      </label>
    </>
  );
}
