import { useEffect, useRef, useState } from "react";

import { setupPartnerAccount } from "api";
import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import {
  Button,
  Confetti,
  HuntressOfferAlert,
  InsightsCapGraph,
  InsightsCertCreditsTable,
  InsightsCertStatusGraph,
  LoadingSpinner,
  OrgTable,
  SpecialOfferAlert,
} from "components";
import { useDashboard } from "contexts";
import { Alert } from "flowbite-react";
import {
  useFetchSuppliersWidgetData,
  useSyncFetchSuppliersWidgetData,
  useSyncVerifyPartnerAccount,
  useVerifyPartnerAccount,
} from "hooks";
import { PageHeader } from "layouts";
import {
  HiCheckCircle,
  HiExclamationCircle,
  HiQuestionMarkCircle,
} from "react-icons/hi";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { setupDashboardData } from "services";
import { ROUTES } from "variables";

export function DashboardPage() {
  const verifyPartnerState = useVerifyPartnerAccount();
  const syncVerifyPartnerState = useSyncVerifyPartnerAccount();
  const fetchSuppliersState = useFetchSuppliersWidgetData();
  const syncFetchSuppliers = useSyncFetchSuppliersWidgetData();

  const { dashboard } = useDashboard();
  const { pathname, state } = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(DATA_INITIAL_STATE);
  const [, setPartner] = useState();
  const [showBulkPurchaseAlert, setShowBulkPurchaseAlert] = useState(false);
  const [showOnboardingAlert, setShowOnboardingAlert] = useState(false);
  const [showOfferAlert, setShowOfferAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const [infoMsg, setInfoMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const confettiRef = useRef(null);

  const navigate = useNavigate();

  const handleDismissOnboardingAlert = async () => {
    setShowOnboardingAlert(false);
    await setupPartnerAccount({});
    await syncVerifyPartnerState();
  };

  const handleDismissOfferAlert = () => {
    setShowOfferAlert(false);

    // Check if showOfferAlert is in the location state
    const { showOfferAlert, ...restState } = state || {};
    if (showOfferAlert) {
      // Remove showOfferAlert from the location state
      navigate(pathname, { replace: true, state: restState });
    }
  };

  const syncSuppliersData = async () => {
    await syncFetchSuppliers({ dashboard: dashboard?.route });
    setIsLoading(false);
  };

  const setup = () => {
    setupDashboardData({
      setPartner,
      setData,
      setShowOnboardingAlert,
      verifyPartnerState,
      fetchSuppliersState,
    });
  };

  useEffect(() => {
    if (!!dashboard) {
      syncSuppliersData();
    }
  }, [dashboard]);

  useEffect(() => {
    if (
      verifyPartnerState?.data?.result &&
      !!fetchSuppliersState?.data?.result
    ) {
      setup();
    }
  }, [verifyPartnerState?.data, fetchSuppliersState?.data]);

  useEffect(() => {
    if (
      searchParams.get("success") === "true" &&
      sessionStorage.getItem("STRIPE_ACTION") === "PENDING"
    ) {
      setShowOfferAlert(true);
      sessionStorage.removeItem("STRIPE_ACTION");
    }

    if (searchParams.get("bulk_success") === "true") {
      setShowBulkPurchaseAlert(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (state?.showOfferAlert === true) {
      setShowOfferAlert(true);
    }
  }, [state]);

  useEffect(() => {
    let interval;
    if (showOnboardingAlert) {
      interval = setInterval(() => {
        if (confettiRef.current) {
          confettiRef.current.fire();
          clearInterval(interval);
        }
      }, 100);
    }

    return () => clearInterval(interval);
  }, [showOnboardingAlert]);

  useEffect(() => {
    let interval;
    if (showOfferAlert) {
      interval = setInterval(() => {
        if (confettiRef.current) {
          confettiRef.current.fire();
          clearInterval(interval);
        }
      }, 100);
    }

    return () => clearInterval(interval);
  }, [showOfferAlert]);

  console.log(fetchSuppliersState?.data?.partner);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SpecialOfferAlert
            confettiRef={confettiRef}
            offer={fetchSuppliersState?.data?.partner?.specialOffer.find(
              (offer) => offer.name === "PARTNER_SPECIAL_CERT_BUNDLE",
            )}
            button={
              <>
                <Button
                  leftIcon={
                    <HiQuestionMarkCircle className="size-4 text-white" />
                  }
                  label={"Learn more"}
                  labelStyles={`whitespace-nowrap text-base`}
                  variant={"purple"}
                  onClick={() => navigate(ROUTES.OFFER.replace("/:type?", ""))}
                />
              </>
            }
            pulse={verifyPartnerState?.data?.partner?.isOfferSetup !== true}
          />

          <HuntressOfferAlert
            offer={fetchSuppliersState?.data?.partner?.specialOffer.find(
              (offer) => offer.name === "huntress",
            )}
            button={
              <>
                <Button
                  leftIcon={
                    <HiQuestionMarkCircle className="size-4 text-white" />
                  }
                  label={"Learn more"}
                  labelStyles={`whitespace-nowrap text-base`}
                  variant={"sky"}
                  onClick={() =>
                    navigate(ROUTES.OFFER.replace("/:type?", "/huntress"))
                  }
                />
              </>
            }
            pulse={true}
          />

          {!!infoMsg && (
            <Alert
              color={"green"}
              onDismiss={() => setInfoMsg("")}
              icon={HiCheckCircle}
            >
              <span className="text-base font-semibold">{infoMsg}</span>
            </Alert>
          )}

          {!!errMsg && (
            <Alert
              color={"red"}
              onDismiss={() => setErrMsg("")}
              icon={HiExclamationCircle}
            >
              <span className="text-base font-semibold">{errMsg}</span>
            </Alert>
          )}

          <PageHeader
            variant="primeDashboard"
            username={verifyPartnerState?.data?.user?.firstName}
            orgName={verifyPartnerState?.data?.partner?.name}
            role={verifyPartnerState?.data?.user?.role}
          />

          {!!showOnboardingAlert && (
            <Alert
              color={"green"}
              onDismiss={handleDismissOnboardingAlert}
              icon={HiCheckCircle}
              additionalContent={
                <div className="text-sm">
                  Congratulations for completing your partner onboarding!
                  <br />
                  We can’t wait to get started on our cyber security
                  certification journey together!
                </div>
              }
            >
              <div className="alert-flowbite">
                <div className="text-base font-semibold">All Done! 🎉</div>
              </div>
            </Alert>
          )}

          {!!showOfferAlert && (
            <Alert
              color={"green"}
              onDismiss={handleDismissOfferAlert}
              icon={HiCheckCircle}
              additionalContent={
                <div className="text-sm">
                  Congratulations for selecting your special offer bundle!
                  <br />
                  Your newly acquired certification credits are now ready to use
                  from the widget below.
                </div>
              }
            >
              <div className="alert-flowbite">
                <div className="text-base font-semibold">Success! 🎉</div>
              </div>
            </Alert>
          )}

          {!!showBulkPurchaseAlert && (
            <Alert
              color={"green"}
              onDismiss={() => setShowBulkPurchaseAlert(false)}
              icon={HiCheckCircle}
              additionalContent={
                <div className="text-sm">
                  Congratulations your bulk purchase was successful!
                  <br />
                  Your newly acquired certification credits are now ready to use
                  from the widget below.
                </div>
              }
            >
              <div className="alert-flowbite">
                <div className="text-base font-semibold">Success! 🎉</div>
              </div>
            </Alert>
          )}

          <span className="text-secondary text-lg font-bold">
            {/* Your {dashboard?.orgTitle.slice(0, -1)} Cyber Assurance Program overview */}
            My Dashboard
          </span>

          <section
            id="cybercert-insights"
            className="flex flex-col gap-5 laptop:flex-row"
          >
            <div className="flex flex-1 flex-col gap-5">
              <InsightsCapGraph data={data?.insights} />

              <InsightsCertStatusGraph data={data?.insights} />
            </div>

            <div className="flex flex-1">
              <InsightsCertCreditsTable
                data={data?.insights}
                handleInfoAlert={setInfoMsg}
                handleErrAlert={setErrMsg}
              />
            </div>
          </section>

          <span className="text-secondary text-lg font-bold">
            {dashboard?.orgTitle} List
          </span>

          <OrgTable data={data?.insights} />

          <Confetti ref={confettiRef} />
        </>
      )}
    </>
  );
}

const DATA_INITIAL_STATE = {
  insights: {
    allCustomers: [[], [], [], [], []],
    CAP: {
      certified: 0,
      total: 0,
    },
    months: [
      {
        new: 0,
        expiring: 0,
      },
      {
        new: 0,
        expiring: 0,
      },
      {
        new: 0,
        expiring: 0,
      },
    ],
    credits: [
      {
        name: "Bronze",
        available: 0,
        total: 0,
        image: IconBronze,
        alt: "IconBronze",
      },
      {
        name: "Silver",
        available: 0,
        total: 0,
        image: IconSilver,
        alt: "IconSilver",
      },
      {
        name: "Gold",
        available: 0,
        total: 0,
        image: IconGold,
        alt: "IconGold",
      },
      {
        name: "Platinum",
        available: 0,
        total: 0,
        image: IconPlatinum,
        alt: "IconPlatinum",
      },
      {
        name: "Diamond",
        available: 0,
        total: 0,
        image: IconDiamond,
        alt: "iconDiamond",
      },
    ],
    suppliers: [],
  },
};
