import { Tooltip } from "flowbite-react";
import {
  HiChatAlt2,
  HiCheck,
  HiEye,
  HiLink,
  HiOutlineGlobeAlt,
} from "react-icons/hi";
import { RiArrowRightUpLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { openZEWidget } from "utils";
import { ROUTES } from "variables";

export function LinkPanel({
  title,
  description,
  link,
  token,
  handleInfoAlert = () => {},
  handleErrAlert = () => {},
}) {
  const navigate = useNavigate();

  const generateAssessmentLink = (token) => {
    return `${window.location.origin}${ROUTES.ASSESSMENT_LINK.replace(":token", token)}`;
  };

  const handleCopyAssessmentLink = (token) => {
    navigator.clipboard.writeText(generateAssessmentLink(token)).then(
      () => {
        handleInfoAlert("Your assessment link has been copied successfully!");
      },
      (error) => {
        handleErrAlert("Failed to copy assessment link to clipboard!");
        console.error(error);
      },
    );
  };

  const handlePreview = () => {
    navigate(ROUTES.ASSESSMENT_LINK.replace(":token", token), {
      state: { preview: true },
    });
  };

  return (
    <>
      <div className="surface-db-stepper border-primary flex w-full flex-col gap-4 rounded-lg border p-5">
        <h2 className="text-base font-semibold">{title}</h2>
        <p>{description}</p>

        <div className="flex w-full flex-row items-center justify-between gap-3">
          <div className="flex w-full flex-row items-center justify-between gap-2 rounded-lg bg-gray-200 p-3">
            <div className="flex flex-row items-center gap-1">
              <HiOutlineGlobeAlt className="text-primary size-5" />
              <span className="hidden tablet:flex">{link}</span>
              <span className="flex tablet:hidden">...</span>
              <div className="surface-secondary w-fit rounded-lg px-2 py-1 font-medium">
                <code className="text-xs">{token}</code>
              </div>
            </div>
            <div className="m-0 rounded-full border border-green-400 bg-green-100 p-0 text-green-400">
              <HiCheck className="size-5" />
            </div>
          </div>
          <Tooltip content="Copy Assessment Link">
            <button
              className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
              onClick={() => handleCopyAssessmentLink(token)}
            >
              <HiLink className="size-5" />
            </button>
          </Tooltip>

          <Tooltip content="Open Assessment Link">
            <Link
              to={generateAssessmentLink(token)}
              target="_blank"
              rel="noreferrer"
              className="border-secondary text-icons flex size-7.5 justify-center rounded-full border bg-gray-100 p-1 hover:bg-green-200 hover:text-green-500"
              onClick={() =>
                handleInfoAlert(
                  "Your assessment link has been opened successfully!",
                )
              }
            >
              <RiArrowRightUpLine className="size-5" />
            </Link>
          </Tooltip>
        </div>

        <div className="flex flex-row gap-4">
          <OptionLinks
            icon={<HiEye className="size-5" />}
            label={"Preview"}
            onClick={handlePreview}
          />
          <OptionLinks
            icon={<HiChatAlt2 className="size-5" />}
            label={"Get Help"}
            onClick={openZEWidget}
          />
        </div>
      </div>
    </>
  );
}

export function OptionLinks({ icon, label, onClick }) {
  return (
    <button
      className="text-icons hover:text-primary m-0 flex flex-row items-center gap-2 p-0 text-sm font-medium hover:font-semibold"
      onClick={onClick}
    >
      {icon}
      {label}
    </button>
  );
}
