import { useEffect, useState } from "react";

import { EditPartnerOrgsData } from "api";
import { Button, OverlaySpinner, TextInput } from "components";
import { useDashboard } from "contexts";
import { Alert, Modal } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";
import { HiCheck, HiExclamation } from "react-icons/hi";
import {
  validateABN,
  validateEmail,
  validateEmpty,
  validateMobile,
} from "utils";

export function ReviewOrganisationsEditModal({
  isOpen = false,
  allOrgs = [],
  editOrgs = [],
  startPolling = () => {},
  setLoading = () => {},
  handleCloseModal = () => {},
}) {
  const { dashboard } = useDashboard();

  const [isModalLoading, setIsModalLoading] = useState(false);
  const [editedOrgs, setEditedOrgs] = useState([]);
  const [showEditWarn, setShowEditWarn] = useState(false);
  const [showEditErr, setShowEditErr] = useState(null);

  const checkDuplicateABNs = (editedOrgs, currentIndex, newABN) => {
    let isDuplicateABN = false;

    // Strip new ABN here for processing
    newABN = newABN?.trim()?.replace(/\s/g, "");

    // Other ABNs - ABNs of the orgs being reviewed but not in the editor
    const otherABNs = allOrgs
      .filter(
        (_, index) =>
          !editedOrgs.map((editedOrg) => editedOrg?.oldIndex).includes(index),
      )
      .map((org) => org?.ABN?.split(" ")?.[1]);

    // Now add those ABNs from the other editor orgs and the new ABN value coming in
    const allABNs = [
      ...otherABNs,
      ...editedOrgs
        .map((editedOrg, index) => {
          if (index !== currentIndex) {
            return editedOrg?.ABN?.trim()?.replace(/\s/g, "");
          } else {
            return newABN;
          }
        })
        .filter((ABN) => ABN !== ""),
      ,
    ];

    // If ABN appears more than twice it is a duplicate
    editedOrgs.forEach((editedOrg, index) => {
      const checkABN = index !== currentIndex ? editedOrg?.ABN : newABN;

      if (allABNs.indexOf(checkABN) !== allABNs.lastIndexOf(checkABN)) {
        editedOrgs[index].duplicateABN = true;
        isDuplicateABN = true;
      } else {
        editedOrgs[index].duplicateABN = false;
      }
    });

    setShowEditWarn(isDuplicateABN);
  };

  const handleInput = (event, index) => {
    const newOrgs = [...editedOrgs];

    newOrgs[index][event?.target?.name] =
      event?.target?.name === "ABN"
        ? event?.target?.value?.replace(/ /g, "")
        : event?.target?.value;
    newOrgs[index]["error"][event?.target?.name] = false;

    checkDuplicateABNs(newOrgs, index, event?.target?.value);

    setEditedOrgs(newOrgs);
  };

  const handleDismissEditWarn = () => {
    setShowEditWarn(false);
  };

  const handleDismissEditErr = () => {
    setShowEditErr(false);
  };

  const handleSubmit = async () => {
    setIsModalLoading(true);

    let submitOrgs = [
      ...editedOrgs?.map((org) => ({
        ...org,
        name: org?.name?.trim(),
        ABN: org?.ABN?.replace(/\s/g, ""),
        email: org?.email?.trim(),
        mobile: org?.mobile?.replace(/\s/g, ""),
      })),
    ];

    let isInvalidField = false;
    submitOrgs.forEach((org) => {
      if (!validateEmpty(org?.name)) {
        org.error.name = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.ABN) || !validateABN(org?.ABN)) {
        org.error.ABN = true;
        isInvalidField = true;
      }

      if (!validateEmpty(org?.ABN) || !validateEmail(org?.email)) {
        org.error.email = true;
        isInvalidField = true;
      }

      if (validateEmpty(org?.mobile) && !validateMobile(org?.mobile)) {
        org.error.mobile = true;
        isInvalidField = true;
      }
    });

    if (!!isInvalidField) {
      setShowEditErr({
        type: "field",
        msg: "You have entered an invalid field. Please check again.",
      });
      setEditedOrgs(submitOrgs);
      setIsModalLoading(false);
      return;
    }

    let isAlreadyInvalidABN = false;
    // If the user has inputted an ABN that has already been processed as INVALID let them know
    const alreadyInvalidABNs = allOrgs.reduce((result, org) => {
      const status = org?.ABN?.split(" ")?.[0];
      const abn = org?.ABN?.split(" ")?.[1];
      if (status === "INVALID_ABN") result.push(abn);
      return result;
    }, []);
    // Now check if any of the user edited ABNs are in the already invalid set
    submitOrgs.forEach((org) => {
      if (alreadyInvalidABNs.includes(org?.ABN)) {
        org.error.ABN = true;
        isAlreadyInvalidABN = true;
      }
    });
    if (!!isAlreadyInvalidABN) {
      setShowEditErr({
        type: "repeat",
        msg: "You have entered an ABN already processed as INVALID. Please edit or remove it.",
      });
      setEditedOrgs(submitOrgs);
      setIsModalLoading(false);
      return;
    }

    // Everything valid, now get ones that have actually changed
    const changedOrgs = submitOrgs.reduce(
      (result, org) => {
        const { name, ABN, email, mobile, oldIndex } = org;

        if (
          name !== allOrgs[oldIndex]?.addedAs ||
          ABN !== allOrgs[oldIndex]?.ABN?.split(" ")?.[1] ||
          email !== allOrgs[oldIndex]?.email ||
          mobile !== allOrgs[oldIndex]?.phone
        ) {
          result.old.push({
            name: allOrgs[oldIndex]?.addedAs,
            ABN: allOrgs[oldIndex]?.ABN?.split(" ")?.[1],
            email: allOrgs[oldIndex]?.email,
            mobile: allOrgs[oldIndex]?.phone,
          });
          result.new.push({ name, ABN, email, mobile });
        }
        return result;
      },
      { old: [], new: [] },
    );

    try {
      await EditPartnerOrgsData({
        dashboard: dashboard?.route,
        data: {
          newOrg: changedOrgs.new[0],
          oldOrg: changedOrgs.old[0],
        },
      });
      startPolling();
    } catch (error) {
      console.error(error);
    }

    setIsModalLoading(false);
    handleCloseModal();
    setLoading(true);
  };

  useEffect(() => {
    setEditedOrgs(
      editOrgs?.map((org, index) => ({
        name: org?.addedAs,
        ABN: org?.ABN?.split(" ")?.[1],
        email: org?.email,
        mobile: org?.phone,
        error: {
          name: false,
          ABN: false,
          email: false,
          mobile: false,
        },
        duplicateABN: false,
        oldIndex: org?.index,
      })),
    );
  }, [isOpen]);

  useEffect(() => {
    if (
      !editedOrgs.some((org) =>
        Object.values(org.error).some((error) => !!error),
      )
    ) {
      setShowEditErr(null);
    }
  }, [editedOrgs]);

  return (
    <>
      <Modal
        show={isOpen}
        size="5xl"
        position="center"
        onClose={handleCloseModal}
      >
        <Modal.Header>
          Update{" "}
          {editOrgs?.length > 1
            ? dashboard?.orgTitle
            : dashboard?.orgTitleSingle}{" "}
        </Modal.Header>
        <Modal.Body>
          {!!isModalLoading && <OverlaySpinner />}

          <div className="flex flex-col gap-5">
            {!!showEditWarn && (
              <Alert
                color="yellow"
                onDismiss={handleDismissEditWarn}
                icon={HiExclamation}
              >
                You have entered a duplicate ABN. Please check you have not
                already uploaded this ABN.
              </Alert>
            )}

            {!!showEditErr && (
              <Alert
                color="red"
                onDismiss={handleDismissEditErr}
                icon={BiSolidErrorAlt}
              >
                {showEditErr?.msg}
              </Alert>
            )}

            <div className="flex flex-col gap-4 self-stretch">
              <div className="text-primary hidden text-sm font-medium laptop:flex laptop:gap-4">
                <span className="invisible">{editOrgs?.length}.</span>
                <span className="ml-1 w-full">{dashboard?.orgTitleSingle}</span>
                <span className="ml-1 w-full">ABN</span>
                <span className="ml-1 w-full">Email</span>
                <span className="ml-1 w-full">Mobile</span>
              </div>

              {editedOrgs?.map((org, index) => (
                <EditOrg
                  key={index}
                  item={org}
                  index={index}
                  handleInput={handleInput}
                />
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="w-full laptop:w-fit"
            label={"Submit"}
            leftIcon={<HiCheck className="size-4" />}
            variant={"blue"}
            onClick={handleSubmit}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

function EditOrg({ item, index, handleInput = () => {} }) {
  const { dashboard } = useDashboard();

  return (
    <div className="flex flex-col items-start gap-4 laptop:flex-row laptop:items-center">
      <div className="text-primary flex flex-row gap-1 text-sm font-medium">
        <span className="flex laptop:hidden">{dashboard?.orgTitleSingle}</span>{" "}
        {index + 1}.
      </div>

      <TextInput
        color={!!item?.error?.["name"] ? "failure" : "gray"}
        name="name"
        placeholder={`Enter ${dashboard?.orgTitleSingle} name`}
        required
        type="text"
        value={item.name
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        color={
          !!item?.error?.["ABN"]
            ? "failure"
            : !!item?.duplicateABN
              ? "warning"
              : "gray"
        }
        name="ABN"
        placeholder={`Enter ${dashboard?.orgSingle} ABN`}
        required
        type="tel"
        value={item?.ABN}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        color={!!item?.error?.["email"] ? "failure" : "gray"}
        name="email"
        placeholder={`Enter ${dashboard?.orgSingle} email`}
        required
        type="email"
        value={item?.email}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        color={!!item?.error?.["mobile"] ? "failure" : "gray"}
        id={`mobile-${index}`}
        label="Optional"
        name="mobile"
        placeholder={`Enter ${dashboard?.orgSingle} mobile`}
        required
        type="tel"
        value={item?.mobile}
        onInput={(event) => handleInput(event, index)}
      />
    </div>
  );
}
