import { useEffect, useState } from "react";

import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import { InsightsCertStatusGraph, LoadingSpinner, OrgTable } from "components";
import { Breadcrumbs } from "components/flowbite";
import { useDashboard } from "contexts";
import {
  useFetchSuppliersWidgetData,
  useSyncFetchSuppliersWidgetData,
  useVerifyPartnerAccount,
} from "hooks";
import { PageHeader } from "layouts";
import { HiChartPie } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { setupDashboardData } from "services";
import { ROUTES } from "variables";

export function OrganisationsPage() {
  const verifyPartnerState = useVerifyPartnerAccount();
  const fetchSuppliersState = useFetchSuppliersWidgetData();
  const syncFetchSuppliers = useSyncFetchSuppliersWidgetData();

  const { dashboard } = useDashboard();

  const [isLoading, setIsLoading] = useState(true);
  const [_, setPartner] = useState();
  const [data, setData] = useState(DATA_INITIAL_STATE);
  const [showInsights, setShowInsights] = useState(true);

  const navigate = useNavigate();

  const toggleInsights = () => {
    setShowInsights(!showInsights);
  };

  async function syncSuppliersData() {
    await syncFetchSuppliers({ dashboard: dashboard?.route });
    setIsLoading(false);
  }

  async function setup() {
    setupDashboardData({
      setPartner,
      setData,
      verifyPartnerState,
      fetchSuppliersState,
    });
  }

  useEffect(() => {
    if (!!dashboard) {
      syncSuppliersData();
    }
  }, [dashboard]);

  useEffect(() => {
    if (
      verifyPartnerState?.data?.result &&
      !!fetchSuppliersState?.data?.result
    ) {
      setup();
    }
  }, [verifyPartnerState?.data, fetchSuppliersState?.data]);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <PageHeader
            variant="primePage"
            pageName={`Our ${dashboard?.orgTitle}`}
            pageDescription={`Find your ${dashboard?.orgTitle.toLowerCase()} and keep up with their certification status.`}
            btnLabel={`Add ${dashboard?.orgTitle}`}
            onClick={() => navigate(ROUTES.ADD_ORGS)}
            customBreadcrumbs={
              <Breadcrumbs
                icon={HiChartPie}
                items={{
                  "": ROUTES.ROOT,
                  [dashboard?.orgTitle]: ROUTES.ORGANISATIONS,
                }}
              />
            }
            showInsights={showInsights}
            toggleInsights={toggleInsights}
          />

          {showInsights && (
            <section
              id="cybercert-insights"
              className="flex flex-col gap-5 laptop:flex-row"
            >
              <InsightsCertStatusGraph data={data?.insights} />
              <div className="hidden flex-1 laptop:flex"></div>
            </section>
          )}

          <span className="text-secondary text-lg font-bold">
            {dashboard?.orgTitle} List
          </span>

          <OrgTable data={data?.insights} />
        </>
      )}
    </>
  );
}

const DATA_INITIAL_STATE = {
  insights: {
    allCustomers: [[], [], [], [], []],
    CAP: {
      certified: 0,
      total: 0,
    },
    months: [
      {
        new: 0,
        expiring: 0,
      },
      {
        new: 0,
        expiring: 0,
      },
      {
        new: 0,
        expiring: 0,
      },
    ],
    credits: [
      {
        name: "Bronze",
        available: 0,
        total: 0,
        image: IconBronze,
        alt: "IconBronze",
      },
      {
        name: "Silver",
        available: 0,
        total: 0,
        image: IconSilver,
        alt: "IconSilver",
      },
      {
        name: "Gold",
        available: 0,
        total: 0,
        image: IconGold,
        alt: "IconGold",
      },
      {
        name: "Platinum",
        available: 0,
        total: 0,
        image: IconPlatinum,
        alt: "IconPlatinum",
      },
      {
        name: "Diamond",
        available: 0,
        total: 0,
        image: IconDiamond,
        alt: "iconDiamond",
      },
    ],
    // uptick: 79,
    suppliers: [],
  },
};
