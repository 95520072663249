import { SearchInputTheme } from "assets";
import { TextInput as FlowbiteTextInput } from "flowbite-react";

export function SearchInput({
  id = "",
  type = "text",
  name = "",
  placeholder = "",
  icon,
  required,
  shadow,
  onChange = () => {},
  onKeyDown = () => {},
  onKeyPress = () => {},
  onPaste = () => {},
  color = "surface",
  value,
}) {
  return (
    <FlowbiteTextInput
      id={id}
      type={type}
      name={name}
      placeholder={placeholder}
      icon={icon}
      required={required}
      shadow={shadow}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyPress={onKeyPress}
      onPaste={onPaste}
      color={color}
      theme={SearchInputTheme}
      value={value}
    />
  );
}
