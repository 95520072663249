import React, { useEffect, useState } from "react";

import { removeUserFromOrganisation } from "api";
import { Button, OverlaySpinner, TextInput } from "components";
import { Modal } from "flowbite-react";
import { BsExclamation, BsXCircleFill } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { HiCheck } from "react-icons/hi";
import { AuthService } from "services";

export function RemoveUsersModal({
  isOpen,
  users,
  handleCloseModal = () => {},
  handleSync = async () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [currentUser, setCurrentUser] = useState();

  const handleClose = () => {
    setConfirmText("");
    setIsSuccess(false);
    handleCloseModal();
  };

  const handleInput = (event) => {
    setConfirmText(event?.target?.value);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    let result = await removeUserFromOrganisation({
      email: users[0]?.email,
      ABN: users[0]?.ABN,
    });

    if (result?.result === true) {
      if (currentUser?.id === users[0]?.id) {
        AuthService.signOutOnRemoval();
      }
      handleSync();
      setIsSuccess(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await AuthService.getCurrentAuthUser();
      setCurrentUser(user);
    };

    fetchCurrentUser();
  }, []);

  return (
    <Modal show={isOpen} size="2xl" position="center" onClose={handleClose}>
      <Modal.Header>Remove User{users?.length > 1 && "s"}</Modal.Header>
      <Modal.Body>
        {!!isLoading && <OverlaySpinner />}

        <div className="flex flex-col gap-4 self-stretch">
          <div className="flex flex-col gap-1 self-stretch">
            <div className="flex items-center gap-1.5">
              {isSuccess ? (
                <>
                  <HiCheck className="h-4.5 w-4.5 rounded-full bg-green-50 text-green-700" />
                  <span className="text-lg font-medium text-green-700">
                    Users removed successfully!
                  </span>
                </>
              ) : (
                <>
                  <BsExclamation className="h-4.5 w-4.5 rounded-full bg-red-100 text-red-500" />
                  <span className="text-lg font-medium text-red-500">
                    Before you proceed
                  </span>
                </>
              )}
            </div>

            <span className="text-base font-normal text-gray-500">
              {isSuccess
                ? "The following users have been successfully removed:"
                : "The following users will be removed from your organisation:"}
            </span>
          </div>

          <div className="grid px-4 tablet:grid-cols-[auto_1fr] tablet:gap-x-10 tablet:gap-y-4">
            {users?.map((user, index) => (
              <React.Fragment key={index}>
                <div className="flex flex-shrink-0 flex-row items-center gap-2 py-0.5">
                  <BsXCircleFill className="size-4 text-red-500" />
                  <span className="text-primary text-base font-normal">
                    {user?.firstName} {user?.lastName}
                    {!user?.firstName && !user?.lastName && (
                      <span className="text-gray-300">(No Name)</span>
                    )}
                  </span>
                </div>

                <div className="ml-6 flex flex-row items-center gap-2 self-stretch py-0.5 tablet:ml-0">
                  <FiMail className="text-icons size-5 flex-shrink-0" />
                  <span className="text-base font-normal text-gray-500">
                    {user?.email}
                  </span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!isSuccess && (
          <div className="flex flex-col gap-5">
            <span>Please type 'delete' to continue</span>

            <TextInput
              color="gray"
              placeholder={`Enter 'delete' to continue`}
              required
              type="tel"
              value={confirmText}
              onInput={handleInput}
            />

            <Button
              disabled={confirmText.toUpperCase() !== "DELETE"}
              label="Remove"
              variant="red"
              customClasses="w-fit"
              onClick={handleSubmit}
            />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}
