import { useEffect, useRef, useState } from "react";

import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import { Button, FilterButton, FlowbitePagination } from "components";
import { Table } from "flowbite-react";
import { useTableScrollPosition } from "hooks";
import { BsShieldFillExclamation } from "react-icons/bs";
import { HiFilter, HiX } from "react-icons/hi";
import { Link } from "react-router-dom";
import {
  getAppPage,
  getCertificationUsedOfferInformation,
  getClientListItemStatus,
} from "services";

const certIcons = {
  Bronze: IconBronze,
  Silver: IconSilver,
  Gold: IconGold,
  Platinum: IconPlatinum,
  Diamond: IconDiamond,
};

const FILTERS = [
  "Assessed",
  "Claimed",
  "Certified",
  "Bronze",
  "Silver",
  "Gold",
  "Platinum",
  "Diamond",
  "Credit Claimed",
  "Discount Claimed",
];

export function SubscriptionActivityTable({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageOption, setPageOption] = useState(10);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filteredData, setFilteredData] = useState();

  const tableRef = useRef(null);
  const tableScroll = useTableScrollPosition(tableRef);

  const totalPages = Math.ceil(filteredData?.length / pageOption);

  const getSubscriptionActivityRenders = () => {
    const startIndex = (currentPage - 1) * pageOption;
    const currentSubscriptions = filteredData?.slice(
      startIndex,
      startIndex + pageOption
    );
    return currentSubscriptions?.map((subscription, index) => (
      <SubscriptionTableRow
        key={index}
        subscription={subscription}
        scrollPos={tableScroll.scrollLeft}
      />
    ));
  };

  const getSubscriptionActivityRendersMobile = () => {
    const startIndex = (currentPage - 1) * pageOption;
    const currentSubscriptions = filteredData?.slice(
      startIndex,
      startIndex + pageOption
    );
    return currentSubscriptions?.map((subscription, index) => (
      <SubscriptionTableRowMobile
        key={index}
        subscription={subscription}
        index={index}
      />
    ));
  };

  const handlePageOptionChange = (value) => {
    setPageOption(value);
    setCurrentPage(
      Math.min(currentPage, Math.ceil(filteredData?.length / value))
    );
  };

  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  const toggleFilter = (filter) => {
    if (selectedFilter.includes(filter)) {
      setSelectedFilter(selectedFilter.filter((item) => item !== filter));
    } else {
      setSelectedFilter([...selectedFilter, filter]);
    }
  };

  const clearFilters = () => {
    setSelectedFilter([]);
  };

  function getFilteredOrgData(data, filters) {
    if (filters?.length === 0) return data;

    let result = [];
    if (filters.includes("Assessed")) {
      result = [
        ...result,
        ...data.filter((item) => item?.statusTxt === "Assessed"),
      ];
    }
    if (filters.includes("Claimed")) {
      result = [
        ...result,
        ...data.filter(
          (item) =>
            (item?.statusTxt === "In Progress" ||
              item?.statusTxt.includes("%")) &&
            !!item?.inProgressCertification
        ),
      ];
    }
    if (filters.includes("Certified")) {
      result = [
        ...result,
        ...data.filter((item) => item?.statusTxt === "Completed"),
      ];
    }
    if (filters.includes("Bronze")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Bronze")
          // || item?.inProgressCertification?.includes("Bronze"),
        ),
      ];
    }
    if (filters.includes("Silver")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Silver")
          // || item?.inProgressCertification?.includes("Silver"),
        ),
      ];
    }
    if (filters.includes("Gold")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Gold")
          // || item?.inProgressCertification?.includes("Gold"),
        ),
      ];
    }
    if (filters.includes("Platinum")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Platinum")
          // || item?.inProgressCertification?.includes("Platinum"),
        ),
      ];
    }
    if (filters.includes("Diamond")) {
      result = [
        ...result,
        ...data.filter(
          (item) => item?.certification?.includes("Diamond")
          // || item?.inProgressCertification?.includes("Diamond"),
        ),
      ];
    }
    if (filters.includes("Credit Claimed")) {
      result = [
        ...result,
        ...data.filter((item) => item?.source?.includes("invite")),
      ];
    }
    if (filters.includes("Discount Claimed")) {
      result = [
        ...result,
        ...data.filter((item) => item?.source?.includes("offer")),
      ];
    }

    const filteredResult = data
      .map((item) => result.filter((r) => r?.ABN === item?.ABN)[0])
      .filter(Boolean);
    return filteredResult;
  }

  useEffect(() => {
    let updatedOrgData = data?.suppliers?.map((supplier) =>
      getClientListItemStatus(supplier)
    );

    const filteredData = getFilteredOrgData(updatedOrgData, selectedFilter);

    setFilteredData(filteredData);
  }, [data, selectedFilter]);

  return (
    <section className="cybercert-subscriptions-activity-table border-primary flex flex-col self-stretch">
      <div className="flex flex-col tablet:flex-row items-center justify-center gap-3 self-stretch border border-b-0 p-4">
        <div className="flex items-center gap-2 self-stretch py-2">
          <HiFilter className="text-icons size-4" />
          <span className="text-secondary text-xs font-medium">Activity</span>
        </div>

        <div className="flex flex-grow flex-wrap items-center gap-1 self-stretch">
          {FILTERS?.map((filter, index) => (
            <div
              key={index}
              onClick={() => {
                toggleFilter(filter);
              }}
            >
              <FilterButton
                impIsSelected={selectedFilter.includes(filter)}
                key={index}
              >
                {filter}
              </FilterButton>
            </div>
          ))}
        </div>

        <Button
          onClick={clearFilters}
          label="Clear Filters"
          leftIcon={<HiX className="text-icons size-4" />}
          variant="white"
        />
      </div>

      {/****************************** PC TABLE ******************************/}
      <div className="hidden laptop:block">
        <Table ref={tableRef} hoverable>
          <Table.Head>
            <Table.HeadCell
              className={`w-auto ${tableScroll.scrollLeft > 0 ? "shadow-table" : ""}`}
            >
              Organisation
            </Table.HeadCell>
            <Table.HeadCell className="w-64">Certification</Table.HeadCell>
            <Table.HeadCell className="w-36">Status</Table.HeadCell>
            <Table.HeadCell className="w-48">Used Offer</Table.HeadCell>
          </Table.Head>

          <Table.Body>
            {!!filteredData?.length ? (
              getSubscriptionActivityRenders()
            ) : (
              <Table.Row>
                <Table.Cell colSpan={5} className="text-center">
                  No subscription activity currently listed
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      {/****************************** MOBILE TABLE ******************************/}
      <div className="laptop:hidden">
        <div className="surface-secondary text-primary border p-4 text-xs font-semibold uppercase">
          Subscription Activities
        </div>

        <div className="flex flex-col border-x">
          {!!filteredData?.length ? (
            getSubscriptionActivityRendersMobile()
          ) : (
            <div className="border-b p-5 text-center">
              No subscription activity currently listed
            </div>
          )}
        </div>
      </div>

      {!!filteredData?.length && (
        <FlowbitePagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          totalCount={filteredData?.length}
          selectedOption={pageOption}
          handleOptionClick={handlePageOptionChange}
        />
      )}
    </section>
  );
}

function SubscriptionTableRow({ subscription, scrollPos }) {
  return (
    <Table.Row className="w-fit bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell
        className={`sticky z-5 left-16${scrollPos > 0 ? " shadow-table" : ""}`}
      >
        {subscription?.name}
      </Table.Cell>
      <Table.Cell>
        {!!subscription?.certification ? (
          !!certIcons[subscription?.certification] ? (
            <Link
              className="hover:text-link flex w-fit items-center gap-3 rounded-full outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-8"
              to={`${getAppPage()}/issued/${subscription?.certificationId}`}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              <img
                src={certIcons[subscription?.certification]}
                alt={`Icon${subscription?.certification}`}
                className="size-6.5"
              />
              <span className="uppercase">{subscription?.certification}</span>
            </Link>
          ) : subscription?.recommended ? (
            <div className="flex flex-row items-center gap-3">
              <BsShieldFillExclamation className="text-icons h-6 w-6" />
              {subscription?.certification}
            </div>
          ) : (
            <div className="flex flex-row gap-3">
              <BsShieldFillExclamation className="text-icons h-6 w-6" />
              Non-Certified
            </div>
          )
        ) : subscription?.recommended ? (
          <div className="flex flex-row gap-3">
            <BsShieldFillExclamation className="text-icons h-6 w-6" />
            {subscription?.certification}
          </div>
        ) : (
          <div className="flex flex-row gap-3">
            <BsShieldFillExclamation className="text-icons h-6 w-6" />
            Non-Certified
          </div>
        )}
      </Table.Cell>
      <Table.Cell>{subscription?.status}</Table.Cell>
      <Table.Cell>
        {getCertificationUsedOfferInformation(subscription?.source)}
      </Table.Cell>
    </Table.Row>
  );
}

function SubscriptionTableRowMobile({ subscription, index }) {
  return (
    <div className="hover:surface-db-stepper grid grid-cols-[auto_1fr] gap-4 border-b p-4 font-medium">
      <span className="text-gray pr-1 font-normal">Name:</span>
      <span>{subscription?.name}</span>
      <span className="text-gray pr-1 font-normal">Mobile:</span>
      {!!subscription?.certification ? (
        !!certIcons[subscription?.certification] ? (
          <Link
            className="hover:text-link flex w-fit items-center gap-3 rounded-full outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-8"
            to={`${getAppPage()}/issued/${subscription?.certificationId}`}
            target="_blank"
            referrerPolicy="no-referrer"
          >
            <img
              src={certIcons[subscription?.certification]}
              alt={`Icon${subscription?.certification}`}
              className="size-6.5"
            />
            <span className="uppercase">{subscription?.certification}</span>
          </Link>
        ) : subscription?.recommended ? (
          <div className="flex flex-row gap-3">
            <BsShieldFillExclamation className="text-icons h-6 w-6" />
            {subscription?.certification}
          </div>
        ) : (
          <div className="flex flex-row gap-3">
            <BsShieldFillExclamation className="h-6 w-6 text-gray-300" />
            Non-Certified
          </div>
        )
      ) : subscription?.recommended ? (
        <div className="flex flex-row gap-3">
          <BsShieldFillExclamation className="text-icons h-6 w-6" />
          {subscription?.certification}
        </div>
      ) : (
        <div className="flex flex-row gap-3">
          <BsShieldFillExclamation className="h-6 w-6 text-gray-300" />
          Non-Certified
        </div>
      )}
      <span className="text-gray pr-1 font-normal">Status:</span>
      {subscription?.status}
      <span className="text-gray pr-1 font-normal">Used Offer:</span>
      {getCertificationUsedOfferInformation(subscription?.source)}
    </div>
  );
}
