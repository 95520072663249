export const PaginationTheme = {
  base: "",
  layout: {
    table: {
      base: "text-sm text-gray",
      span: "font-semibold text-gray-900 dark:text-white",
    },
  },
  pages: {
    base: "inline-flex items-center -space-x-px",
    showIcon: "inline-flex",
    previous: {
      base: "ml-0 rounded-l-lg border border-gray-300 bg-white py-[7px] px-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
      icon: "size-5",
    },
    next: {
      base: "rounded-r-lg border border-gray-300 bg-white py-[7px] px-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
      icon: "size-5",
    },
    selector: {
      base: "mx-auto flex justify-center w-10 border border-gray-300 surface-white px-2 py-2 leading-tight text-gray-500 enabled:hover:bg-gray-100 enabled:hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 enabled:dark:hover:bg-gray-700 enabled:dark:hover:text-white",
      active:
        " font-semibold bg-blue-50 text-blue-600 hover:bg-blue-100 hover:text-blue-700 dark:border-blue-700 dark:bg-blue-700 dark:text-white",
      disabled: "opacity-50 cursor-normal",
    },
  },
};
