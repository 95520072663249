import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function PartnerClientManagementDocs({ resourceLinks, title }) {
  return (
    <>
      <h2 className="text-2xl font-semibold">{title}</h2>
      {resourceLinks?.clientManagement ? (
        <>
          <video className="w-full border border-gray-300" controls>
            <source src={resourceLinks?.clientManagement} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        <>
          <Spinner theme={SpinnerTheme} color="darkGray" />
        </>
      )}

      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-black">Adding Client Listings</p>
        <p>
          The first step in your new account is to add preliminary listings for
          your clients. Head to the clients page and click add clients to start.
        </p>

        <p className="font-medium text-black">Client Information</p>
        <p>
          Either upload a csv file or add manually. From here you can enter a
          preliminary trading name for your reference, customer email address,
          ABN and optionally their mobile too.
        </p>
      </div>
    </>
  );
}
