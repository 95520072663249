import React from "react";
import { Modal } from "react-bootstrap";

export function CertificationOverviewDocs() {
  return (
    <>
      <h2 className="text-2xl font-semibold">Certification Overview</h2>
      <Modal.Body>
        <video
          className="w-full border border-gray-300"
          src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/resources/Cert+App+Clip+01.mp4"
          controls
          playsInline
        ></video>
      </Modal.Body>
      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-lg text-black">Welcome to CyberCert.</p>
        <p>
          The purpose of this video is to guide you through the CyberCert
          certification process so your organisation can quickly and easily get
          cyber certified.
        </p>
        <p>
          For this demonstration account, we have purchased a bronze level
          certification, registered, logged in, and are now ready to commence.
        </p>
        <p>Let’s get started!</p>
      </div>
    </>
  );
}
