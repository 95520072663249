import { useEffect, useState } from "react";

import { AlertTheme, AppleLogo, PlayLogo, TextInputTheme } from "assets";
import { Button, LoadingSpinner } from "components";
import { Alert, TextInput, Tooltip } from "flowbite-react";
import QRCode from "qrcode.react";
import { HiArrowLeft, HiCheck, HiCheckCircle } from "react-icons/hi";
import { PiCopySimpleFill } from "react-icons/pi";
import { RiQrScan2Line } from "react-icons/ri";
import { AuthService } from "services";
import {
  filterNumberKeyPress,
  filterNumberPaste,
  handleEnterKeyDownSubmission,
} from "utils";

const authenticatorApps = {
  "Microsoft Authenticator": {
    playLink:
      "https://play.google.com/store/apps/details?id=com.azure.authenticator",
    appleLink:
      "https://apps.apple.com/us/app/microsoft-authenticator/id983156458",
  },
  "Google Authenticator": {
    playLink:
      "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
    appleLink: "https://apps.apple.com/us/app/google-authenticator/id388497605",
  },
};

export function MFASetupOnboarding({
  partner,
  handleNextStep = () => { },
  handlePrevStep = () => { },
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const [showQR, setShowQR] = useState(false);
  const [verifyDone, setVerifyDone] = useState(partner?.isMfaDone);
  const [showAlert, setShowAlert] = useState(false);
  const [showSetupBtn, setShowSetupBtn] = useState(true);
  const [user, setUser] = useState();
  const [setupCode, setSetupCode] = useState();
  const [generatedCode, setGeneratedCode] = useState();
  const [showCode, setShowCode] = useState(false);

  useEffect(() => {
    // setupTOTP();
    // console.log(partner);
  }, []);

  // console.log(verifyDone);

  const handleAppSelection = (app) => {
    setSelectedApp(app);
  };

  const handleDismiss = () => {
    setShowAlert(false);
  };

  const handleVerify = () => {
    setVerifyDone(true);
  };

  const toggleCode = () => {
    setShowCode(true);
    setShowQR(false);
  };

  const toggleQR = () => {
    setShowCode(false);
    setShowQR(true);
    setShowAlert(false);
  };

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(setupCode || "").then(
      () => {
        // Handle success
        setShowAlert(true);
      },
      () => {
        // Handle error
        console.log("Failed to copy code to clipboard!");
      },
    );
  };

  const setupTOTP = async () => {
    setIsLoading(true);
    const setupDetails = await AuthService.setupTOTP();
    // console.log(setupDetails);

    setSetupCode(setupDetails?.code);
    setUser(setupDetails?.user);
    if (!!setupDetails?.code) {
      setShowQR(true);
      setShowSetupBtn(false);
    }
    setIsLoading(false);
  };

  const verifyTOTP = async () => {
    const res = await AuthService.verifyTOTP(generatedCode);
    setSetupCode(null);
    setUser(null);
    const newUser = await AuthService.getCurrentAuthSession();
    setUser(newUser);

    return res;
  };

  const handleInput = (event) => {
    setErr(null);
    setGeneratedCode(event?.target?.value);
  };

  const verifyNextStep = async (event) => {
    // Check code is a 6 digit number
    if (!/^\d{6}$/.test(generatedCode.replace(/\s/g, ""))) {
      setErr({ type: "code", msg: "This is an invalid MFA code" });
      return;
    }

    setIsLoading(true);

    const result = await verifyTOTP();
    if (!!result) {
      // Code was correct
      handleVerify();
    } else {
      // Code was incorrect so display error
      setErr({ type: "code", msg: "Incorrect code. Please try again." });
    }

    setIsLoading(false);
  };

  return verifyDone ? (
    <div className="flex flex-col gap-8 self-stretch laptop:w-[565px]">
      <div className="flex flex-col items-center gap-2 tablet:items-start">
        <div className="text-primary text-lg font-bold">You’re All Set!</div>

        <div className="text-gray">
          MFA setup done. Your account is now secured.
        </div>
      </div>

      <div className="flex w-full gap-3 self-stretch">
        <Button
          customClasses={`w-full laptop:w-fit`}
          leftIcon={<HiArrowLeft className="size-4" />}
          label={"Back"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={handlePrevStep}
        />

        <Button
          customClasses={`w-full tablet:w-fit`}
          leftIcon={<HiCheck className="size-4" />}
          label={"Next Step"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={handleNextStep}
        />

        {/* TODO: Add a redo step here for MFA? */}
      </div>
    </div>
  ) : (
    <div className="flex flex-col gap-8 self-stretch laptop:w-[565px]">
      <div className="flex flex-col self-stretch">
        <div className="text-primary text-lg font-bold">MFA Setup</div>

        <div className="text-gray">
          Download the free{" "}
          <button
            className="text-link font-medium outline-none hover:opacity-80 focus:underline"
            onClick={() => handleAppSelection("Microsoft Authenticator")}
          >
            Microsoft Authenticator
          </button>{" "}
          or{" "}
          <button
            className="text-link font-medium outline-none hover:opacity-80 focus:underline"
            onClick={() => handleAppSelection("Google Authenticator")}
          >
            Google Authenticator
          </button>{" "}
          app, click add, and then scan this QR code to set up your account. Or
          keep using an authenticator app of your choice.
        </div>
      </div>

      <DownloadButtons
        selectedApp={selectedApp}
        setSelectedApp={setSelectedApp}
      />

      {showAlert && (
        <Alert
          color={"green"}
          onDismiss={handleDismiss}
          icon={HiCheckCircle}
          theme={AlertTheme}
          className="w-full"
        >
          <div className="alert-flowbite">
            <div className="text-base font-semibold">
              Code has been copied successfully!
            </div>
          </div>
        </Alert>
      )}

      {showSetupBtn && (
        <div className="w-full">
          <Button
            btnWidth={"w-full"}
            customClasses={"py-2.5 px-5"}
            variant={"black"}
            leftIcon={<RiQrScan2Line className="size-5" />}
            label={"Scan QR Code"}
            labelStyles={"text-sm font-semibold"}
            onClick={setupTOTP}
          />
        </div>
      )}

      {showQR && (
        <div id="QR" className="flex flex-col items-center gap-5 py-2">
          <QRCode
            size={118}
            // value={`otpauth://totp/AWSCognito:${user?.username}?secret=${setupCode}&issuer=CyberCert`}
            value={`otpauth://totp/CyberCert:${user?.attributes?.email}?secret=${setupCode}&issuer=CyberCert`}
          />

          <button
            className="text-link text-sm font-semibold outline-none hover:opacity-80 focus:underline"
            onClick={toggleCode}
          >
            Enter code manually instead
          </button>
        </div>
      )}

      {showCode && (
        <div id="code" className="flex flex-col gap-3 self-stretch">
          <div className="border-primary surface-50 flex items-center justify-between self-stretch rounded-lg border p-[11px]">
            <div className="text-dark-blue w-5/6 text-wrap break-words text-base font-medium">
              {setupCode}
            </div>

            <button
              className="surface-border-primary flex size-7.5 shrink-0 items-center justify-center rounded-full"
              onClick={copyCodeToClipboard}
            >
              <Tooltip content="Copy Code" placement="bottom">
                <PiCopySimpleFill className="text-icons size-5" />
              </Tooltip>
            </button>
          </div>
          <button
            className="text-link text-sm font-medium outline-none hover:opacity-80 focus:underline"
            onClick={toggleQR}
          >
            Scan QR Code
          </button>
        </div>
      )}

      {!!isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="flex w-full flex-col gap-2">
          Enter the code shown in your authentication app
          {!!err?.msg && (
            <div className="body-sm font-medium text-red-600">
              {err.msg}
            </div>
          )}
          <TextInput
            name="mfa"
            type="number"
            placeholder="Enter code"
            required
            shadow
            onChange={handleInput}
            onKeyDown={(event) =>
              handleEnterKeyDownSubmission(event, verifyNextStep)
            }
            onKeyPress={filterNumberKeyPress}
            onPaste={filterNumberPaste}
            color={!!err ? "failure" : "gray"}
            theme={TextInputTheme}
          />
        </div>
      )}

      <div className="flex w-full gap-3 self-stretch">
        <Button
          customClasses={`w-full laptop:w-fit`}
          leftIcon={<HiArrowLeft className="size-4" />}
          label={"Back"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={handlePrevStep}
        />

        <Button
          customClasses={`w-full laptop:w-fit`}
          leftIcon={<HiCheck className="size-4" />}
          label={"Continue"}
          labelStyles={"font-semibold text-sm"}
          variant={"blue"}
          onClick={verifyNextStep}
        />
      </div>
    </div>
  );
}

export function DownloadButtons({
  selectedApp = null,
  setSelectedApp = () => { },
}) {
  if (!selectedApp) return;

  return (
    <div className="flex w-1/2 flex-col items-center gap-5 self-center">
      <div className="text-secondary text-sm font-semibold">
        Download {selectedApp}
      </div>

      <div className="flex flex-col items-center justify-center gap-2 self-stretch">
        <Button
          btnWidth={"w-full"}
          variant={"black"}
          customClasses={"py-2.5"}
          label={"Download from Play Store"}
          labelStyles={"text-sm font-semibold"}
          onClick={() =>
            window.open(authenticatorApps[selectedApp].playLink, "_blank")
          }
          leftIcon={
            <img src={PlayLogo} alt={"Google Play Logo"} className="size-4" />
          }
        />

        <Button
          btnWidth={"w-full"}
          variant={"black"}
          customClasses={"py-2.5"}
          label={"Download from App Store"}
          labelStyles={"text-sm font-semibold"}
          onClick={() =>
            window.open(authenticatorApps[selectedApp].appleLink, "_blank")
          }
          leftIcon={
            <img src={AppleLogo} alt={"Apple Store Logo"} className="size-4" />
          }
        />

        <button
          className="text-gray text-xs font-normal outline-none hover:underline focus:underline"
          onClick={() => setSelectedApp(null)}
        >
          Hide Options
        </button>
      </div>
    </div>
  );
}
