import { useEffect, useState } from "react";

import {
  BronzeAnimated,
  BronzeDefault,
  BronzeIcon,
  DiamondAnimated,
  DiamondDefault,
  DiamondIcon,
  GoldAnimated,
  GoldDefault,
  GoldIcon,
  PlatinumAnimated,
  PlatinumDefault,
  PlatinumIcon,
  SilverAnimated,
  SilverDefault,
  SilverIcon,
} from "assets";
import { Button, OverlaySpinner } from "components";
import { HiDownload } from "react-icons/hi";

// Levels and types
const levels = ["Diamond", "Platinum", "Gold", "Silver", "Bronze"];
const types = ["Animated", "Default", "Icon"];

// Descriptions for types
const descriptions = {
  Default: "The widely recognised badges to signify CyberCert certified.",
  Icon: "A minimal version of the badges that can be used for marketing purposes when appropriate.",
  Animated: "A shiny and classy animated version of the CyberCert badges.",
};

// Mapping of thumbnails based on level and type
const thumbnails = {
  Animated: {
    Bronze: BronzeAnimated,
    Silver: SilverAnimated,
    Gold: GoldAnimated,
    Platinum: PlatinumAnimated,
    Diamond: DiamondAnimated,
  },
  Default: {
    Bronze: BronzeDefault,
    Silver: SilverDefault,
    Gold: GoldDefault,
    Platinum: PlatinumDefault,
    Diamond: DiamondDefault,
  },
  Icon: {
    Bronze: BronzeIcon,
    Silver: SilverIcon,
    Gold: GoldIcon,
    Platinum: PlatinumIcon,
    Diamond: DiamondIcon,
  },
};

// Generate badge types using map
let badgeTypes = levels.flatMap((level) =>
  types.map((type) => ({
    level: level,
    type: `${level} ${type}`,
    description: descriptions[type],
    thumbnail: thumbnails[type][level],
    downloadLink: "", // add download links
  })),
);

export function ResourcesBadges({ resources }) {
  const [selectedBadge, setSelectedBadge] = useState(badgeTypes[0]);

  const handleBadgeClick = (badge) => {
    setSelectedBadge(badge);
  };

  useEffect(() => {
    badgeTypes = badgeTypes.map((badgeType) => ({
      ...badgeType,
      downloadLink:
        resources?.badges?.[badgeType?.level?.toLowerCase()]?.[
          badgeType?.type?.split(" ")[1]?.toLowerCase()
        ],
    }));
    setSelectedBadge(badgeTypes[0]);
  }, [resources]);

  return (
    <>
      {!resources ? (
        <OverlaySpinner />
      ) : (
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <p className="text-base font-bold">
              {selectedBadge ? selectedBadge.type : "Badges"}
            </p>
            <p className="text-gray w-full laptop:w-1/2">
              {selectedBadge
                ? selectedBadge.description
                : "Select a badge to see details."}
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
            <div className="flex flex-col gap-8">
              <div className="surface-50 relative flex h-[390px] items-center justify-center gap-8 rounded-lg p-8">
                {selectedBadge.downloadLink && (
                  <>
                    {selectedBadge.type.includes("Animated") ? (
                      <video
                        src={selectedBadge.downloadLink}
                        autoPlay
                        loop
                        muted
                        className="h-full w-[75%]"
                      />
                    ) : (
                      <img
                        src={selectedBadge.downloadLink}
                        alt={selectedBadge.type}
                        className="scale-50"
                      />
                    )}

                    <Button
                      variant="white"
                      label="Download"
                      customClasses="w-fit absolute bottom-4 left-4"
                      rightIcon={<HiDownload className="text-icons h-5 w-5" />}
                      onClick={() => {
                        window.open(selectedBadge?.downloadLink, "_blank");
                      }}
                    />
                  </>
                )}
              </div>

              <div className="flex flex-col gap-4">
                <p>
                  You have the option to download all badges at your
                  convenience. Please ensure the responsible use of our
                  resources, avoiding any unauthorized or illegal activities. We
                  reserve the right to take necessary actions to protect our
                  interests.
                </p>
                <Button
                  variant="white"
                  label="Download Pack"
                  customClasses="w-fit"
                  rightIcon={<HiDownload className="text-icons h-5 w-5" />}
                  onClick={() => {
                    !!resources &&
                      window.open(resources?.downloadPackUrl, "_blank");
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-8">
              <div className="flex h-fit w-fit flex-wrap gap-4 laptop:grid laptop:grid-cols-3">
                {badgeTypes.map((badge, index) => (
                  <>
                    {badge.downloadLink && (
                      <div
                        key={index}
                        className={`border-primary flex h-[80px] w-[150px] cursor-pointer items-center justify-center rounded-lg border ${
                          selectedBadge?.type === badge.type
                            ? "border-green-100 bg-green-50"
                            : "text-primary bg-white"
                        }`}
                        onClick={() => handleBadgeClick(badge)}
                      >
                        {badge.type.includes("Animated") ? (
                          <video
                            src={badge.downloadLink}
                            autoPlay
                            loop
                            muted
                            className="w-[40%]"
                          />
                        ) : (
                          <img
                            src={badge.downloadLink}
                            alt={badge.type}
                            className="w-[30%]"
                          />
                        )}
                      </div>
                    )}
                  </>
                ))}
              </div>

              {selectedBadge && (
                <p className="text-icons text-xs">
                  {selectedBadge.type} is selected.
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
