import { useEffect, useState } from "react";

import { updatePartnerDetails } from "api";
import { Button, OverlaySpinner, TextInput } from "components";
import { Alert, Modal } from "flowbite-react";
import { HiArrowLeft, HiCheck, HiExclamation, HiRefresh } from "react-icons/hi";
import { AuthService } from "services";
import {
  isEqual,
  validateCode,
  validateEmail,
  validateEmpty,
  validatePhoneNumber,
} from "utils";

const DEFAULT = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  role: "",
  error: {
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    role: false,
    code: false,
  },
  code: "",
};

const RESEND_TIME = 2 * 60; // 2 minutes * 60 seconds per minute

export function UpdateUserModal({
  isOpen,
  user,
  handleCloseModal = () => {},
  handleSync = async () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailUpdated, setIsEmailUpdated] = useState(false);
  const [err, setErr] = useState(null);
  const [info, setInfo] = useState(null);
  const [canResend, setCanResend] = useState(true);
  const [resendTimeLeft, setResendTimeLeft] = useState(RESEND_TIME);
  const [updatedUser, setUpdatedUser] = useState({});

  const areUsersSame = (user1, user2) => {
    return (
      isEqual(user1.id, user2.id) &&
      isEqual(user1.firstName, user2.firstName) &&
      isEqual(user1.lastName, user2.lastName) &&
      isEqual(user1.phone, user2.phone) &&
      isEqual(user1.role, user2.role) &&
      isEqual(user1.status, user2.status) &&
      isEqual(user1.email, user2.email)
    );
  };

  const handleBack = () => {
    setIsEmailUpdated(false);
    setUpdatedUser((prevUpdatedUser) => ({
      ...prevUpdatedUser,
      code: "",
      error: { ...prevUpdatedUser?.error, code: false },
    }));
  };

  const handleClose = () => {
    setIsEmailUpdated(false);
    setUpdatedUser({});
    handleCloseModal();
  };

  const handleInput = (event) => {
    setUpdatedUser((prevUpdatedUser) => ({
      ...prevUpdatedUser,
      [event?.target?.name]: event?.target?.value,
      error: {
        ...prevUpdatedUser.error,
        [event?.target?.name]: false,
      },
    }));
  };

  const handleResend = async () => {
    setIsLoading(true);

    let result = await AuthService.updateUserEmail(updatedUser?.email);
    if (!!result?.error) {
      setErr({ type: "server", msg: result?.error });
      setIsLoading(false);
      return;
    }

    setInfo({
      type: "success",
      msg: "Resend successful.  Your new code should arrive in your inbox shortly.",
    });

    setIsLoading(false);
    setCanResend(false);
    setResendTimeLeft(RESEND_TIME);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    let submitUser = {
      ...updatedUser,
      firstName: updatedUser?.firstName?.trim(),
      lastName: updatedUser?.lastName?.trim(),
      email: updatedUser?.email?.trim(),
      phone: updatedUser?.phone?.replace(/\s/g, ""),
      role: updatedUser?.role?.trim(),
    };

    let isInvalidField = false;
    if (!validateEmpty(submitUser?.firstName)) {
      submitUser.error.firstName = true;
      isInvalidField = true;
    }
    if (!validateEmpty(submitUser?.lastName)) {
      submitUser.error.lastName = true;
      isInvalidField = true;
    }
    if (
      !validateEmpty(submitUser?.email) ||
      !validateEmail(submitUser?.email)
    ) {
      submitUser.error.email = true;
      isInvalidField = true;
    }
    if (
      (!validateEmpty(submitUser?.phone) ||
        !validatePhoneNumber(submitUser?.phone)) &&
      !submitUser?.isAdmin
    ) {
      submitUser.error.phone = true;
      isInvalidField = true;
    }

    if (!!isInvalidField) {
      setErr({
        type: "field",
        msg: "You have entered an invalid field. Please check again.",
      });
      setUpdatedUser(submitUser);
      setIsLoading(false);
      return;
    }

    let result = await updatePartnerDetails({
      userData: {
        id: submitUser.id,
        firstName: submitUser.firstName,
        lastName: submitUser.lastName,
        phone: submitUser.phone,
        role: submitUser.role,
        status: submitUser.status,
      },
    });
    if (!!result?.error) {
      setErr({ type: "server", msg: result?.error });
      setIsLoading(false);
      return;
    }

    if (user?.email !== submitUser?.email) {
      result = await AuthService.updateUserEmail(submitUser?.email);
      if (!!result?.error) {
        setErr({ type: "server", msg: result?.error });
        setIsLoading(false);
        return;
      }
      setUpdatedUser(submitUser);
      setIsEmailUpdated(true);
    } else {
      handleSync();
      handleClose();
    }
    setIsLoading(false);
  };

  const handleVerify = async () => {
    setIsLoading(true);

    let submitUser = {
      ...updatedUser,
      code: updatedUser?.code?.replace(/\s/g, ""),
    };

    if (!validateEmpty(submitUser?.code) || !validateCode(submitUser?.code)) {
      submitUser.error.code = true;
      setIsLoading(false);
      setErr({
        type: "code",
        msg: "You have entered an invalid MFA code. Please try again.",
      });
      setUpdatedUser(submitUser);
      return;
    }

    const result = await AuthService.confirmUserEmailChange({
      code: submitUser?.code,
    });

    if (!!result?.error) {
      setErr({ type: "server", msg: result?.error });
    }

    handleSync();
    setIsLoading(false);
    handleClose();
  };

  useEffect(() => {
    if (
      updatedUser?.error &&
      Object?.values(updatedUser?.error)?.every((error) => !error)
    ) {
      setErr(null);
    }
  }, [updatedUser]);

  useEffect(() => {
    let timer;
    if (!canResend) {
      // Update the remaining time every second
      timer = setInterval(() => {
        setResendTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            return RESEND_TIME;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [canResend]);

  useEffect(() => {
    if (resendTimeLeft <= 0) {
      setCanResend(true);
    }
  }, [resendTimeLeft]);

  useEffect(() => {
    setUpdatedUser({ ...DEFAULT, ...user });
  }, [isOpen]);

  const formattedResendTime = `${Math.floor(resendTimeLeft / 60)}:${resendTimeLeft % 60 < 10 ? "0" : ""}${resendTimeLeft % 60}`;

  return (
    <>
      <Modal
        show={isOpen && !isEmailUpdated}
        size="2xl"
        position="center"
        onClose={handleClose}
      >
        <Modal.Header>Update User</Modal.Header>
        <Modal.Body>
          {!!isLoading && <OverlaySpinner />}

          <div className="flex flex-grow flex-col gap-4 self-stretch px-1">
            {!!err && (
              <Alert
                color="red"
                onDismiss={() => setErr(null)}
                icon={HiExclamation}
              >
                {err.msg}
              </Alert>
            )}

            <div className="flex flex-grow gap-4 self-stretch">
              <div className="flex flex-grow flex-col gap-2 self-stretch">
                <span className="text-gray-600">First Name</span>
                <TextInput
                  className="w-full"
                  color={updatedUser?.error?.firstName ? "failure" : "gray"}
                  // label="Optional"
                  name="firstName"
                  placeholder="Enter first name"
                  required
                  type="text"
                  value={updatedUser?.firstName}
                  onInput={handleInput}
                />
              </div>

              <div className="flex flex-grow flex-col gap-2 self-stretch">
                <span className="text-gray-600">Last Name</span>
                <TextInput
                  className="w-full"
                  color={updatedUser?.error?.lastName ? "failure" : "gray"}
                  // label="Optional"
                  name="lastName"
                  placeholder="Enter last name"
                  required
                  type="text"
                  value={updatedUser?.lastName}
                  onInput={handleInput}
                />
              </div>
            </div>

            {!updatedUser?.isSSO && (
              <div className="flex flex-grow flex-col gap-2 self-stretch">
                <span className="text-gray-600">Work Email</span>
                <TextInput
                  className="w-full"
                  color={updatedUser?.error?.email ? "failure" : "gray"}
                  disabled={!!user?.isAdmin}
                  name="email"
                  placeholder="Enter work email"
                  type="url"
                  value={updatedUser?.email}
                  onInput={handleInput}
                />
              </div>
            )}

            <div className="flex flex-grow flex-col gap-2 self-stretch">
              <span className="text-gray-600">Mobile</span>
              <TextInput
                className="w-full"
                color={updatedUser?.error?.phone ? "failure" : "gray"}
                disabled={!!user?.isAdmin}
                name="phone"
                placeholder="Enter mobile number"
                required
                type="tel"
                value={updatedUser?.phone}
                onInput={handleInput}
              />
            </div>

            <div className="flex flex-grow flex-col gap-2 self-stretch">
              <span className="text-gray-600">Role</span>
              <TextInput
                className="w-full"
                color={updatedUser?.error?.role ? "failure" : "gray"}
                label="Optional"
                name="role"
                placeholder="Enter user role"
                type="text"
                value={updatedUser?.role}
                onInput={handleInput}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            customClasses="w-fit"
            label="Submit"
            leftIcon={<HiCheck className="size-4" />}
            variant="blue"
            onClick={handleSubmit}
          />
        </Modal.Footer>
      </Modal>

      <Modal
        show={isOpen && isEmailUpdated}
        size="2xl"
        position="center"
        theme={{ header: { close: { base: "hidden" } } }}
        onClose={handleClose}
      >
        <Modal.Header>Verify your Email Address</Modal.Header>
        <Modal.Body>
          {!!isLoading && <OverlaySpinner />}

          <div className="flex flex-grow flex-col gap-4 self-stretch px-1">
            {!!err && (
              <Alert
                color="red"
                onDismiss={() => setErr(null)}
                icon={HiExclamation}
              >
                {err.msg}
              </Alert>
            )}

            {!!info && (
              <Alert
                color="green"
                onDismiss={() => setInfo(null)}
                icon={HiCheck}
              >
                {info.msg}
              </Alert>
            )}

            <div className="flex flex-grow flex-col gap-2 self-stretch">
              <span className="text-gray-600">Verify Code</span>
              <TextInput
                className="w-full"
                color={updatedUser?.error?.code ? "failure" : "gray"}
                name="code"
                placeholder="Enter your code"
                required
                type="tel"
                value={updatedUser?.code}
                onInput={handleInput}
              />
            </div>

            <span className="text-gray text-xs">
              We have sent an OTP code to the new email address you added for
              this account.
              <br />
              Enter the code here to verify and proceed with this action.
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex flex-grow flex-row items-center justify-between self-stretch">
            <div className="flex flex-row items-center gap-3 self-stretch">
              <Button
                customClasses="w-fit"
                label="Back"
                leftIcon={<HiArrowLeft className="size-4" />}
                variant="blue"
                onClick={handleBack}
              />
              <Button
                customClasses="w-fit"
                label="Verify"
                leftIcon={<HiCheck className="size-4" />}
                variant="blue"
                onClick={handleVerify}
              />
            </div>
            <Button
              customClasses="w-fit"
              disabled={!canResend}
              label={canResend ? "Resend" : `Resend in ${formattedResendTime}`}
              leftIcon={<HiRefresh className="size-4" />}
              variant={canResend ? "blue" : "disabled"}
              onClick={handleResend}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
