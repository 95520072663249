import { useState } from "react";

import { OverlaySpinner } from "components";

export function UpdateButton({
  btnContent = "Update",
  className = "",
  onClick = async () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <button
      className={`relative flex flex-row items-center rounded-full px-2 hover:opacity-70 focus:ring-2 focus:ring-blue-300 focus:ring-offset-2
                ${!!className ? " " + className : ""}`}
      onClick={handleClick}
    >
      {isLoading && <OverlaySpinner size="md" />}
      <span className="text-link text-sm font-medium">{btnContent}</span>
    </button>
  );
}
