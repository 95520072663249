import { fetchGAPAssessment } from "api";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const key = "partner-fetch-GAP-assessment";

export const useFetchGAPAssessments = () => {
  return useQuery({
    queryKey: [key],
    queryFn: () => fetchGAPAssessment({}),
    refetchOnMount: "always",
  });
};

export const useSyncFetchGAPAssessments = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ id, token }) => fetchGAPAssessment({ id, token }),
    onSuccess: (data) => {
      queryClient.setQueryData([key], () => data);
      // queryClient.invalidateQueries([key]);
    },
  });

  return async ({ id, token }) => {
    await mutation.mutateAsync({ id, token });
  };
};
