import { API } from "aws-amplify";

export const removeUserFromOrganisation = async ({ email, ABN }) => {
  let result;
  try {
    const myInit = {
      body: {
        email,
        ABN,
      },
    };
    result = await API.post("partner", "/user/remove", myInit);
    // console.log(result);
  } catch (error) {
    console.error(error);
  }
  return result;
};
