import { HiClock } from "react-icons/hi";

export function AssessmentTitle({ title, description, duration }) {
  return (
    <>
      <div className="flex w-full flex-col gap-2">
        <h3 className="text-lg font-semibold">{title}</h3>
        <p>{description}</p>
        <div className="flex flex-row items-center gap-1">
          <HiClock className="text-icons size-4" /> <p>{duration}</p>
        </div>
      </div>
    </>
  );
}
