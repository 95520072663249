import { BreadcrumbTheme } from "assets/themes/breadcrumb";
import { Breadcrumb } from "flowbite-react";

export function Breadcrumbs({ icon, items = {} }) {
  const lastItemIndex = Object.keys(items).length - 1;
  return (
    <>
      <Breadcrumb theme={BreadcrumbTheme}>
        {Object.entries(items).map((item, index) =>
          index === 0 ? (
            index < lastItemIndex ? (
              <Breadcrumb.Item key={index} icon={icon} href={item[1]}>
                {item[0]}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item key={index} icon={icon}>
                {item[0]}
              </Breadcrumb.Item>
            )
          ) : index < lastItemIndex ? (
            <Breadcrumb.Item key={index} href={item[1]}>
              {item[0]}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={index}>{item[0]}</Breadcrumb.Item>
          )
        )}
      </Breadcrumb>
    </>
  );
}
