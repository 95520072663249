import { useEffect, useState } from "react";

import { LoadingSpinner } from "components/spinners";
import { AuthService } from "services";

export function RequireAuth({ children, navigate = () => {} }) {
  const [check, setCheck] = useState(false);

  const checkAccountState = async () => {
    setCheck(false);
    const sess = await AuthService.checkLoginState();
    if (!sess) {
      // window.location.href = getLandingPage();
    } else {
      // authorised
    }
    setCheck(true);
  };

  useEffect(() => {
    checkAccountState();
  }, []);

  return <>{!!check ? <>{children}</> : <LoadingSpinner />}</>;
}
