import { fetchSuppliersWidgetData } from "api";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const key = "partner-fetch-suppliers";

export const useFetchSuppliersWidgetData = () => {
  return useQuery({
    queryKey: [key],
    queryFn: () => fetchSuppliersWidgetData({}),
  });
};

export const useSyncFetchSuppliersWidgetData = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ dashboard }) => fetchSuppliersWidgetData({ dashboard }),
    onSuccess: (data) => {
      queryClient.setQueryData([key], () => data);
    },
  });

  return async ({ dashboard }) => {
    await mutation.mutateAsync({ dashboard });
  };
};
