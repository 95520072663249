import React from "react";

import { HiCheck, HiChevronRight } from "react-icons/hi";

export const StepperIcon = ({ variant, animate }) => {
  switch (variant) {
    case "completed":
      return (
        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-100">
          <HiCheck className="size-5 text-green-500" />
        </div>
      );
    case "inprogress":
      return (
        <>
          <div className="relative flex h-6 w-6 items-center justify-center rounded-full bg-orange-100">
            <div className="size-4 rounded-full bg-orange-500" />
            {animate && (
              <div className="absolute h-4.5 w-4.5 animate-ping rounded-full ring-1 ring-orange-300" />
            )}
          </div>
        </>
      );
    case "notstarted":
      return (
        <div className="surface-white border-border-primary flex h-6 w-6 items-center justify-center rounded-full border">
          <HiChevronRight className="text-primary size-5" />
        </div>
      );
    default:
      return (
        <div className="surface-white border-border-primary flex h-6 w-6 items-center justify-center rounded-full border">
          <HiChevronRight className="text-primary size-5" />
        </div>
      );
  }
};
