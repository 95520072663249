import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function PartnerGapAssessmentDocs({ resourceLinks, title }) {
  return (
    <>
      <h2 className="text-2xl font-semibold">{title}</h2>
      {resourceLinks?.gapAssessment ? (
        <>
          <video className="w-full border border-gray-300" controls>
            <source src={resourceLinks?.gapAssessment} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </>
      ) : (
        <>
          <Spinner theme={SpinnerTheme} color="darkGray" />
        </>
      )}

      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-black">GAP Assessment</p>
        <p>
          The GAP assessment functionality lets you send a private survey to
          your clients to determine their appropriate CyberCert certification
          level. To begin, head to Assessments, GAP Assessments and generate a
          new GAP assessment. There are 5 options to choose from which range
          from “Diamond and below” down to “Bronze only”. Each option has a
          different scope of questions and the results will be used to recommend
          a certification level. To distribute the survey, copy the assessment
          link and send it to your clients. The client will be asked to enter
          the email address as listed on CyberCert and their name for reference.
          During the survey, the client will be asked to answer questions about
          their current cybersecurity posture. After the survey is completed,
          the results will be shown on the survey results page including their
          current account status and a recommended certification level.
        </p>
      </div>
    </>
  );
}
