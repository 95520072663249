export function FinishFooter({ description, children }) {
  return (
    <>
      <div className="flex flex-col gap-4 bg-green-50 p-8">
        <div className="text-lg font-semibold text-green-500">Finished</div>
        <div className="text-green-500">{description}</div>

        {children}
      </div>
    </>
  );
}
