import { useState } from "react";

import { openPartnerSpecialOfferCheckoutSession } from "api/functions/stripe/partner/offer/checkout";
import { Button } from "components/buttons";
import { OverlaySpinner } from "components/spinners";
import { useDashboard } from "contexts";
import { Modal } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { capitalize } from "services";
import { openZEWidget } from "utils";
import { LEVEL_PRICES, ROUTES } from "variables";

export function SelectOfferModal({
  isOpen = false,
  option = {},
  handleCloseModal = () => {},
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { dashboard } = useDashboard();

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      console.log(option?.name, dashboard?.route);
      await openPartnerSpecialOfferCheckoutSession({
        item: option?.name,
        dashboard: dashboard?.route,
      });
      setIsSuccess(true);
    } catch (error) {
      console.error("Checkout session failed", error);
    }

    setIsLoading(false);
  };

  return (
    <Modal
      show={isOpen}
      size="md"
      position="center"
      onClose={() => {
        if (isSuccess) {
          navigate(ROUTES.DASHBOARD, { state: { showOfferAlert: true } });
        }
        setIsSuccess(false);
        handleCloseModal();
      }}
    >
      {!isSuccess ? (
        <>
          <Modal.Header>Confirm Your Selection</Modal.Header>
          <Modal.Body>
            {!!isLoading && <OverlaySpinner />}

            <div className="flex flex-col gap-5 self-stretch">
              <span className="font-medium text-gray-500">
                You are about to select the {capitalize(option?.name)} Bundle
              </span>

              <div className="flex items-center gap-4 self-stretch">
                <img
                  src={option?.image}
                  className="pointer-events-none w-full"
                  alt="Option offer"
                />
              </div>

              <div className="flex flex-col gap-3">
                <p className="text-base font-bold">Included</p>

                {option?.details?.map((detail, index) => (
                  <div
                    key={index}
                    className="flex flex-row items-end justify-between border-b pb-2"
                  >
                    <div className="flex flex-col">
                      <span className="font-medium text-gray-500">
                        {detail?.label}
                      </span>

                      <div className="flex gap-2">
                        <span className="font-medium">$0</span>
                        <span className="text-red-700 line-through">
                          $
                          {
                            LEVEL_PRICES[
                              capitalize(
                                detail?.label?.split(" ")?.[0]?.toLowerCase(),
                              )
                            ]
                          }{" "}
                          per subscription
                        </span>
                      </div>
                    </div>

                    <span className="rounded-4xl bg-[#BCEF9B] px-2 py-1 text-sm font-bold text-[#1E3D2A]">
                      {detail?.value}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="w-full laptop:w-fit"
              label={"Continue"}
              leftIcon={<HiCheck className="size-4" />}
              variant={"blue"}
              onClick={handleSubmit}
            />
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header>
            You have successfully selected the {capitalize(option?.name)}{" "}
            Bundle!
          </Modal.Header>
          <Modal.Body>
            {!!isLoading && <OverlaySpinner />}

            <div className="flex flex-col gap-3.5 self-stretch">
              <span className="font-medium text-gray-500">
                Your subscriptions should be now credited in your dashboard.
              </span>

              <span className="font-medium text-gray-500">
                If you have any questions, please{" "}
                <button
                  className="text-blue-600 hover:opacity-70 focus:underline"
                  onClick={openZEWidget}
                >
                  contact us
                </button>
                .
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="w-full laptop:w-fit"
              label={"Go to Dashboard"}
              leftIcon={<HiCheck className="size-4" />}
              variant={"blue"}
              onClick={() =>
                navigate(ROUTES.DASHBOARD, { state: { showOfferAlert: true } })
              }
            />
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
