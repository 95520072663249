import { API } from "aws-amplify";

export const verifyPartnerAccount = async ({
  username,
  email,
  ABN,
  isActive,
  isSetup,
  specialOfferCode,
}) => {
  const myInit = {
    queryStringParameters: {
      username,
      email,
      ABN,
      isActive,
      isSetup,
      specialOfferCode,
    },
  };
  const result = await API.get("partner", "/verify", myInit);
  // console.log(result);
  return result;
};

export const verifyPartnerAccountPublic = async ({
  username,
  email,
  ABN,
  isActive,
  isSetup,
}) => {
  const myInit = {
    queryStringParameters: {
      username,
      email,
      ABN,
      isActive,
      isSetup,
    },
  };
  const result = await API.get("partner", "/verify-public", myInit);
  // console.log(result);
  return result;
};
