import { HiXCircle } from "react-icons/hi";

export function LogoUpload({ imgSrc, imgAlt, handleRemove = () => {} }) {
  return (
    <div className="border-primary relative flex w-fit self-stretch rounded-lg border-px px-1 py-2">
      <img src={imgSrc} alt={imgAlt} className="max-h-10" />

      <button
        onClick={handleRemove}
        className="absolute right-0 top-0 -translate-y-1/2 translate-x-1/2 rounded-full hover:opacity-80 focus:ring-2 focus:ring-gray-500"
      >
        <HiXCircle className="size-5 text-gray-400" />
      </button>
    </div>
  );
}
