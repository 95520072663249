import { useEffect, useState } from "react";

import { ProgressBarTheme } from "assets";
import { Button } from "components";
import { Alert, Progress } from "flowbite-react";
import { FaRedo } from "react-icons/fa";
import { HiArrowRight, HiCheck, HiCheckCircle } from "react-icons/hi";
import { useLocation, useNavigate } from "react-router-dom";
import { getFlatGAPAssessmentQuestions } from "services";
import { INITIAL_GAP_ASSESSMENTS, ROUTES } from "variables";

const resultDetails = [
  {
    // orgName: "Techno Vista",
    // abn: "12345678910",
    // certificationStatus: "nonCertified",
    // maxLevel: "GOLD",
    // qualified: "14",
    // total: "22",
    // qualifiedLevel: "BRONZE",
    // completedBy: "Jane Smith",
    // verifiedVia: "email",
    // verifiedData: "jsmith@acme.com",
    // completedOn: "14 Mar 2024, 5:11 PM",
  },
];

export function MainAssessment({ assessment }) {
  const { state } = useLocation();

  const [assessmentDetail, setAssessmentDetail] = useState();
  const [showAlert, setShowAlert] = useState(true);

  const handleDismiss = () => {
    setShowAlert(!showAlert);
  };

  useEffect(() => {
    if (!!assessment) {
      let progresses = assessment?.assessment?.questionnaire?.map((section) =>
        checkAnswersStatus(section, assessment?.assessmentResult?.answers),
      );
      setAssessmentDetail({
        name: INITIAL_GAP_ASSESSMENTS[
          INITIAL_GAP_ASSESSMENTS.length - assessment?.assessment?.maximumLevel
        ]?.level,
        totalQuestions: getFlatGAPAssessmentQuestions(
          assessment?.assessment?.questionnaire,
        )?.length,
        progresses,
      });
    }
  }, [assessment]);

  useEffect(() => {
    if (!!assessment) console.log("ASSESSMENT:", assessment);
  }, [assessment]);

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col gap-8 p-8">
        {showAlert && !state?.preview && (
          <Alert color={"green"} onDismiss={handleDismiss} icon={HiCheckCircle}>
            <div className="alert-flowbite flex flex-row items-center gap-3">
              <div className="text-base font-semibold">
                All cleared! You are now ready to begin the assessment.
              </div>
            </div>
          </Alert>
        )}

        <div className="flex flex-col items-center justify-between laptop:flex-row">
          <div className="text-primary text-xl font-bold">
            {assessmentDetail?.name}
          </div>
          <span className="text-gray text-sm font-semibold">
            {assessmentDetail?.totalQuestions} questions to complete
          </span>
        </div>

        <div className="flex flex-col gap-5">
          <AssessmentDetail
            title={`${assessmentDetail?.totalQuestions} questions to complete`}
            progress={assessmentDetail?.progresses[0]}
            assessment={assessment}
            section={1}
          />
        </div>
      </div>

      <div className="flex flex-col gap-0">
        <ActionAssessmentPanel assessment={assessment} />
        {/* <Footer /> */}
      </div>
    </div>
  );

  // <div className="flex h-full flex-col justify-between">
  //   <div className="flex flex-col gap-8 p-8">
  //     <div className="flex flex-col items-center gap-4 laptop:flex-row">
  //       <div className="text-primary text-xl font-bold">
  //         SMB1001 GAP Assessment
  //       </div>
  //       <StatusLabel variant={"completed"} label={"Completed"} animate />
  //     </div>

  //     <div className="surface-db-stepper flex flex-col gap-5 self-stretch rounded-lg border p-8">
  //       <div className="text-primary text-xl font-bold">
  //         <h2>{resultDetails[0].orgName}</h2>
  //         <p className="text-gray text-sm font-medium">
  //           ABN{" "}
  //           <span className="text-normal text-primary">
  //             {resultDetails[0].abn}
  //           </span>
  //         </p>
  //       </div>
  //       <span className="text-primary text-base font-bold">Results</span>

  //       <div className="flex flex-col gap-8 laptop:flex-row">
  //         <div className="flex w-full flex-col">
  //           <div>
  //             <DetailRow label={"Implemented requirements"}>
  //               <span className="label-completed-text">
  //                 {Math.min(
  //                   resultDetails[0].qualified,
  //                   resultDetails[0].total,
  //                 )}
  //               </span>{" "}
  //               / {resultDetails[0].total}
  //             </DetailRow>
  //             <DetailRow label={"Qualified level"}>
  //               <div className="flex flex-row items-center gap-2">
  //                 <DisplayLevelIcon level={resultDetails[0].qualifiedLevel} />
  //                 {resultDetails[0].qualifiedLevel}
  //               </div>
  //             </DetailRow>
  //             <DetailRow label={"Completed by"}>
  //               {resultDetails[0].completedBy}
  //             </DetailRow>
  //             <DetailRow label={"Verified via"}>
  //               <div className="flex flex-row items-center gap-2">
  //                 {resultDetails[0].verifiedVia &&
  //                   (() => {
  //                     switch (resultDetails[0].verifiedVia) {
  //                       case "email":
  //                         return (
  //                           <HiOutlineMail className="text-icons size-5" />
  //                         );
  //                       case "mobile":
  //                         return (
  //                           <HiOutlineDeviceMobile className="text-icons size-5" />
  //                         );
  //                       default:
  //                         return null;
  //                     }
  //                   })()}
  //                 {resultDetails[0].verifiedData}
  //               </div>
  //             </DetailRow>
  //             <DetailRow label={"Completed on"}>
  //               {resultDetails[0].completedOn}
  //             </DetailRow>
  //           </div>
  //         </div>
  //         <AssessmentsResultsPanel
  //           certificationStatus={"Non-Certified"}
  //           qualifiedLevel={resultDetails[0].qualifiedLevel}
  //         />
  //       </div>
  //     </div>

  //     <div className="flex flex-col items-center justify-between laptop:flex-row">
  //       <div className="text-primary text-xl font-bold">
  //         {assessmentDetail?.name}
  //       </div>
  //       <span className="text-gray text-sm font-semibold">
  //         {assessmentDetail?.totalQuestions} questions to complete
  //       </span>
  //     </div>

  //     <div className="flex flex-col gap-5">
  //       <AssessmentDetail
  //         key={0}
  //         title={`${assessmentDetail?.totalQuestions} questions to complete`}
  //         progress={assessmentDetail?.progresses[0]}
  //         assessmentCompleted={assessmentCompleted}
  //         assessment={assessment}
  //         section={1}
  //         previewLink={previewLink}
  //       />
  //     </div>
  //   </div>

  //   <ActionAssessmentPanel
  //     assessment={assessment}
  //     assessmentCompleted={assessmentCompleted}
  //     previewLink={previewLink}
  //   />
  //   {* <Footer /> *}
  // </div>
}

function AssessmentDetail({ title, progress, assessment, section }) {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-0">
      <button
        onClick={() => {
          navigate(
            ROUTES.ASSESSMENT_SESSION.replace(
              ":distribution",
              assessment?.assessment?.token,
            ).replace(":token", assessment?.assessmentResult?.session),
            { state },
          );
        }}
        className={`${
          progress === 100
            ? "flex flex-row items-center justify-between bg-green-50 p-4 hover:bg-green-100"
            : "surface-db-stepper flex flex-row items-center justify-between p-4 hover:bg-gray-100"
        }`}
      >
        <span className="text-primary text-base font-semibold">{title}</span>
        <HiCheck
          className={`size-5 ${
            progress === 100 ? "text-green-400" : "text-icons"
          }`}
        />
      </button>
      <Progress
        progress={progress}
        color={progress === 100 ? "green" : "inprogress"}
        size="xs"
        theme={ProgressBarTheme}
      />
    </div>
  );
}

function ActionAssessmentPanel({ assessmentCompleted, assessment }) {
  const { state } = useLocation();

  const navigate = useNavigate();

  return (
    <div className="border-primary surface-db-stepper flex w-full border-b border-t p-8">
      <Button
        customClasses={`w-fit laptop:flex`}
        leftIcon={
          assessmentCompleted ? (
            <FaRedo className="size-4" />
          ) : (
            <HiArrowRight className="size-4" />
          )
        }
        label={assessmentCompleted ? "Redo Assessment" : "Get Started"}
        labelStyles={"font-semibold text-sm"}
        variant={"blue"}
        onClick={() => {
          navigate(
            ROUTES.ASSESSMENT_SESSION.replace(
              ":distribution",
              assessment?.assessment?.token,
            ).replace(":token", assessment?.assessmentResult?.session),
            {
              state,
            },
          );
        }}
        animate={"btn-pulse hover:ring-4 hover:ring-blue-100"}
      />
    </div>
  );
}

const checkAnswersStatus = (section, answers) => {
  if (!answers) {
    return 0;
  }
  const totalQuestions = section.length * 1.0;
  if (!totalQuestions) return 0;
  let answeredQuestions = 0;
  section?.forEach((question) => {
    if (
      answers?.filter(
        (answer) => answer?.id === question?.id && !!answer?.value,
      ).length > 0
    ) {
      answeredQuestions++;
    }
  });
  return (answeredQuestions / totalQuestions) * 100;
};
