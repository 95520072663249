import { Stepper } from "components/stepper";
import { HiBadgeCheck } from "react-icons/hi";

export function SidebarProcess({
  title = "",
  hasHeading,
  steps = [],
  stepTitles = [],
}) {
  const processedSteps = Array.isArray(steps) ? steps : [];
  const processedStepTitles = Array.isArray(stepTitles) ? stepTitles : [];

  return (
    <div className="surface-nav border-primary min-w-[250px] border-b laptop:border-b-0 laptop:border-r">
      <div className="flex flex-col gap-5 self-stretch p-8">
        {!!hasHeading && (
          <div className="flex w-fit flex-col self-stretch pb-5 laptop:hidden">
            <div className="text-primary text-2xl font-bold">
              Welcome to CyberCert's Partner Program
            </div>
            <div className="text-gray flex items-center gap-2 text-base font-medium">
              <HiBadgeCheck className="size-5" />
              Partner Onboarding
            </div>
          </div>
        )}

        <Stepper
          title={title}
          steps={processedSteps}
          stepTitles={processedStepTitles}
        />
      </div>
    </div>
  );
}
