import React, { useEffect, useState } from "react";

import { sendPartnerNewAdminInvitation } from "api";
import { Button, OverlaySpinner, TextInput } from "components";
import { Alert, Modal } from "flowbite-react";
import { BiSolidErrorAlt } from "react-icons/bi";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { HiCheck, HiPlus, HiRefresh, HiX } from "react-icons/hi";
import { validateEmail, validateEmpty, validateMobile } from "utils";

const GET_INITIAL_USER = () => ({
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  error: {
    firstName: false,
    lastName: false,
    email: false,
    mobile: false,
  },
});

export function AddUsersModal({
  isOpen,
  handleCloseModal = () => {},
  handleSync = async () => {},
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [successAdds, setSuccessAdds] = useState();
  const [failedAdds, setFailedAdds] = useState();
  const [err, setErr] = useState(null);
  const [addUsers, setAddUsers] = useState([GET_INITIAL_USER()]);

  const handleAddNew = () => {
    setAddUsers((prevUsers) => [...prevUsers, GET_INITIAL_USER()]);
  };

  const handleClose = () => {
    setIsSuccess(false);
    setAddUsers([GET_INITIAL_USER()]);
    handleCloseModal(successAdds?.length > 0);
  };

  const handleDismissError = () => {
    setErr(null);
  };

  const handleInput = (event, index) => {
    event.preventDefault();

    const newAddUsers = [...addUsers];
    newAddUsers[index][event.target.name] = event.target.value;
    newAddUsers[index]["error"][event.target.name] = false;

    setAddUsers(newAddUsers);
  };

  const handlePaste = (event, index) => {
    event.preventDefault();

    const paste = event.clipboardData.getData("text").replace(/\s/g, "");
    if (/[0-9\s]/.test(paste)) {
      const newAddUsers = [...addUsers];

      const input = event.target;
      const startPos = input.selectionStart;
      const endPos = input.selectionEnd;
      const originalValue = newAddUsers[index][event.target.name];
      const value =
        originalValue.substring(0, startPos) +
        paste +
        originalValue.substring(endPos);
      input.setSelectionRange(startPos + paste.length, startPos + paste.length);

      newAddUsers[index][event.target.name] = value;
      newAddUsers[index].error[event.target.name] = false;
      newAddUsers[index].duplicate = false;

      setAddUsers(newAddUsers);
    }
  };

  const handleRemove = (target) => {
    if (addUsers?.length === 1) {
      setAddUsers([GET_INITIAL_USER()]);
    } else {
      setAddUsers((prevAddUsers) =>
        prevAddUsers.filter((_, index) => index !== target),
      );
    }
  };

  const handleRedo = () => {
    setAddUsers(
      failedAdds.map((user) => ({
        ...user,
        error: {
          firstName: false,
          lastName: false,
          email: user?.error?.toLowerCase()?.includes("email") ? true : false,
          mobile: user?.error?.toLowerCase()?.includes("mobile") ? true : false,
        },
      })),
    );
    setErr({
      type: "duplicate",
      msg: "You have submitted a duplicate email or mobile number. Please check again.",
    });
    setIsSuccess(false);
  };

  const handleSubmit = async () => {
    setIsModalLoading(true);

    let submitAddUsers = [
      ...addUsers?.map((user) => ({
        ...user,
        firstName: user?.firstName?.trim(),
        lastName: user?.lastName?.trim(),
        email: user?.email?.trim(),
        mobile: user?.mobile?.replace(/\s/g, ""),
      })),
    ];

    let isInvalidField = false;
    submitAddUsers.forEach((user) => {
      if (!validateEmpty(user?.firstName)) {
        user.error.firstName = true;
        isInvalidField = true;
      }
      if (!validateEmpty(user?.lastName)) {
        user.error.lastName = true;
        isInvalidField = true;
      }
      if (!validateEmpty(user?.email) || !validateEmail(user?.email)) {
        user.error.email = true;
        isInvalidField = true;
      }
      if (!validateEmpty(user?.mobile) || !validateMobile(user?.mobile)) {
        user.error.mobile = true;
        isInvalidField = true;
      }
    });

    if (!!isInvalidField) {
      setErr({
        type: "field",
        msg: "You have entered an invalid field. Please check again.",
      });
      setAddUsers(submitAddUsers);
      setIsModalLoading(false);
      return;
    }

    let result = await sendPartnerNewAdminInvitation({
      userDetails: (submitAddUsers || []).map((user) => ({
        userFirstName: user?.firstName,
        userLastName: user?.lastName,
        userEmail: user?.email,
        userPhone: user?.mobile,
        userRole: user?.role,
      })),
    });
    handleSync();

    setSuccessAdds(
      result?.errors
        ? addUsers?.filter((u) =>
            result?.errors?.every((r) => r?.userDetail?.userEmail !== u?.email),
          )
        : addUsers,
    );
    setFailedAdds(
      addUsers
        ?.map((u) => {
          const error = result?.errors?.find(
            (r) => r?.userDetail?.userEmail === u?.email,
          )?.message;
          return { ...u, error };
        })
        ?.filter((u) => !!u?.error),
    );

    setIsModalLoading(false);
    setIsSuccess(true);
  };

  useEffect(() => {
    if (
      !addUsers.some((user) =>
        Object.values(user.error).some((error) => !!error),
      )
    ) {
      setErr(null);
    }
  }, [addUsers]);

  return (
    <Modal
      show={isOpen}
      size={isSuccess ? "2xl" : "7xl"}
      position="center"
      onClose={handleClose}
    >
      <Modal.Header>Add Users</Modal.Header>
      <Modal.Body>
        {!!isModalLoading && <OverlaySpinner />}

        {isSuccess ? (
          <div className="flex flex-col gap-4 self-stretch overflow-x-scroll">
            {successAdds?.length > 0 && (
              <>
                <div className="flex flex-col gap-1 self-stretch">
                  <div className="flex items-center gap-1.5">
                    <HiCheck className="h-4.5 w-4.5 rounded-full bg-green-50 text-green-700" />
                    <span className="text-lg font-medium text-green-700">
                      Users created successfully!
                    </span>
                  </div>

                  <span className="text-base font-normal text-gray-500">
                    The following user have been successfully added:
                  </span>
                </div>

                <div className="grid px-4 tablet:grid-cols-[auto_1fr] tablet:gap-x-10 tablet:gap-y-4">
                  {successAdds?.map((user, index) => (
                    <React.Fragment key={index}>
                      <div className="flex flex-row items-center gap-2 self-stretch py-0.5">
                        <BsCheckCircleFill className="size-4 text-green-500" />
                        <span className="text-primary text-base font-normal">
                          {user?.firstName} {user?.lastName}
                          {!user?.firstName && !user?.lastName && (
                            <span className="text-gray-300">(No Name)</span>
                          )}
                        </span>
                      </div>

                      <div className="mb-4 ml-6 flex flex-row items-center gap-2 self-stretch py-0.5 last:mb-0 tablet:m-0">
                        <FiMail className="text-icons size-5 flex-shrink-0" />
                        <span className="text-base font-normal text-gray-500">
                          {user?.email}
                        </span>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}

            {failedAdds?.length > 0 && (
              <>
                <div className="flex flex-col gap-1 self-stretch">
                  <div className="flex items-center gap-1.5">
                    <HiX className="h-4.5 w-4.5 rounded-full bg-green-50 text-red-700" />
                    <span className="text-lg font-medium text-red-700">
                      User creation failed!
                    </span>
                  </div>

                  <span className="text-base font-normal text-gray-500">
                    The following users were not sucessfully added:
                  </span>
                </div>

                <div className="grid px-4 tablet:grid-cols-[auto_1fr] tablet:gap-x-10 tablet:gap-y-4">
                  {failedAdds?.map((user, index) => (
                    <React.Fragment key={index}>
                      <div className="flex flex-shrink-0 flex-row items-center gap-2 py-0.5">
                        <BsXCircleFill className="size-4 text-red-500" />
                        <span className="text-primary text-base font-normal">
                          {user?.firstName} {user?.lastName}
                          {!user?.firstName && !user?.lastName && (
                            <span className="text-gray-300">(No name)</span>
                          )}
                        </span>
                      </div>

                      <div className="mb-4 ml-6 flex flex-col self-stretch py-0.5 last:mb-0 tablet:m-0 tablet:ml-0">
                        <div className="flex flex-row items-center gap-2 self-stretch">
                          <FiMail className="text-icons size-5 flex-shrink-0" />
                          <span className="text-base font-normal text-gray-500">
                            {user?.email}
                          </span>
                        </div>

                        <span className="text-base font-normal text-red-500">
                          {user?.error}
                        </span>
                      </div>
                    </React.Fragment>
                  ))}
                </div>

                <Button
                  customClasses={"w-full laptop:w-fit mt-4"}
                  leftIcon={<HiRefresh className="size-4" />}
                  label={"Redo"}
                  variant={"blue"}
                  onClick={handleRedo}
                />
              </>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-5">
            {!!err && (
              <Alert
                color="red"
                onDismiss={handleDismissError}
                icon={BiSolidErrorAlt}
              >
                {err?.msg}
              </Alert>
            )}

            <div className="flex flex-col gap-8">
              <span className="text-primary text-sm font-normal">
                You can add more users to access your organisations’ CyberCert
                dashboard.
              </span>

              <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-4 self-stretch">
                  <div className="text-primary hidden px-1 text-sm font-medium laptop:flex laptop:gap-4">
                    <span className="invisible">{addUsers?.length}.</span>
                    <span className="ml-1 w-full">First Name</span>
                    <span className="ml-1 w-full">Last Name</span>
                    <span className="ml-1 w-full">Email</span>
                    <span className="ml-1 w-full">Mobile</span>
                    <span className="ml-1 w-full">Role</span>
                    <span className="w-5 flex-shrink-0"></span>
                  </div>

                  {addUsers?.map((user, index) => (
                    <AddUserRow
                      key={index}
                      user={user}
                      index={index}
                      handleInput={handleInput}
                      handlePaste={handlePaste}
                      handleRemove={handleRemove}
                    />
                  ))}
                </div>
              </div>

              <button
                className="btn-text ml-1 flex w-fit flex-row items-center gap-1 self-stretch rounded px-1
                          focus-visible:ring-2 focus-visible:ring-blue-300 focus-visible:ring-offset-2 enabled:hover:opacity-80"
                onClick={handleAddNew}
              >
                <HiPlus className="h-3.5 w-3.5" />
                <span className="text-sm font-semibold">Add New User</span>
              </button>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isSuccess && (
          <div className="flex flex-col gap-2">
            <Button
              leftIcon={<HiCheck className="size-4" />}
              label={"Submit"}
              variant={"blue"}
              onClick={handleSubmit}
            />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

function AddUserRow({
  user,
  index,
  handleInput = () => {},
  handleRemove = () => {},
}) {
  return (
    <div className="flex flex-col items-start gap-4 px-1 laptop:flex-row laptop:items-center">
      <div className="text-primary flex flex-row gap-1 text-sm font-medium">
        <span className="flex laptop:hidden">User</span> {index + 1}.
      </div>

      <TextInput
        autoComplete="off"
        color={!!user?.error?.["firstName"] ? "failure" : "gray"}
        // label="Optional"
        name="firstName"
        placeholder={`Enter first name`}
        required
        type="text"
        value={user?.firstName
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
        className={"w-full"}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        autoComplete="off"
        color={!!user?.error?.["lastName"] ? "failure" : "gray"}
        // label="Optional"
        name="lastName"
        placeholder={`Enter last name`}
        required
        type="tel"
        value={user?.lastName
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")}
        className={"w-full"}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        autoComplete="off"
        color={!!user?.error?.["email"] ? "failure" : "gray"}
        name="email"
        placeholder={`Enter email`}
        required
        type="email"
        className={"w-full"}
        value={user?.email}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        autoComplete="off"
        color={!!user?.error?.["mobile"] ? "failure" : "gray"}
        name="mobile"
        placeholder={`Enter mobile`}
        required
        type="tel"
        className={"w-full"}
        value={user?.mobile}
        onInput={(event) => handleInput(event, index)}
      />

      <TextInput
        autoComplete="off"
        color={!!user?.error?.["role"] ? "failure" : "gray"}
        label="Optional"
        name="role"
        placeholder={`Enter role`}
        type="text"
        className={"w-full"}
        value={user?.role}
        onInput={(event) => handleInput(event, index)}
      />

      <button
        className="rounded focus:ring-2 focus:ring-gray-400 enabled:hover:bg-gray-200"
        onClick={() => handleRemove(index)}
      >
        <HiX className="text-gray size-5" />
      </button>
    </div>
  );
}
