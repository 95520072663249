export const ModalTheme = {
  root: {
    base: "fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full font-inter",
    show: {
      on: "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80",
      off: "hidden",
    },
    sizes: {
      sm: "max-w-sm",
      md: "max-w-md",
      lg: "max-w-lg",
      xl: "max-w-xl",
      "2xl": "max-w-2xl",
      "3xl": "max-w-3xl",
      "4xl": "max-w-4xl",
      "5xl": "max-w-5xl",
      "6xl": "max-w-6xl",
      "7xl": "max-w-7xl",
    },
    positions: {
      "top-left": "items-start justify-start",
      "top-center": "items-start justify-center",
      "top-right": "items-start justify-end",
      "center-left": "items-center justify-start",
      center: "items-center justify-center",
      "center-right": "items-center justify-end",
      "bottom-right": "items-end justify-end",
      "bottom-center": "items-end justify-center",
      "bottom-left": "items-end justify-start",
    },
  },
  content: {
    base: "relative h-fit w-full p-4 md:h-auto",
    inner:
      "relative flex max-h-[90svh] flex-col rounded-lg bg-white shadow dark:bg-gray-700",
  },
  body: {
    base: "flex-1 overflow-auto mx-5 py-5",
    popup: "pt-0",
  },
  header: {
    base: "flex items-start justify-between rounded-t border-b mx-5 pt-5 pb-3 dark:border-gray-600",
    popup: "border-b-0 p-2",
    title: "text-lg font-semibold text-primary",
    close: {
      base: "ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
      icon: "size-5",
    },
  },
  footer: {
    base: "flex items-center space-x-2 rounded-b border-gray-200 mx-5 pb-5 dark:border-gray-600",
    popup: "",
  },
};

export const VideoModalTheme = {
  root: {
    base: "fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
    show: {
      on: "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80 backdrop-filter backdrop-blur-sm",
      off: "hidden",
    },
  },
  content: {
    base: "relative h-fit w-full p-4 md:h-auto",
    inner:
      "relative flex max-h-[90svh] flex-col rounded-lg bg-gradient-to-b from-neutral-300 from-10% via-white to-white shadow",
  },
  header: {
    base: "flex items-center justify-between rounded-t border-b mx-5 py-5 dark:border-gray-600",
    popup: "border-b-0",
    title: "text-lg font-semibold text-secondary",
    close: {
      base: "ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-secondary hover:bg-gray-800 hover:text-white focus:ring-2 focus:ring-gray-800",
      icon: "size-5",
    },
  },
};
