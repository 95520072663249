import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function LoadingSpinner() {
  return (
    <div className="flex flex-grow items-center justify-center self-stretch">
      <Spinner size="xl" theme={SpinnerTheme} color="darkGray" />
    </div>
  );
}
