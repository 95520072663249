import { API } from "aws-amplify";
import { detectBrowser, detectOS } from "utils";

export const pushLoginEvent = async () => {
  let result;
  try {
    let partnerInvite = sessionStorage.getItem(
      "ACCEPT_PARTNER_INVITE_BY_LOGIN"
    );
    sessionStorage.removeItem("ACCEPT_PARTNER_INVITE_BY_LOGIN");

    const myInit = {
      body: {
        partnerInvite,
        loginAt: new Date().toString(),
        loginDevice: `${detectOS()}, ${detectBrowser()}`,
        loginLocation: null,
      },
    };
    result = await API.post("user", "/event/login", myInit);
  } catch (e) {
    console.error(e);
  }
  // console.log(result);
  return result;
};
