import { cloneElement, useEffect, useState } from "react";

import { placeholderLogo } from "assets";
import { LoadingSpinner } from "components";
import { DashboardOptions, useDashboard } from "contexts";
import { useVerifyPartnerAccount } from "hooks";
import { Footer, Navbar } from "layouts";
import { FaCircle } from "react-icons/fa";
import { HiChevronRight, HiOfficeBuilding } from "react-icons/hi";
import { RiBookmarkFill, RiShieldFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { getAppPage } from "services";
import { ROUTES } from "variables";

export function SelectDashboardPage() {
  const navigate = useNavigate();
  const verifyPartnerState = useVerifyPartnerAccount();

  const { setDashboard } = useDashboard();

  const [isLoading, setIsLoading] = useState(true);

  const partnerLogoL = !verifyPartnerState?.data?.partner?.logo?.includes(
    "favicon"
  )
    ? verifyPartnerState?.data?.partner?.logo
    : null;

  const handleSelectDashboard = (value) => {
    navigate(ROUTES.DASHBOARD);
    setDashboard(value);
  };

  useEffect(() => {
    if (verifyPartnerState?.data?.result) {
      setIsLoading(false);
    }
  }, [verifyPartnerState?.data]);

  return !!isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <Navbar
        variant="primeSelectDashboard"
        primeLogo={
          verifyPartnerState?.data?.partner?.logoUrl ||
          verifyPartnerState?.data?.partner?.faviconUrl ||
          placeholderLogo
        }
        primeLogoL={partnerLogoL}
        orgName={verifyPartnerState?.data?.partner?.name || "CAP Partner"}
      />

      <div className="flex flex-grow flex-col justify-between">
        <div className="mx-auto flex w-full flex-col justify-start gap-8 px-8 laptop:max-w-[760px]">
          <div className="flex flex-col gap-2">
            <div className="text-primary mt-8 text-2xl font-bold">
              My Dashboards
            </div>
            {/* <PartnerInfo
              partnerLogo={
                verifyPartnerState?.data?.partner?.logoUrl ||
                verifyPartnerState?.data?.partner?.faviconUrl ||
                placeholderLogo
              }
              orgName={verifyPartnerState?.data?.partner?.name || "CAP Partner"}
            /> */}
          </div>

          <div className="panel-common flex w-full flex-col gap-5 p-5 shadow-sm tablet:p-8 laptop:w-[695px]">
            {/* <SearchInput
                id="search"
                type="text"
                name="search"
                placeholder="Search"
                icon={HiSearch}
                required
                shadow
                theme={SearchInputTheme}
                color="gray"
              /> */}

            <div className="text-primary text-sm font-bold">
              Available Dashboards
            </div>

            <div className="flex flex-col gap-0">
              {/* Link to certificat app goes first */}
              <DashboardLink
                icon={<RiBookmarkFill />}
                title="Certification"
                to={getAppPage()}
              />

              {/* Display accessible items second */}
              {DashboardOptions.filter(
                (item) => !!verifyPartnerState?.data?.partner?.[item?.check]
              ).map((item, index) => (
                <DashboardItem
                  key={index}
                  icon={item?.icon}
                  title={item?.title}
                  accessible={true}
                  onClick={() => handleSelectDashboard(item)}
                />
              ))}

              {/* Display inaccessible items last */}
              {DashboardOptions.filter(
                (item) => !verifyPartnerState?.data?.partner?.[item?.check]
              ).map((item, index) => (
                <DashboardItem
                  key={index}
                  icon={item?.icon}
                  title={item?.title}
                  accessible={false}
                  onClick={() => handleSelectDashboard(item)}
                />
              ))}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export function PartnerInfo({ partnerLogo, orgName }) {
  return (
    <div className="flex items-center gap-2">
      {partnerLogo?.includes("favicon") ? (
        <>
          <div className="border-surface-primary m-0 flex aspect-square w-7.5 items-center justify-center overflow-hidden rounded-full border object-cover p-0">
            {!!partnerLogo ? (
              <img
                src={partnerLogo}
                alt={`${orgName} Logo`}
                className="aspect-square w-full scale-110 overflow-hidden object-cover"
              />
            ) : (
              <HiOfficeBuilding className="text-gray size-7.5" />
            )}
          </div>
          <span className="text-gray font-medium">{orgName}</span>
        </>
      ) : (
        <>
          <img src={partnerLogo} alt="Large Partner Logo" className="h-7.5" />
        </>
      )}
    </div>
  );
}

export function DashboardLink({ icon, title, to }) {
  return (
    <Link
      className="border-primary flex flex-col tablet:flex-row items-center justify-between gap-2 tablet:gap-4 border-b bg-gray-50 p-3 font-semibold hover:border-b hover:border-blue-600 hover:bg-blue-100 hover:font-bold"
      to={to}
    >
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row items-center gap-3">
          <DashboardCircleIcon icon={icon} />

          <span className="text-secondary hover:text-primary text-sm tablet:text-base">
            {title}
          </span>
        </div>

        <div className="flex flex-row items-center gap-3">
          <span className="hidden tablet:flex w-fit text-icons hover:text-primary text-xs font-normal">
            {"View and manage your organisation’s CyberCert Certifications."}
          </span>
          <div className="surface-white border-primary size-6.5 flex items-center justify-center rounded-full border">
            <HiChevronRight className="text-primary size-5" />
          </div>
        </div>
      </div>

      <div className="flex tablet:hidden w-full">
        <span className="flex flex-col w-full text-icons text-xs font-normal items-start">
          {"View and manage your organisation’s CyberCert Certifications."}
        </span>
      </div>
    </Link>
  );
}

export function DashboardItem({ icon, title, accessible, onClick = () => {} }) {
  return accessible ? (
    <button
      className="border-primary flex flex-col tablet:flex-row items-center justify-between gap-2 tablet:gap-4 border-b bg-gray-50 p-3 font-semibold hover:border-b hover:border-blue-600 hover:bg-blue-100 hover:font-bold"
      onClick={onClick}
    >
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-row items-center gap-3">
          <DashboardShieldIcon icon={icon} />
          <span className="text-secondary hover:text-primary text-sm tablet:text-base">
            {title}
          </span>
        </div>

        <div className="flex flex-row items-center gap-3">
          <span className="hidden tablet:flex w-fit text-icons hover:text-primary text-xs font-normal">
            {"View and manage your Cyber Assurance Program."}
          </span>
          <div className="surface-white border-primary size-6.5 flex items-center justify-center rounded-full border">
            <HiChevronRight className="text-primary size-5" />
          </div>
        </div>
      </div>

      <div className="flex tablet:hidden w-full">
        <span className="flex flex-col w-full text-icons text-xs font-normal items-start">
          {"View and manage your Cyber Assurance Program."}
        </span>
      </div>
    </button>
  ) : (
    <>
      {title === "Supply Chain" && (
        <>
          {/* <div className="surface-50 border-primary flex flex-row items-center justify-between gap-4 border-b p-3">
            <div className="flex flex-row items-center gap-3">
              <DashboardIcon icon={icon} />
              <div className="flex flex-col">
                <span className="text-gray text-base font-medium">{title}</span>
              </div>
            </div>

            <Button
              label={"Request Access"}
              variant={"white"}
              onClick={openZEWidget}
            />
          </div> */}
        </>
      )}
    </>
  );
}

export function DashboardCircleIcon({ icon }) {
  if (!icon) {
    console.error("No icon supplied to component!");
    return;
  }

  icon = !!icon ? (
    cloneElement(icon, {
      className:
        "absolute left-1/2 top-1/2 h-4.5 w-4.5 -translate-x-1/2 -translate-y-1/2 text-white",
    })
  ) : (
    <></>
  );

  return (
    <div className="relative flex h-9 w-9 items-center justify-center">
      <FaCircle className="text-primary h-full w-full p-0.5" />
      {icon}
    </div>
  );
}

export function DashboardShieldIcon({ icon }) {
  if (!icon) {
    console.error("No icon supplied to component!");
    return;
  }

  icon = !!icon ? (
    cloneElement(icon, {
      className:
        "absolute left-1/2 top-1/2 h-4.5 w-4.5 -translate-x-1/2 -translate-y-[calc(50%+1px)] text-white",
    })
  ) : (
    <></>
  );

  return (
    <div className="relative flex h-9 w-9 items-center justify-center">
      <RiShieldFill className="text-primary h-full w-full" />
      {icon}
    </div>
  );
}
