export function HuntressOfferAlert({ offer, button, pulse }) {
  return (
    <>
      {!!offer && (
        <div
          className={`flex flex-col justify-between gap-8 rounded-lg bg-sky-50 p-5 laptop:flex-row laptop:items-center laptop:gap-0 
                    ${pulse && "btn-pulse-sky"}`}
        >
          <div className="flex flex-col-reverse gap-4 laptop:flex-row laptop:items-center">
            <img
              src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/partners/huntress-offer-logo.png"
              alt="Huntress Logo"
              className="size-16 rounded-full"
            />

            <div className="flex flex-col gap-2">
              <div className="flex w-fit flex-row items-center gap-3 rounded-lg bg-sky-200 px-4 py-3 laptop:gap-1 laptop:px-2 laptop:py-1">
                <div className="relative flex h-3 w-3 items-center justify-center rounded-full bg-sky-300">
                  <div className="h-1.5 w-1.5 rounded-full bg-sky-600" />
                  <div className="absolute h-1.5 w-1.5 animate-ping rounded-full ring-2 ring-sky-400" />
                </div>

                <span className="text-base font-bold text-sky-600">
                  EXCLUSIVE OFFER FOR NEW PARTNERS
                </span>
              </div>

              <p className="text-base text-sky-700">
                Kickstart your journey with a complementary subscription bundle
                from Huntress!
              </p>
            </div>
          </div>
          {button}
        </div>
      )}
    </>
  );
}
