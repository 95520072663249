export const INITIAL_GAP_ASSESSMENTS = [
  {
    level: "DIAMOND and below",
    dashboardType: "Supply Chain",
    dashboardTypeIcon: "Supply Chain",
    dashboardRoute: "supply-chain",
    token: null,
    questions: "39",
    status: "notstarted",
    levelId: 5,
  },
  {
    level: "PLATINUM and below",
    dashboardType: "Supply Chain",
    dashboardTypeIcon: "Supply Chain",
    dashboardRoute: "supply-chain",
    token: null,
    questions: "32",
    status: "notstarted",
    levelId: 4,
  },
  {
    level: "GOLD and below",
    dashboardType: "Supply Chain",
    dashboardTypeIcon: "Supply Chain",
    dashboardRoute: "supply-chain",
    token: null,
    questions: "24",
    status: "notstarted",
    levelId: 3,
  },
  {
    level: "SILVER and below",
    dashboardType: "Supply Chain",
    dashboardTypeIcon: "Supply Chain",
    dashboardRoute: "supply-chain",
    token: null,
    questions: "14",
    status: "notstarted",
    levelId: 2,
  },
  {
    level: "BRONZE only",
    dashboardType: "Supply Chain",
    dashboardTypeIcon: "Supply Chain",
    dashboardRoute: "supply-chain",
    token: null,
    questions: "6",
    status: "notstarted",
    levelId: 1,
  },
];
