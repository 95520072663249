import React from "react";
import { Modal } from "react-bootstrap";

export function FullCertificationProcessDocs() {
  return (
    <>
      <h2 className="text-2xl font-semibold">
        How to Get CyberCert Certified: A Full Walkthrough
      </h2>
      <Modal.Body>
        <video
          className="w-full border border-gray-300"
          src="https://cybercert-public.s3.ap-southeast-2.amazonaws.com/videos/resources/Cert+App+Demo+Video+Full.mp4"
          controls
          playsInline
        ></video>
      </Modal.Body>
      <div className="panel-common gap-4 text-gray">
        <p className="font-medium text-lg text-black">Welcome to CyberCert.</p>
        <p>
          The purpose of this video is to guide you through the CyberCert
          certification process so your organisation can quickly and easily get
          cyber certified.
        </p>
        <p>
          For this demonstration account, we have purchased a bronze level
          certification, registered, logged in, and are now ready to commence.
        </p>
        <p>Let’s get started!</p>

        <p className="font-medium text-black">Workbook & Worksheet</p>
        <p>
          Your first step in the certification process is to download your
          workbook.
        </p>
        <p>
          It contains all the instructions and certification requirements that
          you need to have in place in your business to qualify for your
          certification.
        </p>
        <p>
          You can use the workbook as a tool to record and document your
          implementation of each requirement.
        </p>
        <p>
          Once all of the requirements are in place in your organisation, press
          "next step" to continue to step 2.
        </p>

        <p className="font-medium text-black">Attesting to Requrements</p>

        <p>
          Step 2 is where you confirm you have implemented all of the
          certification requirements in your organisation.
        </p>
        <p>
          Go through each requirement one by one and tick them to confirm they
          have been implemented.
        </p>
        <p>
          To certify, you must implement each requirement, unless the not
          applicable option is available. Enter the name of the person who is
          attesting to the implementation of each requirement and press "next
          requirement" to continue.
        </p>
        <p>
          Once all the requirements have been ticked off and you have reviewed
          the final checklist, click confirm to move to Step 3.
        </p>

        <p className="font-medium text-black">Create the Attestation Letter</p>

        <p>Step 3 requires you to generate and sign your attestation letter.</p>
        <p>
          The attestation letter is a formal document that provides written
          confirmation or verification that all of the requirements have been
          implemented in your organisation.
        </p>
        <p>
          This letter needs to be signed off by an owner or director of your
          organisation and affirms that you have implemented the necessary
          requirements to receive your certification.
        </p>
        <p>
          Add their details in this section and the letter will be sent to them
          for signing to the email address entered.
        </p>

        <p className="font-medium text-black">Sign the Attestation Letter</p>

        <p>
          Now that the attestation letter has been generated and sent, check
          your inbox.
        </p>
        <p>
          It usually arrives within a few minutes. If the letter was sent to
          someone other than yourself, be sure to let them know to look out for
          it.
        </p>
        <p>
          Once the attestation letter is signed digitally, that will conclude
          the certification process.
        </p>

        <p className="font-medium text-black">Completing your Certification</p>

        <p>
          Congratulations, now that the attestation letter is signed your
          organisation is now officially cyber certified!
        </p>
        <p>
          You can view the signed attestation letter and your new certification
          directly from the dashboard.
        </p>
        <p>You can also generate a Credly badge for sharing on social media.</p>
        <p>
          Congratulations, you're now a CyberCert-certified organisation! Stay
          secure and share your certification with pride.
        </p>
      </div>
    </>
  );
}
