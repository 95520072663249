import { Button } from "components";
import { DashboardOptions, useDashboard } from "contexts";
import { useVerifyPartnerAccount } from "hooks";
import { HiCheck } from "react-icons/hi";

export function SetupAssessment({
  assessmentSetup,
  handleChange = () => { },
  handleNextStep = () => { },
}) {
  const { dashboard } = useDashboard();

  const verifyPartnerState = useVerifyPartnerAccount();

  return (
    <>
      <div className="flex flex-col gap-8 p-8">
        <div className="text-primary w-fit text-lg font-bold">
          <span>Setup details for this assessment</span>
        </div>

        <div className="flex w-full flex-col gap-8 self-stretch text-sm font-medium laptop:w-[50%]">
          <div className="flex w-full flex-col gap-4 self-stretch">
            Assessment maximum level
            <select
              className="surface-border-primary block w-full rounded-md border border-gray-300 p-3 text-base font-medium shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
              disabled
              name="maximumLevel"
              value={assessmentSetup?.maximumLevel}
              onChange={handleChange}
            >
              <optgroup label="Select Maximum Level Type">
                <option key="maximum-level-1" value={1}>
                  Bronze
                </option>
                <option key="maximum-level-2" value={2}>
                  Silver
                </option>
                <option key="maximum-level-3" value={3}>
                  Gold
                </option>
                <option key="maximum-level-4" value={4}>
                  Platinum
                </option>
                <option key="maximum-level-5" value={5}>
                  Diamond
                </option>
              </optgroup>
            </select>
            <span className="text-xs">
              The maximum level will limit the assessment to only check the {" "}
              {dashboard?.orgPlural} up to the selected certification.
            </span>
          </div>

          <div className="flex w-full flex-col gap-2 self-stretch">
            Assign dashboard
            <div className="w-full">
              <select
                className="block w-full rounded-md border border-gray-300 bg-white p-3 text-base font-medium shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500"
                disabled
                name="dashboard"
                value={assessmentSetup?.dashboard}
                onChange={handleChange}
              >
                <optgroup label="Select Dashboard Type">
                  {DashboardOptions.filter(
                    (option) =>
                      !!verifyPartnerState?.data?.partner?.[option?.check],
                  ).map((option, index) => (
                    <option key={index} value={option?.route}>
                      {option.title}
                    </option>
                  ))}
                </optgroup>
              </select>
            </div>
          </div>
        </div>

        <div className="flex gap-3 self-stretch">
          <Button
            customClasses={`w-full laptop:w-fit`}
            leftIcon={<HiCheck className="size-4" />}
            label={"Next Step"}
            labelStyles={"font-semibold text-sm"}
            variant={"blue"}
            onClick={handleNextStep}
          />
        </div>
      </div>
    </>
  );
}
