export function Footer() {
  return (
    <>
      <div className="flex flex-col justify-between gap-4 p-8 pt-16 text-xs laptop:flex-row">
        <div className="flex flex-col gap-1 whitespace-nowrap laptop:gap-3 desktop:flex-row">
          <div className="text-sub flex text-sm font-bold">
            CyberCert Pty Ltd.
          </div>
          <div className="flex flex-col justify-between gap-1 laptop:pt-[3px] desktop:flex-row">
            <span className="text-icons font-normal">ABN 87 662 681 423.</span>
            <span className="text-icons font-normal">
              © Copyright 2024. All rights reserved.
            </span>
          </div>
        </div>

        <div className="text-icons m-0 flex flex-col gap-2 self-stretch whitespace-nowrap pr-0 font-normal tablet:flex-row tablet:items-end laptop:gap-5 laptop:pr-[117px]">
          <a
            className="hover:text-primary hover:font-semibold"
            href="https://www.cybercert.ai/en-au/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <a
            className="hover:text-primary hover:font-semibold"
            href="https://www.cybercert.ai/en-au/terms"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          <a
            className="hover:text-primary hover:font-semibold"
            href="https://www.cybercert.ai/en-au/cookie-policy"
            target="_blank"
            rel="noreferrer"
          >
            Cookie Notice
          </a>
        </div>
      </div>
    </>
  );
}
