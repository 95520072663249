import { verifyPartnerAccount } from "api";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const key = "partner-verify";

export const useVerifyPartnerAccount = () => {
  return useQuery({
    queryKey: [key],
    queryFn: () => verifyPartnerAccount({}),
  });
};

export const useSyncVerifyPartnerAccount = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => verifyPartnerAccount({}),
    onSuccess: (verify) => {
      queryClient.setQueryData([key], () => verify);
    },
  });

  return async () => {
    await mutation.mutateAsync({});
  };
};
