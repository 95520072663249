import { useEffect, useState } from "react";

import {
  BronzeSampleAttestation,
  BronzeSampleCertificate,
  GoldSampleAttestation,
  GoldSampleCertificate,
  SilverSampleAttestation,
  SilverSampleCertificate,
} from "assets";
import { OverlaySpinner } from "components";

// Levels and types
const levels = ["Gold", "Silver", "Bronze"];
const types = ["Certificate", "Attestation"];

// Mapping of thumbnails based on level and type
const thumbnails = {
  Certificate: {
    Bronze: BronzeSampleCertificate,
    Silver: SilverSampleCertificate,
    Gold: GoldSampleCertificate,
  },
  Attestation: {
    Bronze: BronzeSampleAttestation,
    Silver: SilverSampleAttestation,
    Gold: GoldSampleAttestation,
  },
};

export function ResourcesCertificates({ resources }) {
  const [certTypes, setCertTypes] = useState();
  const [pdfLinks, setPdfLinks] = useState();
  const [selectedCertificate, setselectedCertificate] = useState();

  useEffect(() => {
    let updatedPdfLinks = {
      Certificate: {
        Bronze: resources?.Certificate?.Bronze,
        Silver: resources?.Certificate?.Silver,
        Gold: resources?.Certificate?.Gold,
      },
      Attestation: {
        Bronze: resources?.Attestation?.Bronze,
        Silver: resources?.Attestation?.Silver,
        Gold: resources?.Attestation?.Gold,
      },
    };
    let updatedCertTypes = levels.flatMap((level) =>
      types?.map((type) => ({
        level: level,
        type: `${level} ${type}`,
        thumbnail: thumbnails[type][level],
        pdfLink: updatedPdfLinks?.[type]?.[level], // add PDF links
        // downloadLink: pdfLinks[type][level], // add download links
      })),
    );
    setPdfLinks(updatedPdfLinks);
    setCertTypes(updatedCertTypes);
    setselectedCertificate(updatedCertTypes[0]);
  }, [resources]);

  const handleCertClick = (cert) => {
    setselectedCertificate(cert);
  };

  return (
    <>
      {!resources ? (
        <OverlaySpinner />
      ) : (
        <section className="flex flex-col gap-8">
          <div className="flex flex-col gap-2">
            <p className="text-base font-bold">Sample</p>
            <p className="text-gray w-full laptop:w-1/2">
              This section includes sample certification documents and
              attestation letters to show other companies what completed
              documents look like. Note that these samples are invalid and
              cannot be used for certification verification.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 laptop:grid-cols-2">
            <iframe
              src={selectedCertificate?.pdfLink}
              title={`Sample ${selectedCertificate?.type}`}
              className="border rounded-sm w-full h-[380px] laptop:h-[800px]"
            />
            {/* <div className="surface-50 h-[390px] flex items-center justify-center p-8 relative rounded-lg gap-8">
            <img
              src={selectedCertificate.thumbnail}
              alt={selectedCertificate.type}
              className="scale-50"
            />

            <Button
              variant="white"
              label="Download"
              customClasses="w-fit absolute bottom-4 left-4"
              rightIcon={<HiDownload className="w-5 h-5 text-icons" />}
              onClick={onClick}
            />
          </div> */}
            <div className="flex flex-col gap-8">
              <div className="flex h-fit w-fit flex-wrap gap-4 laptop:grid laptop:grid-cols-2">
                {certTypes?.map((cert, index) => (
                  <div
                    key={index}
                    className={`border-primary flex h-[80px] w-[150px] cursor-pointer items-center justify-center rounded-lg border ${
                      selectedCertificate?.type === cert.type
                        ? "border-green-100 bg-green-50"
                        : "text-primary bg-white"
                    }`}
                    onClick={() => handleCertClick(cert)}
                  >
                    <img
                      src={cert.thumbnail}
                      alt={cert.type}
                      className="w-[30%] shadow"
                    />
                  </div>
                ))}
              </div>

              {selectedCertificate && (
                <p className="text-icons text-xs">
                  Sample {selectedCertificate?.type} is selected.
                </p>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
}
