import "./App.css";

import { Router } from "routes";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useState } from "react";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    const setFavicon = () => {
      const faviconLink = document.querySelector("link[rel='icon']");
      const appleTouchIconLink = document.querySelector("link[rel='apple-touch-icon']");

      if (isDarkMode) {
        faviconLink.href = `${process.env.PUBLIC_URL}/favicon32.png`;
        appleTouchIconLink.href = `${process.env.PUBLIC_URL}/logo192.png`;
      } else {
        faviconLink.href = `${process.env.PUBLIC_URL}/favicon32-dark.png`;
        appleTouchIconLink.href = `${process.env.PUBLIC_URL}/logo192-dark.png`;
      }
    };

    setFavicon();

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setIsDarkMode(e.matches);
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, [isDarkMode]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: 1,
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </>
  );
}

export default App;
