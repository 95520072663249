import React, { useState } from "react";

import { openPartnerSpecialOfferCheckoutSession } from "api/functions/stripe/partner/offer/checkout";
import { SpinnerTheme } from "assets";
import { useDashboard } from "contexts";
import { Spinner } from "flowbite-react";
import { capitalize } from "services";
import { LEVEL_PRICES } from "variables";

export function PricingOfferCard({
  index,
  title,
  size,
  image,
  details,
  price,
  name,
  label,
  zero = false,
  handleSelectOffer = () => {},
}) {
  const { dashboard } = useDashboard();
  const [isLoading, setIsLoading] = useState(false);

  const handleBuyNow = async () => {
    setIsLoading(true);
    try {
      await openPartnerSpecialOfferCheckoutSession({
        item: name,
        dashboard: dashboard?.route,
      });
    } catch (error) {
      console.error("Checkout session failed", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="border-primary flex w-full flex-col justify-between gap-8 rounded-lg border bg-gradient-to-b from-[#f3f3f3] from-10% via-white to-white p-5">
      <div className="flex flex-col self-stretch">
        <span className="text-primary font-semibold uppercase leading-none">
          {title}
        </span>
        <span className="text-2xl font-bold">{size}</span>
      </div>

      <div className="-mb-7.5 flex w-full items-center justify-center">
        <img
          src={image}
          className="pointer-events-none w-[320px] laptop:w-full"
          alt="Option offer"
        />
      </div>

      <div className={`flex flex-col${zero ? " gap-8" : ""}`}>
        <div className="flex flex-col gap-3">
          <p className="text-base font-bold">Included</p>

          {details.map((detail, index) => (
            <div
              key={index}
              className="flex flex-row items-end justify-between border-b pb-2"
            >
              <div className="flex flex-col">
                <span className="font-medium text-gray-500">
                  {detail.label}
                </span>

                {zero && (
                  <div className="flex gap-2">
                    <span className="font-medium">$0</span>
                    <span className="text-red-700 line-through">
                      $
                      {
                        LEVEL_PRICES[
                          capitalize(
                            detail?.label?.split(" ")?.[0]?.toLowerCase(),
                          )
                        ]
                      }{" "}
                      per subscription
                    </span>
                  </div>
                )}
              </div>

              <span className="rounded-4xl bg-[#BCEF9B] px-2 py-1 text-sm font-bold text-[#1E3D2A]">
                {detail.value}
              </span>
            </div>
          ))}
        </div>

        {!zero && (
          <div className="mt-4 flex flex-row items-center gap-2">
            <span className="text-[34px] font-bold">{price}</span>
            <span className="text-gray text-base"> + GST </span>
          </div>
        )}

        <button
          className="mt-2 flex w-full items-center justify-center gap-3 rounded-lg bg-black p-2 text-base font-bold text-white hover:bg-gray-900 focus:ring-4 focus:ring-blue-400"
          onClick={!zero ? handleBuyNow : () => handleSelectOffer(index)}
          disabled={isLoading}
        >
          {isLoading && (
            <Spinner
              size={"md"}
              color={"blue"}
              theme={SpinnerTheme}
              className="ml-2"
            />
          )}
          {!!label ? label : "Buy Now"}
        </button>
      </div>
    </div>
  );
}
