import { SpinnerTheme } from "assets";
import { Spinner } from "flowbite-react";

export function PageSpinner() {
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-full w-full grow items-center justify-center self-stretch bg-white">
      <Spinner size="xl" theme={SpinnerTheme} color="darkGray" />
    </div>
  );
}
