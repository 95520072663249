// useful for all instances of table like label and value format

export function DetailRow({ label, children }) {
  return (
    <>
      <div className="border-primary -mb-[1px] flex flex-col tablet:flex-row items-center gap-2 self-stretch border-b-[1px] py-2 text-sm">
        <span className="text-gray w-full">{label}</span>
        <span className="text-primary w-full font-medium">{children}</span>
      </div>
    </>
  );
}

export function DetailRowGrid({ children, className }) {
  return (
    <div
      className={`grid grid-cols-[auto_1fr] py-2${!!className ? " " + className : ""}`}
    >
      {children}
    </div>
  );
}
