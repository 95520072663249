import {
  useEffect,
  useState,
} from "react";

import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import {
  BulkPurchaseCard,
  DiscountOffersInsightWidget,
  InsightsCertCreditsTable,
  PageSpinner,
  SubscriptionActivityTable,
  SubscriptionsInsightsWidget,
} from "components";
import { Breadcrumbs } from "components/flowbite";
import { useDashboard } from "contexts";
import { Alert } from "flowbite-react";
import {
  useFetchSuppliersWidgetData,
  useSyncFetchSuppliersWidgetData,
  useVerifyPartnerAccount,
} from "hooks";
import { PageHeader } from "layouts";
import {
  HiChartPie,
  HiCheckCircle,
  HiExclamationCircle,
} from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import {
  getLandingPage,
  setupDashboardData,
} from "services";
import { isExpired } from "utils";
import { ROUTES } from "variables";

export function SubscriptionsPage() {
  const { dashboard } = useDashboard();

  const verifyPartnerState = useVerifyPartnerAccount();
  const fetchSuppliersState = useFetchSuppliersWidgetData();
  const syncSuppliersState = useSyncFetchSuppliersWidgetData();

  const [data, setData] = useState(INITIAL_DATA);
  const [partner, setPartner] = useState(null);
  const [showInsights, setShowInsights] = useState(true);
  const [infoMsg, setInfoMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [searchParams] = useSearchParams();

  const toggleInsights = () => {
    setShowInsights(!showInsights);
  };

  const handleCopyDiscount = () => {
    navigator.clipboard
      .writeText(`${getLandingPage()}/offer/${data?.insights?.discount?.code}`)
      .then(
        () => {
          setInfoMsg("Your discount link has been copied successfully!");
        },
        (error) => {
          setErrMsg("Failed to copy discount link to clipboard!");
          console.error("Failed to copy code to clipboard:", error);
        },
      );
  };

  async function setup() {
    setupDashboardData({
      setPartner,
      setData,
      verifyPartnerState,
      fetchSuppliersState,
    });
  }

  useEffect(() => {
    if (!!dashboard) {
      syncSuppliersState({ dashboard: dashboard?.route });
    }
  }, []);

  useEffect(() => {
    if (
      verifyPartnerState?.data?.result &&
      !!fetchSuppliersState?.data?.result
    ) {
      setup();
    }
  }, [verifyPartnerState?.data, fetchSuppliersState?.data]);

  useEffect(() => {
    if (searchParams.get("cancel") === "true") {
      sessionStorage.removeItem("STRIPE_ACTION");
      setInfoMsg("Subscription purchase has been cancelled successfully!");
    }
  }, [searchParams]);

  useEffect(() => {
    if (data) console.log("DATA:", data);
  }, [data]);

  return !data?.result ? (
    <PageSpinner />
  ) : (
    <>
      {!!infoMsg && (
        <Alert
          color={"green"}
          onDismiss={() => setInfoMsg("")}
          icon={HiCheckCircle}
        >
          <span className="text-base font-semibold">{infoMsg}</span>
        </Alert>
      )}

      {!!errMsg && (
        <Alert
          color={"red"}
          onDismiss={() => setErrMsg("")}
          icon={HiExclamationCircle}
        >
          <span className="text-base font-semibold">{errMsg}</span>
        </Alert>
      )}

      <PageHeader
        customBreadcrumbs={
          <Breadcrumbs
            icon={HiChartPie}
            items={{
              "": ROUTES.ROOT,
              Subscriptions: ROUTES.SUBSCRIPTIONS,
            }}
          />
        }
        pageDescription={`Create subscriptions to start ${dashboard?.orgPlural} towards their certification journey.`}
        pageName="Subscriptions"
        variant="primePage"
        showInsights={showInsights}
        toggleInsights={toggleInsights}
      />

      {!!showInsights && (
        <section className="cybercert-subscriptions-insights flex flex-col gap-5 laptop:flex-row">
          <div className="flex w-full flex-grow flex-col gap-5 self-stretch">
            <DiscountOffersInsightWidget
              data={data?.insights}
              handleCopy={handleCopyDiscount}
            />
            <SubscriptionsInsightsWidget data={data?.insights} />
          </div>

          <InsightsCertCreditsTable
            data={data?.insights}
            handleInfoAlert={setInfoMsg}
            handleErrAlert={setErrMsg}
          />
        </section>
      )}

      <BulkPurchaseCard
        subscriptionDiscount={data?.partner?.subscriptionDiscount}
        volumeDiscount={
          !isExpired(data?.partner?.codeExpiry)
            ? data?.partner?.volumeDiscount
            : 0
        }
        volumeAmount={data?.partner?.minimumVolume}
      />

      <span className="text-secondary text-lg font-bold">
        {dashboard?.orgTitleSingle} Subscription Activity
      </span>

      <SubscriptionActivityTable data={data?.insights} />
    </>
  );
}

const INITIAL_DATA = {
  insights: {
    credits: [
      {
        name: "Bronze",
        available: 0,
        total: 0,
        image: IconBronze,
        alt: "IconBronze",
      },
      {
        name: "Silver",
        available: 0,
        total: 0,
        image: IconSilver,
        alt: "IconSilver",
      },
      {
        name: "Gold",
        available: 0,
        total: 0,
        image: IconGold,
        alt: "IconGold",
      },
      {
        name: "Platinum",
        available: 0,
        total: 0,
        image: IconPlatinum,
        alt: "IconPlatinum",
      },
      {
        name: "Diamond",
        available: 0,
        total: 0,
        image: IconDiamond,
        alt: "iconDiamond",
      },
    ],
    discount: {
      code: "",
      discount: "0%",
      expiry: "",
      status: "",
      total: 0,
      used: 0,
    },
    subscriptions: [
      {
        name: "Bronze",
        issued: 0,
        required: 0,
        color: "",
      },
      {
        name: "Silver",
        issued: 0,
        required: 0,
        color: "",
      },
      {
        name: "Gold",
        issued: 0,
        required: 0,
        color: "",
      },
      {
        name: "Platinum",
        issued: 0,
        required: 0,
        color: "",
      },
      {
        name: "Diamond",
        issued: 0,
        required: 0,
        color: "",
      },
      {
        name: "Ineligible",
        required: 0,
        color: "",
      },
    ],
  },
};
